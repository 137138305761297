import dayjs from "dayjs";

export function checkWithInThePeriod(startDate: string, endDate: string) {
	const dateTimeNow = dayjs();

	const startDateValid = startDate ? dayjs(startDate).isBefore(dateTimeNow) : true;
	const endDateValid = endDate ? dayjs(endDate).isAfter(dateTimeNow) : true;

	return startDateValid && endDateValid;
}
