import { FaSearch } from "react-icons/fa";

import { NoRecordsFeedback } from "../NoRecordsFeedback";

import "./styles.css";

export type TimeLineContent = {
	eventDate: string;
	content: React.ReactNode;
};

type Props = {
	timeLineTitle: string;
	timeLineContents?: TimeLineContent[];
	style?: React.CSSProperties;
};

export function TimeLine({ timeLineTitle, timeLineContents, style }: Props) {
	return (
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<h6 className="card-title">{timeLineTitle}</h6>
							<div id="content">
								<ul className="timeline" style={style}>
									{timeLineContents?.length === 0 && (
										<NoRecordsFeedback
											icon={<FaSearch className="fs-1" />}
											message="Não encontrado"
										/>
									)}
									{timeLineContents?.map((timeLinecontent, index) => (
										<li
											key={`time-line-item-${index}`}
											className="event"
											data-date={timeLinecontent.eventDate}
										>
											{timeLinecontent.content}
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
