import Col from "react-bootstrap/Col";
import { toAbsoluteUrl } from "@/../_metronic/helpers";
import { Row } from "react-bootstrap";

type Props = {
	city?: string;
};

export function ReportHeader({ city }: Props) {
	return (
		<Row className="d-flex align-items-end justify-content-between w-100">
			<Col className="d-flex justify-content-start">
				<img
					src={toAbsoluteUrl("/media/logos/logo-multintegrada.png")}
					alt="Multintegrada - Tecnologia"
					height={50}
				/>
			</Col>
			<Col className="text-center">
				<h4 className="text-nowrap" style={{ textTransform: "uppercase" }}>
					PREFEITURA MUNICIPAL DE {city}
				</h4>
				<h4>SECRETARIA MUNICIPAL DE SAÚDE</h4>
				<h4>SISTEMA ÚNICO DE SAÚDE - SUS</h4>
			</Col>
			<Col className="d-flex justify-content-end">
				<img
					src={toAbsoluteUrl("/media/logos/multisus-azul.png")}
					alt="Multintegrada - Tecnologia"
					height={100}
				/>
			</Col>
		</Row>
	);
}
