export type Column = {
	title: string;
	visible: boolean;
};

export const handleColumnToggle = <T extends Record<string, Column>, K extends keyof T>(
	column: K,
	setState: React.Dispatch<React.SetStateAction<T>>
) => {
	setState((prevState) => ({
		...prevState,
		[column]: {
			...prevState[column],
			visible: !prevState[column].visible,
		},
	}));
};
