import { useState } from "react";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
	PregnancyByCityAndPlannedPregnancyResume,
	PregnancyByCityAndPlannedPregnancyTable,
} from "../PregnancyByCityAndPlannedPregnancyTable";
import { PregnancyByCityAndPlannedPregnancyTableFilters } from "../PregnancyByCityAndPlannedPregnancyTableFilters";

type Props = ModalProps & {
	pregnancyByCityAndPlannedPregnancyResumes: PregnancyByCityAndPlannedPregnancyResume[];
};

export function PregnancyByCityAndPlannedPregnancyModal({
	pregnancyByCityAndPlannedPregnancyResumes,
	...rest
}: Props) {
	const [cityName, setCityName] = useState("");
	const pregnancyByCityAndPlannedPregnancyResumesFiltered =
		pregnancyByCityAndPlannedPregnancyResumes.filter((resume) =>
			resume.city.locale.toUpperCase().includes(cityName.toUpperCase())
		);
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">
						GESTANTES POR CIDADE E PLANEJAMENTO
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="mb-4">
					<Col>
						<PregnancyByCityAndPlannedPregnancyTableFilters
							cityName={cityName}
							setCityName={setCityName}
						/>
					</Col>
				</Row>
				<PregnancyByCityAndPlannedPregnancyTable
					pregnancyByCityAndPlannedPregnancyResumes={
						pregnancyByCityAndPlannedPregnancyResumesFiltered
					}
				/>
			</Modal.Body>
		</Modal>
	);
}
