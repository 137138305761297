import { BsSearch } from "react-icons/bs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { useUnits } from "@/hooks/useUnits";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { Option } from "@/@types/Option";
import { TImmunobiological } from "@/@types/TImmunobiological";

import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";
import { UnitsSelect } from "@/components/UnitsSelect";
import { TImmunobiologicalSelect } from "@/components/TImmunobiologicalSelect";

type Props = {
	handleSearch: () => void;
	setSelectedHealthUnit: (selectedHealtUnit: Option<string>) => void;
	setSelectedHealthTeam: (selectedHealthTeam: Option<string>) => void;
	setSelectedImmunobiological: (selectedImmunobiological: Option<TImmunobiological>) => void;
};

export function ActiveVaccinationFilters({
	setSelectedHealthUnit,
	setSelectedHealthTeam,
	setSelectedImmunobiological,
	handleSearch,
}: Props) {
	const { cnes } = useCurrentAccount();
	const { units } = useUnits();
	const { healthTeams } = useHealthTeams();

	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleSearch();
			}}
		>
			<Row className="d-flex justify-content-between mx-3 p-3 bg-white">
				{!cnes && (
					<Col sm={12} md={12} lg={5} xl={3}>
						<UnitsSelect
							units={units}
							onChange={(event) => setSelectedHealthUnit(event as Option<string>)}
						/>
					</Col>
				)}
				<Col sm={12} md={12} lg={5} xl={3}>
					<HealthTeamsSelect
						id="health-teams-select"
						healthTeams={healthTeams}
						onChange={(newValue) => setSelectedHealthTeam(newValue as Option<string>)}
					/>
				</Col>
				<Col sm={12} md={12} lg={5} xl={4}>
					<TImmunobiologicalSelect
						id="immunobiological-select"
						onChange={(event) =>
							setSelectedImmunobiological(event as Option<TImmunobiological>)
						}
					/>
				</Col>

				<Col sm={12} md={12} lg={2} xl={2} className="d-flex p-0 mt-3 align-items-end">
					<Button variant="light" type="submit" className="align-items-end w-100">
						<BsSearch className="fs-1 me-2" />
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
