import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

export type BarChartProps = {
	minHeight?: number;
	baseMultiplier?: number;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	categories: string[];
	apexOptions?: ApexOptions;
	isVertical?: boolean;
	colors?: string[];
	dataLabelsColors?: string[];
	isStacked?: boolean;
	toolbar?: boolean;
	zoom?: boolean;
	paddingTop?: number;
	paddingleft?: number;
	paddingRight?: number;
	paddingBottom?: number;
	categoryTextWidth?: number;
};

export function BarChart({
	minHeight,
	baseMultiplier,
	paddingBottom,
	paddingRight,
	paddingTop,
	paddingleft,
	isStacked,
	colors,
	dataLabelsColors,
	categories,
	series,
	apexOptions,
	isVertical,
	categoryTextWidth,
}: BarChartProps) {
	const barChartOption: ApexOptions = apexOptions || {
		chart: {
			type: "bar",
			stacked: isStacked,
			toolbar: {
				show: true,
			},
			zoom: {
				enabled: true,
			},
		},
		grid: {
			padding: {
				top: paddingTop ?? 5,
				right: paddingRight ?? 5,
				bottom: paddingBottom ?? 5,
				left: paddingleft ?? 5,
			},
		},
		colors: colors && colors,
		plotOptions: {
			bar: {
				horizontal: !isVertical,
				borderRadius: 10,
				borderRadiusApplication: "end",
				borderRadiusWhenStacked: "last",
				barHeight: "80%",
				columnWidth: "80%",
				dataLabels: {
					total: {
						enabled: false,
						style: {
							fontSize: "13px",
							fontWeight: 900,
						},
					},
				},
			},
		},
		dataLabels: {
			style: {
				colors: dataLabelsColors ?? ["#333"],
			},
			textAnchor: "middle",
			enabled: true,
		},
		yaxis: {
			labels: { show: true, align: "left", maxWidth: categoryTextWidth ?? 100 },
		},
		xaxis: {
			type: "category",
			categories: categories,
		},
		legend: {
			position: "top",
		},
		fill: {
			opacity: 1,
		},
		responsive: [
			{
				breakpoint: 1000,
				options: {
					plotOptions: {
						bar: {
							horizontal: !isVertical,
							barHeight: "80%",
							columnWidth: "80%",
						},
					},
					chart: {
						height: "100%",
						width: "100%",
					},
					legend: {
						show: true,
						horizontalAlign: "left",
					},
					yaxis: {
						show: true,
						labels: { align: "left", maxWidth: 100, style: { fontSize: "14px" } },
					},
					xaxis: {
						labels: {
							show: true,
						},
					},
				} as ApexOptions,
			},
		],
	};

	const calculateMinHeightBarChart = (itemsLength: number, multiplierBar?: number) => {
		const valueDefault = 40;
		const barHeight = multiplierBar ?? valueDefault;
		return `${Math.max(itemsLength * barHeight, 300)}px`;
	};

	return (
		<div
			style={{
				flexGrow: 1,
				width: "100%",
				minHeight:
					minHeight ?? calculateMinHeightBarChart(categories?.length, baseMultiplier),
				height: "100%",
			}}
		>
			<ReactApexChart
				options={barChartOption}
				series={series}
				type="bar"
				height="100%"
				width="100%"
			/>
		</div>
	);
}
