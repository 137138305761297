import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";

import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Account } from "@/@types/Account";

import { PregnancyDashboardFilters } from "./PregnancyDashboardFilters";
import {
	PregnancyDashboardIndicators,
	PregnancyIndicatorsResume,
	PregnancyIndicatorsResumeCity,
} from "./PregnancyDashboardIndicators";
import {
	PregnancyDashboardPrenatal,
	PregnancyResume,
	PregnancyResumeCity,
} from "./PregnancyDashboardPrenatal";
import {
	ChildBirthResume,
	ChildBirthResumeCity,
	PregnancyDashboardChildBirth,
} from "./PregnancyDashboardChildBirth";
import { LoadingScreen } from "@/components/LoadingScreen";

type PregnancyWithChildBirthResume = PregnancyIndicatorsResume & PregnancyResume & ChildBirthResume;

type PregnancyWithChildBirthResumeCity = {
	pregnancyResume?: PregnancyWithChildBirthResume;
	city: Account;
};

export function PregnancyDashboard() {
	const api = useApi();
	const { uf } = useCurrentAccount();
	const [isLoading, setIsLoading] = useState(false);
	const [pregnancyWithChildBirthResumeCities, setPregnancyWithChildBirthResumeCities] = useState<
		PregnancyWithChildBirthResumeCity[]
	>([]);
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(4, "months").format("YYYY-MM-DD")
	);
	const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
	const [selectedIbgeCode, setSelectedIbgeCode] = useState("");

	const pregnancyWithChildBirthResumeCitiesFiltered = pregnancyWithChildBirthResumeCities.filter(
		(resume) => resume.city.ibgeCode.includes(selectedIbgeCode)
	);

	async function getPregnancyResume() {
		try {
			const { data } = await api.get<PregnancyWithChildBirthResumeCity[]>(
				`/FederalUnitAttendance/v1/pregnancyResume`,
				{
					params: {
						uf: uf,
						startDate: startDate,
						endDate: endDate,
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetch() {
		setIsLoading(true);
		setSelectedIbgeCode("");
		setPregnancyWithChildBirthResumeCities([]);
		setPregnancyWithChildBirthResumeCities(await getPregnancyResume());
		setIsLoading(false);
	}

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const pregnancyIndicatorsResumeCities: PregnancyIndicatorsResumeCity[] =
		pregnancyWithChildBirthResumeCitiesFiltered.map((pregnancyResumeCity) => {
			return {
				city: pregnancyResumeCity.city,
				indicator1Resumes: pregnancyResumeCity.pregnancyResume?.indicator1Resumes,
				indicator2Resumes: pregnancyResumeCity.pregnancyResume?.indicator2Resumes,
				indicator3Resumes: pregnancyResumeCity.pregnancyResume?.indicator3Resumes,
			} as PregnancyIndicatorsResumeCity;
		});

	const pregnancyResumeCities: PregnancyResumeCity[] =
		pregnancyWithChildBirthResumeCitiesFiltered.map((pregnancyResumeCity) => {
			return {
				city: pregnancyResumeCity.city,
				totalActivePregnants: pregnancyResumeCity.pregnancyResume?.totalActivePregnants,
				pregnancyByAgeResumes: pregnancyResumeCity.pregnancyResume?.pregnancyByAgeResumes,
				pregnancyByRacialColorResumes:
					pregnancyResumeCity.pregnancyResume?.pregnancyByRacialColorResumes,
				pregnancyByPlannedPregnancyStatusResumes:
					pregnancyResumeCity.pregnancyResume?.pregnancyByPlannedPregnancyStatusResumes,
				pregnancyByHighRiskStatusResumes:
					pregnancyResumeCity.pregnancyResume?.pregnancyByHighRiskStatusResumes,
			} as PregnancyResumeCity;
		});

	const childBirthResumeCities: ChildBirthResumeCity[] =
		pregnancyWithChildBirthResumeCitiesFiltered.map((pregnancyResumeCity) => {
			return {
				city: pregnancyResumeCity.city,
				childBirthByPeriodAndAverageGestationalAgeResumes:
					pregnancyResumeCity.pregnancyResume
						?.childBirthByPeriodAndAverageGestationalAgeResumes,
				childBirthByPeriodAndTypeResumes:
					pregnancyResumeCity.pregnancyResume?.childBirthByPeriodAndTypeResumes,
				childBirthTypeResumes: pregnancyResumeCity.pregnancyResume?.childBirthTypeResumes,
			} as ChildBirthResumeCity;
		});

	return (
		<div>
			<LoadingScreen loading={isLoading} />
			<Card className="my-2 mx-4">
				<Card.Body>
					<PregnancyDashboardFilters
						cities={pregnancyWithChildBirthResumeCities
							.map((resume) => resume.city)
							.sort((a, b) => a.locale.localeCompare(b.locale))}
						setSelectedIbgeCode={setSelectedIbgeCode}
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						handleFilter={fetch}
					/>
				</Card.Body>
			</Card>
			<PregnancyDashboardIndicators
				pregnancyIndicatorsResumeCities={pregnancyIndicatorsResumeCities}
			/>
			<PregnancyDashboardPrenatal pregnancyResumeCities={pregnancyResumeCities} />
			<PregnancyDashboardChildBirth childBirthResumeCities={childBirthResumeCities} />
		</div>
	);
}
