import Table from "react-bootstrap/Table";

import { Account } from "@/@types/Account";
import { RacialColorEnum } from "@/utils/enums/RacialColorEnum";
import { ResumeGrouped } from "@/services/esus/attendanceService";

export type PregnancyByCityAndRacialColorResume = {
	resumes: ResumeGrouped<RacialColorEnum | undefined, object>[];
	city: Account;
};

type Props = {
	pregnancyByCityAndRacialColorResumes: PregnancyByCityAndRacialColorResume[];
};

export function PregnancyByCityAndRacialColorTable({
	pregnancyByCityAndRacialColorResumes,
}: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			id="table-pregnancy-city-racialcolor"
			className="table border border-1 align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Município</th>
					<th>Brancas</th>
					<th>Negras</th>
					<th>Amarelas</th>
					<th>Indígenas</th>
					<th>Pardas</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{pregnancyByCityAndRacialColorResumes
					.sort((a, b) => a.city.locale.localeCompare(b.city.locale))
					.map((cityResume, index) => (
						<tr key={`table-pregnancy-city-racialcolor-${index}`}>
							<td>{cityResume.city.locale}</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.key === RacialColorEnum.WHITE
								)?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.key === RacialColorEnum.BLACK
								)?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.key === RacialColorEnum.YELLOW
								)?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.key === RacialColorEnum.INDIGENOUS
								)?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.key === RacialColorEnum.BROWN
								)?.total || 0}
							</td>
							<td>
								{cityResume.resumes
									.map((resume) => resume?.total || 0)
									.reduce((acc, curr) => acc + curr, 0)}
							</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
