import { Outlet } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { BannerTypeEnum } from "@/utils/enums/BannerTypeEnum";
import { BannerDisplay } from "@/components/BannerDisplay";
import "./styles.css";

type AuthLayoutProps = {
	isLogin?: boolean;
};

export function AuthLayout({ ...rest }: AuthLayoutProps) {
	return (
		<Row className="container-login gx-0">
			<Col sm={12} md={8} lg={8} xl={8} xxl={8} id="left-image-layout">
				<div className="d-flex align-items-end h-100">
					<BannerDisplay bannerTypeEnum={BannerTypeEnum.LOGIN} className="h-100" />
				</div>
			</Col>
			<Col sm={12} md={4} lg={4} xl={4} xxl={4} id="right-image-layout">
				<div className="login-form pb-4 w-100 w-sm-500px z-2">
					<Outlet />
				</div>
			</Col>
		</Row>
	);
}
