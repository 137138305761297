import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import "./styles.scss";

type CodeInputProps = {
	length?: number;
	onCodeChange: (code: string) => void;
	containerClassName?: string;
};

export function CodeInput({ length = 6, onCodeChange, containerClassName }: CodeInputProps) {
	const codeInputElements = document.querySelectorAll<HTMLInputElement>("input[name='code']");
	const [codeInputValues, setCodeInputValues] = useState<string[]>(
		Array.from({ length }, () => "")
	);

	function handleCodeKeyDown(event: KeyboardEvent<HTMLInputElement>, index: number) {
		if (event.key === "Backspace" && !event.currentTarget.value) {
			const newCodes = codeInputValues.slice();
			newCodes[index] = "";

			setCodeInputValues(newCodes);

			codeInputElements?.[Math.max(0, index - 1)]?.focus();
		}
	}

	function handleCodeChange(event: ChangeEvent<HTMLInputElement>, index: number) {
		const value = event.currentTarget.value.charAt(0);

		const newCodes = codeInputValues.slice();
		newCodes[index] = value;

		setCodeInputValues(newCodes);

		if (value) {
			codeInputElements?.[Math.min(length - 1, index + 1)]?.focus();
		}
	}

	useEffect(() => {
		onCodeChange(codeInputValues.join(""));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeInputValues]);

	return (
		<div
			id="verification-code-input"
			className={`border rounded py-5 px-3 d-flex justify-content-between ${containerClassName}`}
		>
			{Array.from({ length }, (_, index) => (
				<input
					key={index}
					type="text"
					name="code"
					className="verification-code-input"
					onKeyDown={(event) => handleCodeKeyDown(event, index)}
					onChange={(event) => handleCodeChange(event, index)}
					value={codeInputValues[index]}
				/>
			))}
		</div>
	);
}
