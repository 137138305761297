import Table from "react-bootstrap/Table";

import { Ciap } from "@/@types/Ciap";
import { ResumeGrouped } from "@/services/esus/attendanceService";

type Props = {
	aggregatedAttendanceCiaps: ResumeGrouped<Ciap, object>[];
};

export function AttendanceRateByCiapTable({ aggregatedAttendanceCiaps }: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Ciap</th>
					<th className="text-start">Descrição</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{aggregatedAttendanceCiaps
					.sort((aggregatedA, aggregatedB) => aggregatedB.total - aggregatedA.total)
					.map((aggregatedAttendanceCiap, index) => (
						<tr key={`attendance-rate-by-ciap-${index}`}>
							<td>{aggregatedAttendanceCiap.key.ciapCode}</td>
							<td className="text-start">
								{aggregatedAttendanceCiap.key.description}
							</td>
							<td className="fw-bolder">{aggregatedAttendanceCiap.total}</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
