import { ActionMeta } from "react-select";
import { FaPrint } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { FormEvent } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { Unit } from "@/contexts/UnitsContext";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { HealthTeam } from "@/@types/HealthTeam";
import { SelectOptionsProps } from "@/@types/genericals";

import { QuartersSelect } from "@/components/QuartersSelect";
import { UnitsSelect } from "@/components/UnitsSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";

type ReactSelectChangeFunction = (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;

type Props = {
	selectedQuarter: SelectOptionsProps;
	handleChangeQuarter: ReactSelectChangeFunction;
	units: Unit[];
	selectedUnit: SelectOptionsProps;
	handleChangeUnit: ReactSelectChangeFunction;
	healthTeams: HealthTeam[];
	selectedHealthTeam: SelectOptionsProps;
	handleChangeHealthTeams: ReactSelectChangeFunction;
	handlePrint: () => void;
	handleFilter: (event: FormEvent) => void;
	isLoading: boolean;
};

export function DashboardFilters({
	selectedQuarter,
	handleChangeQuarter,
	units,
	selectedUnit,
	handleChangeUnit,
	healthTeams,
	selectedHealthTeam,
	handleChangeHealthTeams,
	handlePrint,
	handleFilter,
	isLoading,
}: Props) {
	const hasSelectedUnit = selectedUnit.value !== "0";
	const hasSelectedHealthTeam = selectedHealthTeam.value !== "0";
	const { cnes } = useCurrentAccount();

	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter(event);
			}}
		>
			<Row className="d-flex align-items-end">
				<Col sm={4} lg={3} xl={2}>
					<QuartersSelect
						id="quarters-select"
						value={selectedQuarter}
						onChange={handleChangeQuarter}
					/>
				</Col>
				{!cnes && (
					<Col sm={4} lg={3} xl={3}>
						<UnitsSelect
							id="units-select"
							units={units}
							value={selectedUnit}
							onChange={handleChangeUnit}
							isDisabled={!hasSelectedUnit && hasSelectedHealthTeam}
						/>
					</Col>
				)}
				<Col sm={4} lg={3} xl={4}>
					<HealthTeamsSelect
						id="health-teams-select"
						healthTeams={healthTeams}
						value={selectedHealthTeam}
						onChange={handleChangeHealthTeams}
					/>
				</Col>
				<Col
					sm={12}
					lg={3}
					xl={3}
					className="d-flex justify-content-end align-items-end mt-1 gap-1"
				>
					<Button
						className="d-flex align-items-center justify-content-center"
						type="submit"
						disabled={isLoading}
					>
						<BsSearch className="me-2" />
						Consultar
					</Button>
					<Button
						variant="secondary"
						className="d-flex align-items-center justify-content-center"
						title="Imprimir"
						disabled={isLoading}
						onClick={handlePrint}
					>
						<FaPrint /> Exportar
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
