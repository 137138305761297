import { StorageFile } from "@/@types/StorageFile";
import { get, post } from "../api";
import { Banner } from "@/@types/Banner";
import { AxiosResponse } from "axios";

export type ImageFileFormData = {
	bannerTypeId: number;
	startDate: string;
	endDate: string;
	fileDescription: string;
	imageFile: File;
};

export type registerBannerData = {
	storageFileId: number;
	bannerTypeId: number;
	startDate: string;
	endDate: string;
};

export async function fetchBanners() {
	const { data } = await get<Banner[]>(`/StorageFile/v1/GetBanners`);
	return data || [];
}

export async function fetchBannersByType(typeId: number) {
	const { data } = await get<Banner[]>(`/StorageFile/v1/BannerFilterbyType`, { typeId });
	return data || [];
}

export async function fetchUpdateBanner(banner: Banner) {
	const response = await post<Banner>(`/StorageFile/v1/UpdateBanner`, { ...banner });
	return { ...response, data: response.data || ({} as Banner) } as AxiosResponse<Banner>;
}

export async function registerBanner(data: ImageFileFormData) {
	const response = await post<StorageFile>(`/StorageFile/v1/RegisterBanner`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return {
		...response,
		data: response.data || ({} as StorageFile),
	} as AxiosResponse<StorageFile>;
}

export async function deleteBanner(banner: Banner) {
	const response = await post(`/StorageFile/v1/DeleteBanner`, {
		bannerId: banner.id,
	});
	return response;
}
