import { useEffect } from "react";

import classNamesGroups from "clsx";
import { useFormik } from "formik";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
	PasswordRegistration,
	passwordRegistrationSchema,
} from "@/utils/Schemas/registrationSchema";
import { AccountCreationEnum } from "@/utils/enums/AccountCreationEnum";

import { NextButton } from "../NextButton";

type Props = {
	handleGoToNextStep: () => void;
	handleSubmit: (passwordRegistration: PasswordRegistration) => void;
	setStep: (step: number) => void;
	isNextButtonDisabled: boolean;
	userPassword?: PasswordRegistration;
};

export function UserPasswordForm({
	handleGoToNextStep,
	handleSubmit,
	setStep,
	isNextButtonDisabled,
	userPassword,
}: Props) {
	const formik = useFormik({
		initialValues: { ...userPassword } as PasswordRegistration,
		validationSchema: passwordRegistrationSchema,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	async function handleChangeValues() {
		const isValid = await passwordRegistrationSchema.isValid(formik.values);
		if (isValid) {
			handleSubmit(formik.values);
		} else {
			handleSubmit({} as PasswordRegistration);
		}
	}

	useEffect(() => {
		handleChangeValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values]);

	return (
		<section>
			<form>
				<Row>
					<Col className="text-center">
						<p className="fw-bolder fs-1">Crie sua senha</p>
						<p className="fs-7">Crie sua senha de acesso para finalizar o cadastro</p>
					</Col>
				</Row>
				<Row className="mb-7">
					<Col>
						<label htmlFor="password" className="sr-only">
							Senha
						</label>
						<input
							{...formik.getFieldProps("password")}
							type="password"
							name="password"
							id="password"
							placeholder="Escolha uma senha"
							autoComplete="off"
							className={classNamesGroups(
								"form-control form-control-lg fs-3 fw-normal",
								{
									"is-invalid": formik.touched.password && formik.errors.password,
								},
								{
									"is-valid": formik.touched.password && !formik.errors.password,
								}
							)}
						/>

						{formik.touched.password && formik.errors.password && (
							<div className="fv-plugins-message-container text-danger">
								<span role="alert">{formik.errors.password}</span>
							</div>
						)}
					</Col>
				</Row>
				<Row className="mb-7">
					<Col>
						<label htmlFor="password-confirmation" className="sr-only">
							Confirmação de senha
						</label>
						<input
							{...formik.getFieldProps("confirmPassword")}
							type="password"
							name="confirmPassword"
							id="password-confirmation"
							placeholder="Confirme sua senha"
							autoComplete="off"
							className={classNamesGroups(
								"form-control form-control-lg fs-3 fw-normal",
								{
									"is-invalid":
										formik.touched.confirmPassword &&
										formik.errors.confirmPassword,
								},
								{
									"is-valid":
										formik.touched.confirmPassword &&
										!formik.errors.confirmPassword,
								}
							)}
						/>

						{formik.touched.confirmPassword && formik.errors.confirmPassword && (
							<div className="fv-plugins-message-container text-danger">
								<span role="alert">{formik.errors.confirmPassword}</span>
							</div>
						)}
					</Col>
				</Row>
			</form>
			<Row className="d-flex justify-content-evenly mt-4">
				<Col sm={5} md={5} lg={5} xxl={5} className="d-flex justify-content-center">
					<NextButton
						text={"Voltar"}
						form="registration-form"
						previous
						className="bg-muted w-100"
						onClick={() => setStep(AccountCreationEnum.PERSONAL_DATA)}
						style={{ backgroundColor: "#008FFB" }}
					/>
				</Col>
				<Col sm={5} md={5} lg={5} xxl={5} className="d-flex justify-content-center">
					<NextButton
						text={"Confirmar"}
						form="registration-form"
						className="w-100"
						disabled={isNextButtonDisabled}
						onClick={handleGoToNextStep}
						style={{ backgroundColor: "#008FFB" }}
					/>
				</Col>
			</Row>
		</section>
	);
}
