import { get } from "../api";
import { FederalUnit } from "@/@types/FederalUnit";
import { Account } from "@/@types/Account";

export async function fetchPermitedFederalUnits() {
	const { data } = await get<FederalUnit[]>(`/UserAccount/v1/GetPermittedFederalUnits`);
	return data || [];
}

export async function fetchPermittedCities(uf?: string) {
	const { data } = await get<Account[]>(`/UserAccount/v1/GetPermittedCities`, { uf });
	return data || [];
}
