import { MdPregnantWoman } from "react-icons/md";

export function PregnancyDashboardPrenatalHeader() {
	return (
		<section className="d-flex align-items-center">
			<label className="bg-primary text-secondary py-2 px-4 rounded fs-1 text-center">
				<MdPregnantWoman />
			</label>
			<h1 className="text-primary rounded ms-2 my-0">Pré-natal</h1>
		</section>
	);
}
