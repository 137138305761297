import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

import { BasePatient } from "@/@types/BasePatient";

import { TimeLine } from "@/components/TimeLine";

type Props = ModalProps & {
	patientSelected: BasePatient;
};

export function ModalIndicator4({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-7">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-8 text-primary">Paciente: </span>
							<span>{patientSelected?.fullName}</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">DN: </span>
							<span>{dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")}</span>
						</Col>
						<Col sm={4} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">IDADE: </span>
							<span>{patientSelected.age}</span>
						</Col>
					</Row>
				</section>
				<TimeLine
					timeLineTitle="PROCEDIMENTOS"
					timeLineContents={patientSelected.attendanceProcedures?.map(
						(attendance, index) => {
							return {
								content: (
									<section key={index}>
										<article>
											<p className="fs-8">
												<strong className="text-primary me-2">
													Equipe:
												</strong>
												{patientSelected.healthTeam?.ine
													? `${patientSelected.healthTeam?.ine}-${patientSelected.healthTeam?.name}`
													: "N/A"}
											</p>
										</article>
										<article>
											<p>
												<strong className="text-primary me-2 fs-8">
													Código/Procedimento:
												</strong>
												<label>{`${attendance.procedure?.procedureCode} - ${attendance.procedure?.name}`}</label>
											</p>
										</article>
										<article>
											<strong className="text-primary fs-8">
												1º Profissional:{" "}
											</strong>
											<p className="fs-8">
												<strong className="me-2">Nome:</strong>
												{attendance.professional?.name || "N/A"}
											</p>
											<p className="fs-8">
												<strong className="me-2">Categoria:</strong>
												{attendance.cbo?.description || "N/A"}
											</p>
										</article>
									</section>
								),
								eventDate: attendance.date
									? dayjs(attendance.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Alert variant="warning">
						<section className="d-flex gap-2">
							<article>
								<p>
									"O Ministério da Saúde recomenda a realização da coleta a cada 3
									anos, após 2 exames anuais consecutivos negativos. A previsão da
									"Próxima coleta" do painel é baseada apenas no prazo de 36 meses
									do indicador, não anulando a possibilidade de antecipar uma nova
									coleta quando for o caso"
								</p>
							</article>
						</section>
					</Alert>
				</Row>
			</Modal.Footer>
		</Modal>
	);
}
