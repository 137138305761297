import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsSearch } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import { useLayout } from "@/../_metronic/layout/core";
import { useDebounce } from "@/hooks/useDebounce";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useUnits } from "@/hooks/useUnits";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import {
	handlePregnantsWithChildBirthPaginated,
	handleResumePregnantsWithChildBirth,
	Pregnancy,
	ResumePregnant,
} from "@/services/esus/attendanceService";
import { Paginated } from "@/@types/paginated";
import { OrderBy } from "@/@types/OrderBy";
import { Option } from "@/@types/Option";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { GenericObject } from "@/@types/genericals";
import { exportExcel } from "@/utils/exports";

import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { Skeleton } from "@/components/Skeleton";
import { SemiDonut } from "@/components/Charts/SemiDonut";
import { PregnancyFilters } from "./PregnancyFilters";
import { PregnancyTable } from "./PregnancyTable";
import { DonutChart } from "@/components/Charts/DonutChart";
import { LoadingScreen } from "@/components/LoadingScreen";
import { PregnancyResume } from "./PregnancyResume";
import { UnitsSelect } from "@/components/UnitsSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";

export function PregnancyManagement() {
	const { units } = useUnits();
	const { setTitle } = useLayout();
	const { healthTeams } = useHealthTeams();
	const [orderBy, setOrderBy] = useState<OrderBy<Pregnancy>[]>([]);
	const { cnes, ibgeCode, uf } = useCurrentAccount();
	const [searchFullName, setSearchFullName] = useState("");
	const debouncedFullName = useDebounce(searchFullName, 2000);
	const [showLoadingScreen, setShowLoadingScreen] = useState(false);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState<string>(
		dayjs().subtract(1, "months").format("YYYY-MM-DD")
	);
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState<string>(
		dayjs().format("YYYY-MM-DD")
	);
	const [selectedHealthTeam, setSelectedHealthTeam] = useState<Option<string>>(
		{} as Option<string>
	);
	const [selectedUnit, setSelectedUnit] = useState<Option<string>>({} as Option<string>);
	const [pageNumber, setPageNumber] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingResume, setIsLoadingResume] = useState(false);
	const [pregnantPaginated, setPregnantPaginated] = useState<Paginated<Pregnancy>>(
		{} as Paginated<Pregnancy>
	);
	const [resumePregnant, setResumePregnant] = useState<ResumePregnant>({} as ResumePregnant);
	const cacheExpirationDate = getExpirationDateMidnight();

	const totalPregnants =
		resumePregnant.resumePregnancyStatus
			?.map((resume) => resume.total)
			.reduce((curr, acc) => curr + acc, 0) || 0;

	const totalRegisteredChildBirth =
		resumePregnant?.resumeChildBirthType
			?.filter((resume) => resume.key !== "unregistered")
			.map((resume) => resume.total)
			.reduce((curr, acc) => curr + acc, 0) || 0;

	const totalUnregisteredChildBirth =
		resumePregnant?.resumeChildBirthType
			?.filter((resume) => resume.key === "unregistered")
			.map((resume) => resume.total)
			.reduce((curr, acc) => curr + acc, 0) || 0;

	const totalRiskPregnants =
		resumePregnant.resumeHighRisk
			?.filter((resume) => resume.key === 1)
			.map((resume) => resume.total)
			.reduce((curr, acc) => curr + acc, 0) || 0;

	async function handleMonitoringPregnantPaginated(pageSize?: number) {
		return await handlePregnantsWithChildBirthPaginated({
			startDate: selectedOptionStartDate,
			endDate: selectedOptionEndDate,
			cnes: selectedUnit.value || cnes,
			ine: selectedHealthTeam.value,
			fullName: searchFullName,
			ibgeCode: ibgeCode,
			pageNumber: pageNumber,
			pageSize: pageSize,
			uf: uf,
			orderBy: orderBy
				.map((item) => [item.property, item.method || "asc"].join(" "))
				.join(","),
		});
	}

	async function handleResumePregnant() {
		return await handleResumePregnantsWithChildBirth({
			startDate: selectedOptionStartDate,
			cnes: selectedUnit.value || cnes,
			ine: selectedHealthTeam.value,
			endDate: selectedOptionEndDate,
			ibgeCode: ibgeCode,
			uf: uf,
		});
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	async function fetchResume() {
		const cacheKey = `[handleResumePregnantsWithChildBirth][${selectedOptionStartDate}][${selectedOptionEndDate}][${ibgeCode}][${uf}][${
			selectedUnit.value || cnes
		}][${selectedHealthTeam.value}]`;
		setIsLoadingResume(true);
		setResumePregnant(
			await fetchWithCache(cacheKey, cacheExpirationDate, handleResumePregnant)
		);
		setIsLoadingResume(false);
	}

	async function fetch() {
		const cacheKey = `[PregnantsWithChildBirthPaginated][${uf}][${ibgeCode}][${selectedOptionStartDate}][${selectedOptionEndDate}][${
			selectedUnit.value || cnes
		}][${selectedHealthTeam.value}][${searchFullName}][${orderBy
			.map((item) => [item.property, item.method || "asc"].join(" "))
			.join(",")}][${pageNumber}]`;
		setIsLoading(true);
		setPregnantPaginated(
			await fetchWithCache(cacheKey, cacheExpirationDate, handleMonitoringPregnantPaginated)
		);
		setIsLoading(false);
	}

	async function handleExportTable() {
		setShowLoadingScreen(true);
		const response = await handleMonitoringPregnantPaginated(pregnantPaginated?.totalRecords);
		setShowLoadingScreen(false);

		if (Object.keys(response?.data || []).length) {
			const excelData: GenericObject[] = transformToExportExcel(response?.data);
			exportExcel(excelData, `Tabela`);
		}
	}

	function transformToExportExcel(pregnants: Pregnancy[]) {
		return pregnants.map(
			(pregnant) =>
				({
					NOME: pregnant.fullName,
					DN: dayjs(pregnant.birthdate).format("DD/MM/YYYY"),
					CPF: pregnant.cpf,
					CNS: pregnant.cns,
					TELEFONE: pregnant.phone,
					GENERO: pregnant.gender,
					"STATUS PRESSÃO ALTA": pregnant.haveHighBloodPressure,
					"STATUS ALCOOLICO":
						pregnant.alcoholicStatus === 1
							? "SIM"
							: pregnant.alcoholicStatus === 0
							? "NÃO"
							: "N/A",
					"STATUS FUMANTE":
						pregnant.smokerStatus === 1
							? "SIM"
							: pregnant.smokerStatus === 0
							? "NÃO"
							: "N/A",
					MC: pregnant.area,
					RAÇA: pregnant.racialColor,
					UNIDADE: pregnant.healthUnit?.name,
					EQUIPE: pregnant.healthTeam?.name,
					DPP: dayjs(pregnant.dppDate).format("DD/MM/YYYY"),
					DUM: dayjs(pregnant.firstDumDate).format("DD/MM/YYYY"),
					IG: pregnant.firstGestationalAge,
					PARTO: pregnant.childBirthDate
						? dayjs(pregnant.childBirthDate).format("DD/MM/YYYY")
						: "-",
					"TIPO PARTO":
						pregnant.childBirthType === "normal"
							? "Normal"
							: pregnant.childBirthType === "cesarean"
							? "Cesário"
							: pregnant.childBirthType === "abortion"
							? "Aborto"
							: pregnant.childBirthType === "normalstillbirth"
							? "Natimorto (normal)"
							: pregnant.childBirthType === "cesareanstillbirth"
							? "Natimorto (cesário)"
							: pregnant.childBirthType === "unspecified"
							? "Não especificado"
							: pregnant.childBirthType === "unregistered"
							? "Não registrado"
							: "-",
					PUERPERIO:
						pregnant.puerperalStatus === "finished"
							? "Concluído"
							: pregnant.puerperalStatus === "pending"
							? "Pendente"
							: pregnant.puerperalStatus === "lost"
							? "Perdido"
							: "-",
					CONSULTAS: pregnant.attendances.length || 0,
				} as GenericObject)
		);
	}

	useEffect(() => {
		if (pageNumber !== 0) {
			fetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
			return;
		}
		fetch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFullName, orderBy]);

	useEffect(() => {
		setTitle("SAÚDE DA GESTANTE");
		fetchResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="mx-2" style={{ minWidth: "320px" }}>
			<LoadingScreen loading={showLoadingScreen} />
			<Card className="my-2">
				<Card.Body className="m-0 p-4">
					<Form
						className="mt-2"
						onSubmit={(event) => {
							event.preventDefault();
							fetchResume();
							fetch();
						}}
					>
						<Row className="d-flex justify-content-between align-items-end">
							<Col sm={12} lg={4}>
								<Form.Label className="align-items-center fw-bolder">
									Período
								</Form.Label>
								<InputGroup className="gap-1">
									<Form.Control
										type="date"
										placeholder="Data inicial"
										min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
										max={dayjs().format("YYYY/MM/DD")}
										defaultValue={dayjs().subtract(1, "M").format("YYYY-MM-DD")}
										onChange={(event) =>
											setSelectedOptionStartDate(event.target.value)
										}
									/>
									<Form.Control
										type="date"
										placeholder="data final"
										defaultValue={dayjs().format("YYYY-MM-DD")}
										min={selectedOptionStartDate}
										max={dayjs().format("YYYY-MM-DD")}
										onChange={(event) =>
											setSelectedOptionEndDate(event.target.value)
										}
									/>
								</InputGroup>
							</Col>
							<Col sm={3} md={4} lg={4} xl={3}>
								<UnitsSelect
									units={units}
									onChange={(event) => setSelectedUnit(event as Option<string>)}
									defaultValue={{ value: "", label: "selecione..." }}
									noOptionsMessage={() => "Nenhuma unidade encontrada"}
								/>
							</Col>
							<Col sm={3} md={4} lg={4} xl={3}>
								<HealthTeamsSelect
									healthTeams={healthTeams}
									onChange={(event) =>
										setSelectedHealthTeam(event as Option<string>)
									}
									defaultValue={{ value: "", label: "selecione..." }}
									noOptionsMessage={() => "Nenhuma unidade encontrada"}
								/>
							</Col>
							<Col sm={2} md={2} lg={2} className="mt-2">
								<Button type="submit" className="w-100" disabled={isLoading}>
									<BsSearch className="me-2" />
									Consultar
								</Button>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<PregnancyResume
						isLoadingResume={isLoadingResume}
						totalPregnants={totalPregnants}
						totalRegisteredChildBirth={totalRegisteredChildBirth}
						totalUnregisteredChildBirth={totalUnregisteredChildBirth}
						totalRiskPregnants={totalRiskPregnants}
						averagePregnantAge={resumePregnant?.averagePregnantAge || 0}
						averageGestationalAge={resumePregnant?.averageGestationalAge || 0}
					/>
				</Card.Body>
			</Card>
			<Row className="w-100 my-5 d-flex justify-content-stretch mx-0">
				<Col
					md={8}
					lg={8}
					xl={8}
					className="d-flex flex-column align-items-center justify-content-center mb-3"
				>
					<Card className="w-100 h-100">
						<Card.Header>
							<Card.Title>PROPORÇÃO DE CAPTAÇÃO PRECOCE</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center p-0">
							{!isLoadingResume &&
							resumePregnant?.resumePregnancyStatus &&
							resumePregnant?.resumeChildBirthType ? (
								<DonutChart
									series={[
										resumePregnant?.resumeGestationalAgeTrimester.find(
											(resume) => resume.key === 1
										)?.total || 0,
										resumePregnant?.resumeGestationalAgeTrimester.find(
											(resume) => resume.key === 2
										)?.total || 0,
										resumePregnant?.resumeGestationalAgeTrimester.find(
											(resume) => resume.key === 3
										)?.total || 0,
									]}
									labels={["1º Trimestre", "2º Trimestre", "3º Trimestre"]}
									colors={["#008ffb", "#00e396", "#ffc700"]}
									width={450}
								/>
							) : (
								<Skeleton className="my-2" width={250} height={250} circle />
							)}
						</Card.Body>
					</Card>
				</Col>

				<Col
					md={4}
					lg={4}
					xl={4}
					className="d-flex flex-column align-items-center justify-content-center"
				>
					<Card className="w-100 justify-content-center align-items-center">
						<Card.Header>
							<Card.Title className="d-flex justify-content-center w-100">
								FINALIZAÇÕES GESTACIONAIS
							</Card.Title>
						</Card.Header>
						{!isLoadingResume && resumePregnant?.resumeChildBirthType ? (
							<SemiDonut
								series={[
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.key === "normal"
									)?.total || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.key === "cesarean"
									)?.total || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.key === "abortion"
									)?.total || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.key === "normalstillbirth"
									)?.total || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.key === "cesareanstillbirth"
									)?.total || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.key === "unspecified"
									)?.total || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.key === "unregistered"
									)?.total || 0,
								]}
								descriptionsLabels={[
									"Normal",
									"Cesário",
									"Aborto",
									"Natimorto (normal)",
									"Natimorto (cesário)",
									"Não especificado",
									"Não registrado",
								]}
								height={200}
								colors={[
									"#008ffb",
									"#00e396",
									"#feb019",
									"#ff4560",
									"#775dd0",
									"#998877",
									"#bbbbbb",
								]}
							/>
						) : (
							<Skeleton className="my-2" width={150} height={150} circle />
						)}
					</Card>
					<Card className="w-100 my-2 justify-content-center align-items-center">
						<Card.Header>
							<Card.Title className="d-flex justify-content-center w-100">
								CONSULTA PUERPERAL (ATÉ 42º DIA)
							</Card.Title>
						</Card.Header>
						{!isLoadingResume && resumePregnant?.resumePuerperalStatus ? (
							<SemiDonut
								series={[
									resumePregnant?.resumePuerperalStatus?.find(
										(s) => s.key === "finished"
									)?.total || 0,
									resumePregnant?.resumePuerperalStatus?.find(
										(s) => s.key === "pending"
									)?.total || 0,
									resumePregnant?.resumePuerperalStatus?.find(
										(s) => s.key === "lost"
									)?.total || 0,
								]}
								descriptionsLabels={["Realizado", "Pendente", "Fora do prazo"]}
								height={200}
								colors={["#009ef7", "#ffc700", "#d9214e"]}
							/>
						) : (
							<Skeleton className="my-2" width={150} height={150} circle />
						)}
					</Card>
				</Col>
			</Row>
			<Row className="my-2">
				<Col>
					<Card>
						<Card.Body>
							<PregnancyFilters
								fullName={searchFullName}
								setFullName={setSearchFullName}
								handleExportTable={handleExportTable}
								isLoading={showLoadingScreen}
							/>
							<PregnancyTable
								isLoading={isLoading}
								pregnantsWithChildBirth={pregnantPaginated.data}
								orderBy={orderBy}
								setOrderBy={setOrderBy}
							/>
						</Card.Body>
						<Card.Footer>
							{pregnantPaginated?.totalRecords ? (
								<PaginationLinks
									itemsPerPage={pregnantPaginated.pageSize}
									totalPages={pregnantPaginated.totalRecords}
									changeSelectedPage={handleChangePageNumber}
									pageNumber={pageNumber || 0}
								/>
							) : (
								!isLoading && (
									<NoRecordsFeedback
										message={"Nenhum resultado encontrado."}
										icon={<FaUser />}
									/>
								)
							)}
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
