import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

type Props = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	categories: string[];
};

export function AttendanceProcedureHistoryChart({ series, categories }: Props) {
	const attendanceProcedureOptions: ApexOptions = {
		series: series,
		dataLabels: {
			enabled: true,
			offsetY: -10,
		},
		chart: {
			type: "line",
			redrawOnWindowResize: true,
			foreColor: "black",
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			type: "category",
			categories: categories,
		},
		yaxis: {
			opposite: false,
		},
		markers: {
			size: 8,
			colors: undefined,
			strokeColors: "#fff",
			strokeWidth: 2,
			strokeOpacity: 0.9,
			strokeDashArray: 0,
			fillOpacity: 1,
			discrete: [],
			shape: "circle",
			radius: 2,
			offsetX: 0,
			offsetY: 0,
			showNullDataPoints: true,
			hover: {
				size: undefined,
				sizeOffset: 3,
			},
		},
		grid: {
			show: true,
			position: "back",
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
	};

	return (
		<ReactApexChart
			height={"100%"}
			series={attendanceProcedureOptions.series}
			options={attendanceProcedureOptions}
			type="line"
		/>
	);
}
