/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLayout } from "../core";

const Footer: FC = () => {
	const { classes } = useLayout();
	return (
		<div className="footer py-1 d-flex flex-lg-column mt-auto" id="kt_footer">
			<div
				className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
			>
				<div className="text-dark order-2 order-md-1">
					<span className="text-muted fw-bold me-2">
						{new Date().getFullYear()} &copy;
					</span>
					<a
						href="https://multintegrada.com.br/"
						target="_blank"
						className="text-gray-800 text-hover-primary"
						rel="noreferrer"
					>
						Multintegrada
					</a>
				</div>
			</div>
		</div>
	);
};

export { Footer };
