import { useState } from "react";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
	PregnancyByCityAndRacialColorResume,
	PregnancyByCityAndRacialColorTable,
} from "../PregnancyByCityAndRacialColorTable";
import { PregnancyByCityAndRacialColorTableFilters } from "../PregnancyByCityAndRacialColorTableFilters";

type Props = ModalProps & {
	pregnancyByCityAndRacialColorResumes: PregnancyByCityAndRacialColorResume[];
};

export function PregnancyByCityAndRacialColorModal({
	pregnancyByCityAndRacialColorResumes,
	...rest
}: Props) {
	const [cityName, setCityName] = useState("");
	const pregnancyByCityAndRacialColorResumesFiltered =
		pregnancyByCityAndRacialColorResumes.filter((resume) =>
			resume.city.locale.toUpperCase().includes(cityName.toUpperCase())
		);
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">
						GESTANTES POR CIDADE E RAÇA/COR
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="mb-4">
					<Col>
						<PregnancyByCityAndRacialColorTableFilters
							cityName={cityName}
							setCityName={setCityName}
						/>
					</Col>
				</Row>
				<PregnancyByCityAndRacialColorTable
					pregnancyByCityAndRacialColorResumes={
						pregnancyByCityAndRacialColorResumesFiltered
					}
				/>
			</Modal.Body>
		</Modal>
	);
}
