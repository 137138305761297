import { RiFileExcel2Fill } from "react-icons/ri";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { SearchInput } from "@/components/SearchInput";

type Props = {
	isLoading: boolean;
	fullName: string;
	setFullName: (fullName: string) => void;
	handleExportTable: () => void;
};

export function PregnancyFilters({ fullName, setFullName, isLoading, handleExportTable }: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
			}}
		>
			<Row className="d-flex justify-content-between align-items-end">
				<Col sm={12} md={12} lg={6} xl={6} className=" mb-2">
					<div>
						<label className="form-label text-nowrap">Pesquisar Cidadão:</label>
						<SearchInput
							placeholder="Pesquisar nome"
							value={fullName}
							setValue={setFullName}
						/>
					</div>
				</Col>
				<Col xs={12} sm={6} md={2} lg={2} xl={2} className="d-flex flex-column my-2">
					<Button
						variant="secondary"
						className="px-2"
						disabled={isLoading}
						onClick={handleExportTable}
					>
						<RiFileExcel2Fill />
						Exportar
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
