import { useState } from "react";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ResumeGrouped } from "@/services/esus/attendanceService";
import { Procedure } from "@/@types/Procedure";

import { AttendanceProcedureRateByProcedureTableFilters } from "../AttendanceProcedureRateByProcedureTableFilters";
import { AttendanceProcedureRateByProcedureTable } from "../AttendanceProcedureRateByProcedureTable";

type Props = ModalProps & {
	aggregatedAttendanceProcedures: ResumeGrouped<Procedure, object>[];
};

export function AttendanceProcedureRateByProcedureModal({
	aggregatedAttendanceProcedures,
	...rest
}: Props) {
	const [procedureDescription, setProcedureDescription] = useState("");
	const aggregatedAttendanceProceduresFiltered = aggregatedAttendanceProcedures.filter(
		(aggregatedAttendanceProcedure) =>
			aggregatedAttendanceProcedure.key.name
				.toUpperCase()
				.includes(procedureDescription.toUpperCase()) ||
			aggregatedAttendanceProcedure.key.procedureCode
				.toUpperCase()
				.includes(procedureDescription.toUpperCase())
	);

	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">
						Ranking de procedimentos
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="mb-8">
					<Col>
						<AttendanceProcedureRateByProcedureTableFilters
							procedureDescription={procedureDescription}
							setProcedureDescription={setProcedureDescription}
						/>
					</Col>
				</Row>
				<AttendanceProcedureRateByProcedureTable
					aggregatedAttendanceProcedures={aggregatedAttendanceProceduresFiltered}
				/>
			</Modal.Body>
		</Modal>
	);
}
