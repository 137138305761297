import dayjs from "dayjs";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InputGroup from "react-bootstrap/InputGroup";
import { FaSearch } from "react-icons/fa";

import { ExportTableType } from "@/@types/ExportTableType";
import { Account } from "@/@types/Account";
import { Option } from "@/@types/Option";

import { OptionsPopover } from "@/components/Popovers/OptionsPopover";
import { CitiesSelect } from "@/components/CitiesSelect";

type Props = {
	cities: Account[];
	setSelectedIbgeCode: (selectedIbgeCode: string) => void;
	startDate: string;
	setStartDate: (startDate: string) => void;
	endDate: string;
	setEndDate: (endDate: string) => void;
	handleFilter: () => void;
	handleExportTable?: (printOption: ExportTableType) => void;
	isPrinting?: boolean;
};

export function PregnancyDashboardFilters({
	cities,
	setSelectedIbgeCode,
	startDate,
	setStartDate,
	setEndDate,
	handleFilter,
	handleExportTable,
	isPrinting,
}: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter();
			}}
		>
			<Row>
				<Col sm={12} md={6} lg={6} xl={6} className="d-flex flex-column">
					<label className="form-label text-nowrap">Período</label>
					<div className="d-flex">
						<InputGroup className="mb-3 me-3">
							<Form.Control
								type="date"
								placeholder="Data inicial"
								min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY/MM/DD")}
								defaultValue={dayjs().subtract(4, "M").format("YYYY-MM-DD")}
								onChange={(event) => setStartDate(event.target.value)}
							/>
						</InputGroup>
						<InputGroup className="mb-3 me-3">
							<Form.Control
								type="date"
								placeholder="Data final"
								defaultValue={dayjs().format("YYYY-MM-DD")}
								min={startDate}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setEndDate(event.target.value)}
							/>
						</InputGroup>
					</div>
				</Col>

				<Col
					sm={12}
					md={6}
					lg={6}
					xl={6}
					className="d-flex align-items-end justify-content-between mb-3"
				>
					<div className="me-2">
						<Button type="submit" variant="primary">
							Pesquisar <FaSearch />
						</Button>
					</div>
					{handleExportTable && (
						<div className="me-2">
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={(props) =>
									OptionsPopover({
										handlePrintClick: () => handleExportTable("print"),
										handleExportExcelClick: () => handleExportTable("excel"),
										...props,
									})
								}
								rootClose
							>
								<Button variant="secondary" className="px-2" disabled={isPrinting}>
									Opções
								</Button>
							</OverlayTrigger>
						</div>
					)}
				</Col>
				<Col sm={6} md={6} lg={6} xl={6}>
					<CitiesSelect
						isDisabled={cities.length === 0}
						accounts={[{ locale: "", ibgeCode: "" }].concat(cities)}
						onChange={(option) => setSelectedIbgeCode((option as Option<string>).value)}
					/>
				</Col>
			</Row>
		</Form>
	);
}
