import { get } from "../api";
import { ActiveChildVaccination } from "@/@types/ActiveChildVaccination";
import { ActivePregnantVaccination } from "@/@types/ActivePregnantVaccination";
import { ActiveTeenVaccination } from "@/@types/ActiveTeenVaccination";
import { Paginated } from "@/@types/paginated";
import { ActiveOlderVaccination } from "@/@types/ActiveOlderVaccination";

export type ActiveVaccinationResume = {
	totalCitizens: number;
	immunobiologicalName: string;
	immunobiologicalPreview: number;
	immunobiologicalDelayed: number;
};

type ActiveVaccinationParams = {
	uf: string;
	ibgeCode: string;
	pageNumber: number;
	fullName: string;
	cnes: string;
	ine: string;
	pageSize?: number;
	immunobiologicalCod: number;
};

type ActiveVaccinationResumeParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	ine: string;
	immunobiologicalCod: number;
};

export async function handleActiveChildVaccinationResume(params: ActiveVaccinationResumeParams) {
	const response = await get<ActiveVaccinationResume[]>(
		`/ActiveVaccination/v1/GetResumeActiveVaccinationChild`,
		params
	);
	return response.data || [];
}

export async function handleActiveTeenVaccinationResume(params: ActiveVaccinationResumeParams) {
	const response = await get<ActiveVaccinationResume[]>(
		`/ActiveVaccination/v1/GetResumeActiveVaccinationTeen`,
		params
	);
	return response.data || [];
}

export async function handleActiveOlderVaccinationResume(params: ActiveVaccinationResumeParams) {
	const response = await get<ActiveVaccinationResume[]>(
		`/ActiveVaccination/v1/GetResumeActiveVaccinationOlder`,
		params
	);
	return response.data || [];
}

export async function handleActivePregnantVaccinationResume(params: ActiveVaccinationResumeParams) {
	const response = await get<ActiveVaccinationResume[]>(
		`/ActiveVaccination/v1/GetResumeActiveVaccinationPregnant`,
		params
	);
	return response.data || [];
}

export async function handleActiveChildVaccinationSearch(params: ActiveVaccinationParams) {
	const response = await get<Paginated<ActiveChildVaccination>>(
		`/ActiveVaccination/v1/GetChilds`,
		params
	);
	return response.data || ({} as Paginated<ActiveChildVaccination>);
}

export async function handleActiveTeenVaccinationSearch(params: ActiveVaccinationParams) {
	const response = await get<Paginated<ActiveTeenVaccination>>(
		`/ActiveVaccination/v1/GetTeens`,
		params
	);
	return response.data || ({} as Paginated<ActiveTeenVaccination>);
}

export async function handleActiveOlderVaccinationSearch(params: ActiveVaccinationParams) {
	const response = await get<Paginated<ActiveOlderVaccination>>(
		`/ActiveVaccination/v1/GetOlders`,
		params
	);
	return response.data || ({} as Paginated<ActiveOlderVaccination>);
}

export async function handleActivePregnantVaccinationSearch(params: ActiveVaccinationParams) {
	const response = await get<Paginated<ActivePregnantVaccination>>(
		`/ActiveVaccination/v1/GetPregnants`,
		params
	);
	return response.data || ({} as Paginated<ActivePregnantVaccination>);
}
