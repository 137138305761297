import Form from "react-bootstrap/Form";

import { SearchInput } from "@/components/SearchInput";

type Props = {
	cityName: string;
	setCityName: (cityName: string) => void;
};

export function PregnancyByCityAndAgeTableFilters({ cityName, setCityName }: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
			}}
		>
			<div className="d-flex">
				<SearchInput
					placeholder="Pesquisar município"
					value={cityName}
					setValue={setCityName}
				/>
			</div>
		</Form>
	);
}
