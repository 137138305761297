import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { ProfessionalAttendanceResumeCard } from "./ProfessionalAttendanceResumeCard";

type ProfessionalAttendanceResumeParams = {
	totalAttendance: number;
	totalAttendedWithSchedule: number;
	totalAttendedWithoutSchedule: number;
	totalAbsence: number;
};

export function ProfessionalAttendanceResume({
	totalAttendance,
	totalAttendedWithSchedule,
	totalAttendedWithoutSchedule,
	totalAbsence,
}: ProfessionalAttendanceResumeParams) {
	return (
		<Row className="d-flex justify-content-between m-0 gap-4 text-center">
			<Col className="d-flex rounded" style={{ backgroundColor: "#0aa7ff" }}>
				<ProfessionalAttendanceResumeCard
					title="Atendimentos"
					content={totalAttendance ? totalAttendance.toLocaleString() : "0"}
					titleClassName="text-white"
					contentClassName="text-white"
					backgroundStyle={{ backgroundColor: "#0aa7ff" }}
					backgroundImageUrl="/media/images/card-icons/notes.svg"
					metaDataHeader="Atendimentos"
					metaDataDescription="Quantidade de pessoas atendidas(atendimento consolidado) no período"
				/>
			</Col>
			<Col className="d-flex rounded" style={{ backgroundColor: "#00e195" }}>
				<ProfessionalAttendanceResumeCard
					title="Atendimentos com agendamento"
					content={
						totalAttendedWithSchedule ? totalAttendedWithSchedule.toLocaleString() : "0"
					}
					titleClassName="text-white"
					contentClassName="text-white"
					backgroundStyle={{ backgroundColor: "#00e195" }}
					backgroundImageUrl="/media/images/card-icons/notes-checked.svg"
					metaDataHeader="Atendidos com agendamento"
					metaDataDescription="Quantidade de pessoas atendidas(atendimentos consolidados e não consolidados) no período com agendamento"
				/>
			</Col>
			<Col className="d-flex rounded" style={{ backgroundColor: "#ffd52f" }}>
				<ProfessionalAttendanceResumeCard
					title="Atendimentos sem agendamento"
					content={
						totalAttendedWithoutSchedule
							? totalAttendedWithoutSchedule.toLocaleString()
							: "0"
					}
					titleClassName="text-white"
					contentClassName="text-white"
					backgroundStyle={{ backgroundColor: "#ffd52f" }}
					backgroundImageUrl="/media/images/card-icons/notes-question.svg"
					metaDataHeader="Atendimentos sem agendamento"
					metaDataDescription="Quantidade de atendimentos realizados(atendimentos consolidados) no período sem agendamento"
				/>
			</Col>
			<Col className="d-flex rounded" style={{ backgroundColor: "#f1416c" }}>
				<ProfessionalAttendanceResumeCard
					title="Absenteísmo"
					content={totalAbsence ? totalAbsence.toLocaleString() : "0"}
					titleClassName="text-white"
					contentClassName="text-white"
					backgroundStyle={{ backgroundColor: "#f1416c" }}
					backgroundImageUrl="/media/images/card-icons/notes-remove.svg"
					metaDataHeader="Absenteísmo"
					metaDataDescription="Quantidade de atendimentos com agendamento marcado em que houve desistência por parte do usuário sem aviso prévio"
				/>
			</Col>
		</Row>
	);
}
