import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TbPlayerPlayFilled } from "react-icons/tb";
import ProgressBar from "react-bootstrap/ProgressBar";

import { useApi } from "@/hooks/useApi";
import { TrackOrder } from "@/@types/TrackOrder";

import { GenericCarousel } from "../GenericCarousel";

import "./styles.scss";

export function ContinueWatching() {
	const api = useApi();
	const navigate = useNavigate();
	const [trackOrdersNotFinished, setTrackOrdersNotFinished] = useState<TrackOrder[]>([]);
	const lastVideosWatched = 3;

	async function handleTrackOrdersNotFinished() {
		try {
			const { data } = await api.get(`trackOrder/v1/getTrackOrdersNotFinished`);
			setTrackOrdersNotFinished(data || []);
		} catch (error) {
			setTrackOrdersNotFinished([]);
		}
	}

	useEffect(() => {
		handleTrackOrdersNotFinished();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{trackOrdersNotFinished.length > 0 && (
				<div>
					<h2 className="font-roboto p-2 fs-3">Continuar assistindo...</h2>
					<div className="d-flex flex-nowrap justify-content-evenly overflow-hidden">
						<div className="py-2">
							<GenericCarousel
								carouselItemFixedSize={3}
								carouselItemsProps={trackOrdersNotFinished
									.slice(0, lastVideosWatched)
									.map((item, index) => (
										<div key={`latest-video-${index}`} className="title-item">
											<img
												src={item.trackFile?.urlThumbnail}
												alt="img-thumbnail"
											/>
											<ProgressBar
												variant="danger"
												now={
													item.logUserViewTracks &&
													item.trackFile &&
													item.logUserViewTracks.length > 0
														? (item.logUserViewTracks[0]
																.currentTimeTrack /
																item.trackFile.duration) *
														  100
														: 0
												}
											/>
											<div className="items">
												<div>
													<button
														onClick={() =>
															navigate(
																`/ambiente-de-apoio/acesso-a-plataforma/tracks/${item.trackListId}/video/${item.sequenceOrder}`
															)
														}
														className="border boder-white rounded-circle p-2 d-flex justify-content-center bg-transparent"
													>
														<TbPlayerPlayFilled
															size={30}
															className="text-white"
														/>
													</button>
													<h2 className="fs-7 font-roboto">
														{item.trackFile?.title}
													</h2>
												</div>
											</div>
										</div>
									))}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
