import { IndicatorsData } from "@/services/esus/indicatorService";
import { calculateIndicatorDataPercentage } from "./calculatePercentage";

export function calculatePerformance(indicators: IndicatorsData) {
	const calculateIndicatorOne = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorOne)
	);
	const calculateIndicatorTwo = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorTwo)
	);
	const calculateIndicatorThree = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorThree)
	);
	const calculateIndicatorFour = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorFour)
	);
	const calculateIndicatorFive = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorFive)
	);
	const calculateIndicatorSix = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorSix)
	);
	const calculateIndicatorSeven = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorSeven)
	);

	return [
		calculateIndicatorOne,
		calculateIndicatorTwo,
		calculateIndicatorThree * 2,
		calculateIndicatorFour,
		calculateIndicatorFive * 2,
		calculateIndicatorSix * 2,
		calculateIndicatorSeven,
	].reduce((accumulator, current) => accumulator + current);
}
