import Modal, { ModalProps } from "react-bootstrap/Modal";

import { FormUnitSelection } from "../FormUnitSelection";

type Props = ModalProps;

export function ModalUnitSelection({ ...rest }: Props) {
	return (
		<Modal size="lg" scrollable {...rest}>
			<Modal.Header closeVariant="white" closeButton style={{ backgroundColor: "#0c6fd1" }}>
				<Modal.Title className="text-white">Seleção de unidades liberadas</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormUnitSelection />
			</Modal.Body>
		</Modal>
	);
}
