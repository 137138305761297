import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { DiseaseData } from "../..";
import { TimeLine, TimeLineContent } from "@/components/TimeLine";

type Props = ModalProps & {
	selectedCitizenDiseaseData: DiseaseData;
};

export function ModalCitizensWithDiseases({ selectedCitizenDiseaseData, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-7">Atendimentos</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-8 text-primary">Paciente: </span>
							<span className="fs-8">
								{selectedCitizenDiseaseData?.citizen?.fullName
									? selectedCitizenDiseaseData?.citizen?.fullName
									: "N/A"}
							</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">Data de nascimento: </span>
							<span className="fs-8">
								{selectedCitizenDiseaseData?.citizen?.birthdate
									? dayjs(selectedCitizenDiseaseData?.citizen?.birthdate).format(
											"DD/MM/YYYY"
									  )
									: "N/A"}
							</span>
						</Col>
					</Row>
				</section>
				<TimeLine
					timeLineTitle="Atendimentos"
					timeLineContents={selectedCitizenDiseaseData.problems?.map<TimeLineContent>(
						(attendanceProblem, index) => {
							return {
								content: (
									<section>
										<div>
											<p className="text-primary fs-8">Profissional:</p>
											<p>
												<span className="fw-bolder fs-8">Nome: </span>
												{attendanceProblem?.professional1?.name?.toUpperCase() ||
													"N/A"}
											</p>
											<p>
												<span className="fw-bolder fs-8">Categoria: </span>
												{attendanceProblem.cbo1?.description?.toUpperCase() ||
													"N/A"}
											</p>
											<p>
												<span className="fw-bolder fs-8">Unidade: </span>
												{attendanceProblem.healthUnit1?.name?.toUpperCase() ||
													"N/A"}
											</p>
										</div>

										<div>
											{attendanceProblem.cid?.cidCode && (
												<p className="fs-8">
													<span className="fw-bolder">Cid:</span>
													<span>
														{`${attendanceProblem.cid?.cidCode}-${attendanceProblem?.cid?.description}`}
													</span>
												</p>
											)}
											{attendanceProblem.ciap?.ciapCode && (
												<p>
													<span className="fw-bolder">Ciap:</span>
													<span>
														{`${attendanceProblem?.ciap?.ciapCode}-${attendanceProblem?.ciap?.description}`}
													</span>
												</p>
											)}
										</div>
									</section>
								),
								eventDate: attendanceProblem?.date
									? dayjs(attendanceProblem?.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>
			</Modal.Body>
		</Modal>
	);
}
