import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { PregnantPatient } from "@/@types/PregnantPatient";

import { ExamsCards } from "@/components/ExamsCard";
import { TimeLine } from "@/components/TimeLine";

type Props = ModalProps & {
	patientSelected: PregnantPatient;
};

export function ModalIndicator2({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-7">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="mb-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-8 text-primary">Paciente: </span>
							<span>{patientSelected?.fullName}</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">Data de nascimento: </span>
							<span>
								{patientSelected.birthdate
									? dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-8 text-primary">DUM: </span>
							<span>
								{patientSelected.firstDumDate
									? dayjs(patientSelected?.firstDumDate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-8 text-primary">DPP: </span>
							<span>
								{patientSelected.dppDate
									? dayjs(patientSelected?.dppDate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
					</Row>
				</section>

				<TimeLine
					timeLineTitle="SOROLOGIAS"
					timeLineContents={patientSelected.attendanceProceduresReqEvals?.map(
						(attendanceProcedure, index) => {
							return {
								content: (
									<ExamsCards
										key={`attendance-procedure-req-eval-${index}`}
										healthTeam={patientSelected?.healthTeam}
										healthUnit={patientSelected.healthUnit}
										procedure={
											attendanceProcedure.attProcedureEvaluated
												?.procedureCode !== "-"
												? attendanceProcedure.attProcedureEvaluated
												: attendanceProcedure.attProcedureRequested
										}
										isPendingExam={
											attendanceProcedure.attProcedureEvaluated
												?.procedureCode === "-"
										}
										professional={attendanceProcedure.professional1}
										cbo={attendanceProcedure.cbo1}
									/>
								),
								eventDate: attendanceProcedure?.date
									? dayjs(attendanceProcedure.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>

				<TimeLine
					timeLineTitle="TESTES"
					timeLineContents={patientSelected.attendanceProcedures?.map(
						(attendance, index) => {
							return {
								content: (
									<ExamsCards
										key={`attendance-procedure-${index}`}
										healthTeam={patientSelected.healthTeam}
										healthUnit={patientSelected.healthUnit}
										procedure={attendance.procedure}
										professional={attendance.professional}
										cbo={attendance.cbo}
									/>
								),
								eventDate: attendance.date
									? dayjs(attendance.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>
			</Modal.Body>
		</Modal>
	);
}
