import { useState } from "react";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ResumeGrouped } from "@/services/esus/attendanceService";
import { Ciap } from "@/@types/Ciap";

import { AttendanceRateByCiapTableFilters } from "../AttendanceRateByCiapTableFilters";
import { AttendanceRateByCiapTable } from "../AttendanceRateByCiapTable";

type Props = ModalProps & {
	aggregatedAttendanceCiaps: ResumeGrouped<Ciap, object>[];
};

export function AttendanceRateByCiapModal({ aggregatedAttendanceCiaps, ...rest }: Props) {
	const [ciapDescription, setCiapDescription] = useState("");
	const aggregatedAttendanceCiapsFiltered = aggregatedAttendanceCiaps.filter(
		(aggregatedAttendanceCiap) =>
			aggregatedAttendanceCiap.key.description
				.toUpperCase()
				.includes(ciapDescription.toUpperCase()) ||
			aggregatedAttendanceCiap.key.ciapCode
				.toUpperCase()
				.includes(ciapDescription.toUpperCase())
	);

	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">
						Ranking de atendimentos por ciap
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="mb-8">
					<Col>
						<AttendanceRateByCiapTableFilters
							ciapDescription={ciapDescription}
							setCiapDescription={setCiapDescription}
						/>
					</Col>
				</Row>
				<AttendanceRateByCiapTable
					aggregatedAttendanceCiaps={aggregatedAttendanceCiapsFiltered}
				/>
			</Modal.Body>
		</Modal>
	);
}
