import { Paginated } from "@/@types/paginated";
import { Problem } from "@/@types/Problem";
import { get } from "../api";

type ProblemParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	cids: string[];
	ciaps: string[];
	youngerAge: number;
	olderAge: number;
	startDate: string;
	endDate: string;
	gender: string;
	pageNumber: number;
	pageSize?: number;
};
export async function fetchProblemPaginated(problemParams: ProblemParams) {
	const response = await get<Paginated<Problem>>(`/Problem/v1/Get`, problemParams);
	return response.data || ({} as Paginated<Problem>);
}
