import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Account } from "@/@types/Account";
import { BarChart } from "@/components/Charts/BarChart";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { LegendData } from "@/components/LegendData";

export type ResumeDiseasesChronicTransmissibleOfCities = {
	city: Account;
	totalHansen: number;
	totalAttendancesCitizensWithHansen: number;
	totalTuberculosis: number;
	totalAttendancesCitizensWithTuberculosis: number;
};

type Props = {
	resumeDiseasesChronicTransmissibleOfCities: ResumeDiseasesChronicTransmissibleOfCities[];
};

export function ChronicCommunicableDiseases({ resumeDiseasesChronicTransmissibleOfCities }: Props) {
	return (
		<Row>
			<Col sm={12} md={12} lg={12} xl={6}>
				<Card>
					<Card.Header>
						<Card.Title>
							<label className="me-2"> Tuberculose </label>
							<CardOverlayTrigger
								children={
									<LegendData
										ClassNamesColors={["text-primary", "text-success"]}
										titles={["Atendidos", "Não atendidos"]}
									/>
								}
							/>
						</Card.Title>
					</Card.Header>
					<Card.Body className="overflow-auto" style={{ maxHeight: 535 }}>
						<BarChart
							categories={
								resumeDiseasesChronicTransmissibleOfCities?.map(
									(resume) => resume.city.locale
								) || []
							}
							series={[
								{
									name: "Atendidos",
									data:
										resumeDiseasesChronicTransmissibleOfCities?.map(
											(resume) =>
												resume.totalAttendancesCitizensWithTuberculosis
										) || [],
								},
								{
									name: "Não atendidos",
									data:
										resumeDiseasesChronicTransmissibleOfCities?.map(
											(resume) =>
												resume.totalTuberculosis -
												resume.totalAttendancesCitizensWithTuberculosis
										) || [],
								},
							]}
							colors={["#008FFB", "#F1416C"]}
							isStacked
						/>
					</Card.Body>
				</Card>
			</Col>
			<Col sm={12} md={12} lg={12} xl={6}>
				<Card>
					<Card.Header>
						<Card.Title>
							<label className="me-2"> Hanseníase</label>
							<CardOverlayTrigger
								children={
									<LegendData
										ClassNamesColors={["text-primary", "text-success"]}
										titles={["Atendidos", "Não atendidos"]}
									/>
								}
							/>
						</Card.Title>
					</Card.Header>
					<Card.Body className="overflow-auto" style={{ maxHeight: 535 }}>
						<BarChart
							categories={
								resumeDiseasesChronicTransmissibleOfCities?.map(
									(resume) => resume.city.locale
								) || []
							}
							series={[
								{
									name: "Atendidos",
									data:
										resumeDiseasesChronicTransmissibleOfCities?.map(
											(resume) => resume.totalAttendancesCitizensWithHansen
										) || [],
								},
								{
									name: "Não atendidos",
									data:
										resumeDiseasesChronicTransmissibleOfCities?.map(
											(resume) =>
												resume.totalHansen -
												resume.totalAttendancesCitizensWithHansen
										) || [],
								},
							]}
							colors={["#008FFB", "#F1416C"]}
							isStacked
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}
