import { Account } from "@/@types/Account";

import { IndicatorsData } from "@/services/esus/indicatorService";

import { PrevineBrasilComponent } from "@/pages/PrevineBrasilDashboard/PrevineBrasilComponent";

type Props = {
	city: Account;
	quarter: string;
	indicators: IndicatorsData;
	resourceRef: React.RefObject<HTMLDivElement>;
};

export function CityAdminReportContent({ city, quarter, indicators, resourceRef }: Props) {
	return (
		<div id="resource-report-container" style={{ maxHeight: "100%" }} ref={resourceRef}>
			<div id="resource-report-content" className="overflow-y-scroll">
				<PrevineBrasilComponent
					city={city?.locale || ""}
					indicators={indicators}
					quarter={quarter}
				/>
			</div>
		</div>
	);
}
