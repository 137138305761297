import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import { BsSearch } from "react-icons/bs";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { AttendanceAggregatorEnum } from "@/utils/enums/AttendanceAggregatorEnum";
import { removeDuplicates } from "@/utils/genericals";
import { DiseasesEnum } from "@/utils/enums/DiseasesEnum";
import { Account } from "@/@types/Account";
import { Option } from "@/@types/Option";

import { PieChart } from "@/components/Charts/PieChart";
import { LoadingScreen } from "@/components/LoadingScreen";
import { BarChart } from "@/components/Charts/BarChart";
import { AreaChart } from "@/components/Charts/AreaChart";

type DashboardData<T, K> = {
	key: T;
	total: number;
	values?: K[];
};

type AggregatorKey = "PERIOD" | "AGEGROUP" | "GENDER" | "CID" | "CIAP";

type ResultType = {
	[key: string]: string[] | number[];
};

type AggregatedDashboard = {
	aggregator: AttendanceAggregatorEnum;
	name: string;
	values: DashboardData<string, object>[];
};

type DashboardResume = {
	city: Account;
	resume: AggregatedDashboard[];
};

const chronicCommunicableDiseasesOptions: Option<string>[] = [
	{ label: "Hanseniase", value: String(DiseasesEnum.HANSEN) },
	{ label: "Tuberculose", value: String(DiseasesEnum.TUBERCULOSIS) },
];

export function ChronicCommunicableDiseasesFederalUnitDashboard() {
	const api = useApi();
	const { setTitle } = useLayout();
	const { uf } = useCurrentAccount();
	const [isLoading, setIsloading] = useState(false);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState<string>(
		dayjs().subtract(4, "months").format("YYYY-MM-DD")
	);
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState<string>(
		dayjs().format("YYYY-MM-DD")
	);
	const [aggregatedDashboards, setAggregatedDashboards] = useState<DashboardResume[]>([]);

	const [selectedOptionDisease, setSelectedOptionDisease] = useState<Option<string>>({
		label: "Hanseniase",
		value: String(DiseasesEnum.HANSEN),
	} as Option<string>);

	async function fetchChronicCommunicableDiseasesDashboard() {
		try {
			const { data } = await api.get<DashboardResume[]>(
				"/FederalUnitAttendance/v1/ChronicCommunicableDiseasesDashboard",
				{
					params: {
						uf: uf,
						startDate: selectedOptionStartDate,
						endDate: selectedOptionEndDate,
						epidemicType: selectedOptionDisease.value,
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	const resumes = aggregatedDashboards
		?.map((city) => city.resume)
		.reduce((acc, current) => [...acc, ...current], []);

	function resumeByAggregator(typeAggregator: AttendanceAggregatorEnum) {
		const groupedLabels = removeDuplicates<string[]>(
			resumes
				?.filter((resume) => resume.aggregator === typeAggregator)
				.map((resume) => resume.values.map((value) => value.key))
				.reduce((acc, curr) => [...acc, ...curr], [])
		);
		const groupedValues = resumes
			?.filter((resume) => resume.aggregator === typeAggregator)
			.map((resume) => resume.values)
			.reduce((acc, curr) => [...acc, ...curr], []);
		const resumeGroups = groupedLabels.map<DashboardData<string, number>>((groupLabel) => {
			return {
				key: groupLabel,
				total: groupedValues
					.filter((groupedValue) => groupedValue.key === groupLabel)
					.map((groupValue) => groupValue.total)
					.reduce((acc, curr) => acc + curr, 0),
			};
		});

		return resumeGroups;
	}

	function getEnumKey(key: AggregatorKey): AttendanceAggregatorEnum {
		return AttendanceAggregatorEnum[key];
	}

	const aggregators: { key: AggregatorKey; categoryVar: string; seriesVar: string }[] = [
		{
			key: "PERIOD",
			categoryVar: "attendancesCategoriesByPeriod",
			seriesVar: "attendancesSeriesByPeriod",
		},
		{ key: "AGEGROUP", categoryVar: "ageRangesCategories", seriesVar: "ageRangesSeries" },
		{ key: "GENDER", categoryVar: "genderCategories", seriesVar: "genderSeries" },
		{
			key: "CID",
			categoryVar: "attendancesByCidCategories",
			seriesVar: "attendancesByCidSeries",
		},
		{
			key: "CIAP",
			categoryVar: "attendancesByCiapCategories",
			seriesVar: "attendancesByCiapSeries",
		},
	];

	const result: ResultType = {} as ResultType;

	function initializeResult() {
		aggregators.forEach((agg) => {
			const aggregatedData = resumeByAggregator(getEnumKey(agg.key)) || [];
			result[agg.categoryVar] = aggregatedData.map((item) => item.key);
			result[agg.seriesVar] = aggregatedData.map((item) => item.total);
		});
	}

	initializeResult();

	const {
		attendancesCategoriesByPeriod,
		attendancesSeriesByPeriod,
		ageRangesCategories,
		ageRangesSeries,
		genderCategories,
		genderSeries,
		attendancesByCidCategories,
		attendancesByCidSeries,
		attendancesByCiapCategories,
		attendancesByCiapSeries,
	} = result as {
		attendancesCategoriesByPeriod: string[];
		attendancesSeriesByPeriod: number[];
		ageRangesCategories: string[];
		ageRangesSeries: number[];
		genderCategories: string[];
		genderSeries: number[];
		attendancesByCidCategories: string[];
		attendancesByCidSeries: number[];
		attendancesByCiapCategories: string[];
		attendancesByCiapSeries: number[];
	};

	async function fetchDashboard() {
		setIsloading(true);
		setAggregatedDashboards(await fetchChronicCommunicableDiseasesDashboard());
		setIsloading(false);
	}

	useEffect(() => {
		setTitle("DASHBOARD DE DOENÇAS CRÔNICAS");
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<LoadingScreen
				loading={isLoading}
				messages={[
					"Já estamos processando a consulta do periodo.",
					"aguarde alguns instantes.",
					"Estamos quase lá!",
				]}
			/>
			<Form
				onSubmit={(event) => {
					event.preventDefault();
					fetchDashboard();
				}}
			>
				<Row className="d-flex justify-content-between">
					<Col sm={12} lg={4} className="d-flex align-items-center gap-3">
						<Form.Label className="align-items-center fw-bolder">Período</Form.Label>
						<InputGroup className="mb-3">
							<Form.Control
								type="date"
								placeholder="Data inicial"
								min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY/MM/DD")}
								defaultValue={dayjs().subtract(4, "M").format("YYYY-MM-DD")}
								onChange={(event) => setSelectedOptionStartDate(event.target.value)}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<Form.Control
								type="date"
								placeholder="data final"
								defaultValue={dayjs().format("YYYY-MM-DD")}
								min={selectedOptionStartDate}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setSelectedOptionEndDate(event.target.value)}
							/>
						</InputGroup>
					</Col>
					<Col sm={12} lg={4} className="d-flex align-items-center gap-3">
						<Form.Label>Epidemia</Form.Label>
						<InputGroup className="mb-3">
							<Select
								className="w-100"
								name="epidemic"
								options={chronicCommunicableDiseasesOptions}
								onChange={(event) =>
									setSelectedOptionDisease(event as Option<string>)
								}
								defaultValue={{
									label: "Hanseniase",
									value: String(DiseasesEnum.HANSEN),
								}}
								noOptionsMessage={() => "nenhum resultado encontrado na pesquisa"}
							/>
						</InputGroup>
					</Col>
					<Col sm={12} lg={2}>
						<Button type="submit" className="w-100" disabled={isLoading}>
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</Col>
				</Row>
			</Form>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por período
						</Card.Header>
						<Card.Body>
							<AreaChart
								categories={attendancesCategoriesByPeriod}
								series={[
									{
										data: attendancesSeriesByPeriod,
										name: "Qtde. Atendimentos",
									},
								]}
								heigth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Faixa etária
						</Card.Header>
						<Card.Body className="p-0">
							<PieChart
								categories={ageRangesCategories}
								series={ageRangesSeries}
								height={300}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Gêneros
						</Card.Header>
						<Card.Body className="p-0">
							<PieChart
								categories={genderCategories}
								series={genderSeries}
								height={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por CID
						</Card.Header>

						<Card.Body>
							<BarChart
								categories={attendancesByCidCategories}
								series={[
									{
										data: attendancesByCidSeries,
										name: "Total",
									},
								]}
								categoryTextWidth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por CIAP
						</Card.Header>

						<Card.Body>
							<BarChart
								categories={attendancesByCiapCategories}
								series={[
									{
										data: attendancesByCiapSeries,
										name: "Total",
									},
								]}
								categoryTextWidth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
