import { Route, Routes, Navigate } from "react-router-dom";

import { MasterLayout } from "@/../_metronic/layout/MasterLayout";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { HealthTeamContextProvider } from "@/contexts/HealthTeam";
import { UnitsContextProvider } from "@/contexts/UnitsContext";
import { ProfessionalsContextProvider } from "@/contexts/ProfessionalsContext";
import { PermissionEnum } from "@/utils/enums/PermissionEnum";

import { PrevineBrasilDashboard } from "@/pages/PrevineBrasilDashboard";
import { RegistrationManagement } from "@/pages/RegistrationManagement";
import { ImportData } from "@/pages/ImportData";
import { UsersManagement } from "@/pages/UsersManagement";
import { CitiesManagement } from "@/pages/CitiesManagement";
import { HealthTeamReport } from "@/pages/HealthTeamReport";
import { AccessProfiles } from "@/pages/AccessProfiles";
import { Restrict } from "@/components/Restrict";
import { Home } from "@/pages/Home";
import { InstitutionalPlataform } from "@/pages/InstitutionalPlatform";
import { Personal } from "@/pages/Personal";
import { DatabaseManagement } from "@/pages/DatabaseManagement";
import { Player } from "@/pages/InstitutionalPlatform/TrackList/Player";
import { TrackListManagement } from "@/pages/TrackListManagement";
import { TrackListDetails } from "@/pages/TrackListManagement/TrackListDetails";
import { FormTrackFile } from "@/pages/TrackListManagement/FormTrackFile";
import { FormTrackList } from "@/pages/TrackListManagement/FormTrackList";
import { AttendanceReport } from "@/pages/AttendanceReport";
import { AttendanceDashboard } from "@/pages/AttendanceDashboard";
import { EpidemicDashboard } from "@/pages/EpidemicDashboard";
import { ReleaseNotesManagement } from "@/pages/ReleaseNotesManagement";
import { IndicatorsPanel } from "@/components/IndicatorsPanel";
import { ReterritorializationAcsDashboard } from "@/pages/ReterritorializationAcsDashboard";
import { DashboardFederalUnit } from "@/pages/DashboardFederalUnit";
import { TripleViralManagement } from "@/pages/TripleViralManagement";
import { TripleViralReport } from "@/pages/TripleViralReport";
import { ChronicCommunicableDiseasesFederalUnitDashboard } from "@/pages/ChronicCommunicableDiseasesFederalUnitDashboard";
import { MonitoringOfDiseases } from "@/pages/MonitoringOfDiseases";
import { PregnancyManagement } from "@/pages/PregnancyManagement";
import { ActiveVaccinationSearch } from "@/pages/ActiveVaccinationSearch";
import { CityAdminReport } from "@/pages/CityAdminReport";
import { PregnancyDashboard } from "@/pages/FederalUnit/PregnancyDashboard";
import { ProfessionalAttendanceDashboard } from "@/pages/ProfessionalAttendanceDashboard";
import { StorageManagement } from "@/pages/StorageManagement";

const PrivateRoutes = () => {
	const { isLoading, user, uf, ibgeCode } = useCurrentAccount();
	if (isLoading) {
		return (
			<Routes>
				<Route path="*" element={<></>} />
			</Routes>
		);
	} else if (!user?.identifier || user?.isLocked) {
		return (
			<Routes>
				<Route path="*" element={<Navigate to="/error/401" />}></Route>
			</Routes>
		);
	}

	return (
		<UnitsContextProvider>
			<HealthTeamContextProvider>
				<ProfessionalsContextProvider>
					<Routes>
						<Route path="/selecao-de-unidade" element={<Navigate to="/" />} />
						<Route element={<MasterLayout />}>
							<Route path="/auth/*" element={<Navigate to="/selecao-de-unidade" />} />

							{/* Default Access Routes */}

							<Route path="/home" element={<Home />} />
							<Route path="/Perfil" element={<Personal />} />

							{/* Federal Unit Access Routes */}

							{uf && !ibgeCode && (
								<>
									<Route
										path="/dashboard-estadual/geral"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_STATE_REPORTS]}
												redirectIfNotAuthorized
											>
												<DashboardFederalUnit />
											</Restrict>
										}
									/>
									<Route
										path="/dashboard-estadual/triplice-viral"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_STATE_REPORTS]}
												redirectIfNotAuthorized
											>
												<TripleViralReport />
											</Restrict>
										}
									/>
									<Route
										path="/dashboard-estadual/vigilancia-em-saude"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_STATE_REPORTS]}
												redirectIfNotAuthorized
											>
												<ChronicCommunicableDiseasesFederalUnitDashboard />
											</Restrict>
										}
									/>
									<Route
										path="/dashboard-estadual/gestantes"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_STATE_REPORTS]}
												redirectIfNotAuthorized
											>
												<PregnancyDashboard />
											</Restrict>
										}
									/>
								</>
							)}

							{/* City Access Routes */}

							{uf && ibgeCode && (
								<>
									<Route
										path="/relatorios/atendimentos-individuais/dashboard"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<AttendanceDashboard />
											</Restrict>
										}
									/>
									<Route
										path="/relatorios/atendimentos-individuais/dashboard-profissional"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<ProfessionalAttendanceDashboard />
											</Restrict>
										}
									/>
									<Route
										path="/relatorios/atendimentos-individuais/atendimentos"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<AttendanceReport />
											</Restrict>
										}
									/>
									<Route
										path="/relatorios/vigilancia-em-saude"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<MonitoringOfDiseases />
											</Restrict>
										}
									/>
									<Route
										path="/relatorios/gestantes"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<PregnancyManagement />
											</Restrict>
										}
									/>
									<Route
										path="/relatorios/triplice-viral"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<TripleViralManagement />
											</Restrict>
										}
									/>
									<Route
										path="/relatorios/busca-ativa-vacinal"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<ActiveVaccinationSearch />
											</Restrict>
										}
									/>
									<Route
										path="/relatorios/epidemiologicos"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<EpidemicDashboard />
											</Restrict>
										}
									/>

									<Route
										path="/componente-vinculo/dashboard"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<ReterritorializationAcsDashboard />
											</Restrict>
										}
									/>
									<Route
										path="/componente-vinculo/cadastros"
										element={
											<Restrict
												permissions={[
													PermissionEnum.VIEW_CITIZEN_MANAGEMENT,
												]}
												redirectIfNotAuthorized
											>
												<RegistrationManagement />
											</Restrict>
										}
									/>
									<Route
										path="/componente-qualidade/dashboard"
										element={
											!user.isAdmin || ibgeCode ? (
												<Restrict
													permissions={[PermissionEnum.VIEW_INDICATORS]}
													redirectIfNotAuthorized
												>
													<PrevineBrasilDashboard />
												</Restrict>
											) : null
										}
									/>
									<Route
										path="/componente-qualidade/indicador/:indicator/detalhes"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_INDICATORS]}
												redirectIfNotAuthorized
											>
												<IndicatorsPanel />
											</Restrict>
										}
									/>
									<Route
										path="/componente-qualidade/desempenho-de-equipes"
										element={
											<Restrict
												permissions={[PermissionEnum.VIEW_REPORTS]}
												redirectIfNotAuthorized
											>
												<HealthTeamReport />
											</Restrict>
										}
									/>
									<Route
										path="/componente-qualidade/importacao-dados-sisab"
										element={
											<Restrict
												permissions={[PermissionEnum.CONFIG_DATA_IMPORT]}
												redirectIfNotAuthorized
											>
												<ImportData />
											</Restrict>
										}
									/>
								</>
							)}

							<Route
								path="/gestao-de-profissionais"
								element={
									<Restrict
										permissions={[
											PermissionEnum.VIEW_PROFESSIONAL_MANAGEMENT,
											PermissionEnum.STATE_PROFESSIONAL_MANAGEMENT,
										]}
										redirectIfNotAuthorized
									>
										<UsersManagement />
									</Restrict>
								}
							/>
							<Route
								path="/relatorios/admin/cidade"
								element={
									<Restrict redirectIfNotAuthorized>
										<CityAdminReport />
									</Restrict>
								}
							/>
							<Route
								path="/ambiente-de-apoio/acesso-a-plataforma"
								element={<InstitutionalPlataform />}
							/>
							<Route
								path="/ambiente-de-apoio/acesso-a-plataforma/tracks/:trackListId/video/:sequence"
								element={<Player />}
							/>

							{/* Admin Access Routes */}

							<Route
								path="/configuracoes/cidades"
								element={
									<Restrict redirectIfNotAuthorized>
										<CitiesManagement />
									</Restrict>
								}
							/>
							<Route
								path="/configuracoes/gestao-de-perfil"
								element={
									<Restrict redirectIfNotAuthorized>
										<AccessProfiles />
									</Restrict>
								}
							/>
							<Route
								path="/configuracoes/banco-de-dados"
								element={
									<Restrict
										permissions={[PermissionEnum.CONFIG_DATA_IMPORT]}
										redirectIfNotAuthorized
									>
										<DatabaseManagement />
									</Restrict>
								}
							/>
							<Route
								path="/configuracoes/banners"
								element={
									<Restrict
										permissions={[PermissionEnum.SUPER_ADMIN]}
										redirectIfNotAuthorized
									>
										<StorageManagement />
									</Restrict>
								}
							/>
							<Route
								path="/configuracoes/track-lists"
								element={
									<Restrict
										permissions={[PermissionEnum.SUPER_ADMIN]}
										redirectIfNotAuthorized
									>
										<TrackListManagement />
									</Restrict>
								}
							/>
							<Route
								path="/configuracoes/track-lists/novo"
								element={
									<Restrict
										permissions={[PermissionEnum.SUPER_ADMIN]}
										redirectIfNotAuthorized
									>
										<FormTrackList />
									</Restrict>
								}
							/>
							<Route
								path="/configuracoes/track-lists/:trackListId"
								element={
									<Restrict
										permissions={[PermissionEnum.SUPER_ADMIN]}
										redirectIfNotAuthorized
									>
										<TrackListDetails />
									</Restrict>
								}
							/>
							<Route
								path="/configuracoes/track-lists/:trackListId/novo"
								element={
									<Restrict
										permissions={[PermissionEnum.SUPER_ADMIN]}
										redirectIfNotAuthorized
									>
										<FormTrackFile />
									</Restrict>
								}
							/>

							{/* Default Access Routes */}

							<Route path="/versoes" element={<ReleaseNotesManagement />} />

							{/* Page Not Found */}

							<Route path="*" element={<Navigate to="/error/404" />} />
						</Route>
					</Routes>
				</ProfessionalsContextProvider>
			</HealthTeamContextProvider>
		</UnitsContextProvider>
	);
};

export { PrivateRoutes };
