import { ReactNode } from "react";

import { IndicatorModalCardTheme } from "@/@types/IndicatorModalCardTheme";

type IndicatorModalCardProps = {
	title?: ReactNode;
	subtitleHeading?: ReactNode;
	subtitle?: ReactNode;
	theme: IndicatorModalCardTheme;
	containerClassName?: string;
	children?: ReactNode;
	style?: React.CSSProperties;
};

export function IndicatorModalCard({
	title,
	subtitleHeading,
	subtitle,
	theme,
	containerClassName,
	children,
	style,
}: IndicatorModalCardProps) {
	return (
		<section className={containerClassName}>
			<div
				className={`h-100 bg-light-${theme} border border-${theme} rounded p-1`}
				style={style}
			>
				<div className="d-flex justify-content-between align-items-center text-nowrap">
					<h2 className={`text-capitalize fs-7 text-${theme} fw-bolder`}>{title}</h2>
					<span className="text-gray-900 fs-8">
						{subtitleHeading}
						<span className={`text-white bg-${theme} p-1 fs-9 rounded w-10`}>
							{subtitle}
						</span>
					</span>
				</div>

				<div className="d-flex flex-column gap-1">{children}</div>
			</div>
		</section>
	);
}
