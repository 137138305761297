import { ButtonHTMLAttributes } from "react";
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import "./styles.scss";

type NextButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	text?: string;
	previous?: boolean;
	iconNone?: boolean;
};

export function NextButton({
	text = "Próximo",
	previous,
	iconNone,
	className,
	...rest
}: NextButtonProps) {
	return (
		<button type="button" className={`next-button ${className}`} {...rest}>
			{!iconNone && previous && (
				<i>
					<MdOutlineArrowBackIos />
				</i>
			)}
			<span>{text}</span>
			{!iconNone && !previous && (
				<i>
					<MdOutlineArrowForwardIos />
				</i>
			)}
		</button>
	);
}
