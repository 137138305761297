import Select, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";

import { Professional } from "@/@types/Professional";

type Props = SelectProps & {
	professionals: Professional[];
};

const animatedComponents = makeAnimated();

export const defaultProfessional = { value: "", label: "Todos os profissionais" };

export function ProfessionalsSelect({ professionals, ...rest }: Props) {
	const options = [
		defaultProfessional,
		...professionals?.map((professional) => ({
			value: professional.cns,
			label: professional.name,
		})),
	];

	return (
		<div className="d-flex flex-column">
			<label htmlFor={rest.id}>Profissionais:</label>
			<Select
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhum profissional encontrado"}
				styles={{
					menu: (base) => ({ ...base, zIndex: 90 }),
					control: (base) => ({
						...base,
						minHeight: "30px",
						padding: "0px",
					}),
					valueContainer: (base) => ({
						...base,
						padding: "0px 4px",
					}),
					indicatorsContainer: (base) => ({
						...base,
						height: "30px",
					}),
				}}
				{...rest}
			/>
		</div>
	);
}
