import { FaInfoCircle, FaLandmark, FaMapMarkedAlt, FaMapMarkerAlt, FaUsers } from "react-icons/fa";
import { MdFamilyRestroom } from "react-icons/md";
import { BsFillHouseCheckFill } from "react-icons/bs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { CardInformative } from "@/pages/ReterritorializationAcsDashboard/CardInformative";

type Props = {
	total: number;
	totalAcs: number;
	totalRegistrationMc: number;
	totalRegistraionFa: number;
	totalFamily: number;
	totalResidences: number;
	totalOtherBuildings: number;
};

export function GlobalInformativeVisits({
	total,
	totalAcs,
	totalFamily,
	totalOtherBuildings,
	totalRegistraionFa,
	totalRegistrationMc,
	totalResidences,
}: Props) {
	return (
		<Row className="mx-3 bg-white gap-1 mt-2 justify-content-evenly rounded">
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={1}
				className="border border-muted card-information-acs bg-white rounded p-2 my-2"
			>
				<CardInformative
					title="Total Visitas"
					classNameValue="text-muted"
					classNameTitle="text-muted"
					value={total ? total?.toLocaleString() : 0}
					icon={<FaUsers className="fs-2 text-muted" />}
					metaData={
						<CardOverlayTrigger
							header="Total de visitas"
							message="Quantidade de visitas individuais realizadas"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs bg-info rounded p-2 my-2"
			>
				<CardInformative
					title="Nº de ACS"
					classNameValue="text-white"
					classNameTitle="text-white"
					value={totalAcs}
					icon={<FaUsers className="fs-2 text-white" />}
					metaData={
						<CardOverlayTrigger
							header="Nº de ACS"
							message="Quantidade de ACS,s ativos que realizaram visitas no período selecionado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs rounded p-2 my-2"
				style={{ backgroundColor: "#F1416C" }}
			>
				<CardInformative
					title="Visitas MC"
					value={totalRegistrationMc}
					icon={<FaMapMarkedAlt className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Visitas MC"
							message="Quantidade de visitas realizadas pelos ACS,s ativos  dentro das microáreas(MC) no período selecionado e de acordo com o filtro utilizado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs rounded p-2 my-2"
				style={{ backgroundColor: "#FFD52F" }}
			>
				<CardInformative
					title="Visitas FA"
					value={totalRegistraionFa}
					icon={<FaMapMarkerAlt className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Visitas FA"
							message="Quantidade de visitas realizadas pelos ACS,s ativos fora de área (FA) no período selecionado e de acordo com o filtro utilizado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs rounded p-2 my-2"
				classNameValue="text-white"
				classNameTitle="text-white"
				style={{ backgroundColor: "#0C6FD1" }}
			>
				<CardInformative
					title="Nº de Familias"
					value={totalFamily}
					icon={<MdFamilyRestroom className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Nº de familias"
							message="Quantidade de famílias visitadas no período selecionado e de acordo com o filtro selecionado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs rounded p-2 my-2"
				style={{ backgroundColor: "#04E69A" }}
			>
				<CardInformative
					title="Nº de Domicílios"
					value={totalResidences}
					icon={<BsFillHouseCheckFill className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Nº de Domicílios"
							message="Quantidade de domicílios visitados no período selecionado e de acordo com o filtro utilizado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs bg-muted rounded p-2 my-2"
			>
				<CardInformative
					title="Outros imóveis"
					value={totalOtherBuildings}
					icon={<FaLandmark className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Outros Imóveis"
							message="Quantidade de outros tipos de imóveis visitados no período selecionado e de acordo com o filtro utilizado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
		</Row>
	);
}
