import Skeleton from "react-loading-skeleton";
import { TbPlayerPlayFilled } from "react-icons/tb";
import { GenericCarousel } from "../GenericCarousel";

export type CarouselImageProps = {
	url: string;
	title?: string;
	handleClick?: () => void;
};

export type CarouselProps = {
	isLoading: boolean;
	carouselImagesProps: CarouselImageProps[];
	carouselImageFixedSize?: number;
};

export function CarouselImages({
	isLoading,
	carouselImagesProps,
	carouselImageFixedSize,
}: CarouselProps) {
	return (
		<div className="py-2">
			<GenericCarousel
				carouselItemFixedSize={carouselImageFixedSize}
				carouselItemsProps={
					!isLoading && carouselImagesProps
						? carouselImagesProps.map(
								(carouselImageProps: CarouselImageProps, index) => (
									<div className="title-item m-0">
										<img
											key={`item-carousel-image-${index}`}
											className={`h-100 w-100 ${
												carouselImageProps.handleClick
													? "cursor-pointer"
													: null
											}`}
											alt="thumbnail"
											src={carouselImageProps.url}
										/>
										<div className="items p-1">
											<div>
												<button
													onClick={carouselImageProps.handleClick}
													className="border boder-white rounded-circle p-2 d-flex justify-content-center bg-transparent"
												>
													<TbPlayerPlayFilled className="fs-1 text-white" />
												</button>
												<p className="font-roboto fs-7 text-white mb-0">
													{carouselImageProps.title}
												</p>
											</div>
										</div>
									</div>
								)
						  )
						: Array(6)
								.fill(null)
								.map((_, index) => (
									<div>
										<Skeleton
											key={`skeleton-item-carousel-${index}`}
											className="rounded"
											width={180}
											height={200}
										/>
									</div>
								))
				}
			/>
		</div>
	);
}
