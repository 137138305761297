import Select, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";

import { Unit } from "@/contexts/UnitsContext";
import { Option } from "@/@types/Option";

type Props = SelectProps & {
	units: Unit[];
	noDefaultOption?: boolean;
};

const animatedComponents = makeAnimated();

export const defaultUnit = { value: "", label: "Todas as unidades" };

export function UnitsSelect({ units, noDefaultOption, ...rest }: Props) {
	let options: Option<string>[] = [
		...units?.map((unit) => ({ value: unit.cnes, label: unit.name || "Todas as unidades" })),
	];
	if (!noDefaultOption) {
		options = [defaultUnit, ...options];
	}
	return (
		<div className="d-flex flex-column">
			<label title="Lista de Unidades Disponíveis" htmlFor={rest.id}>
				Unidades:
			</label>
			<Select
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhuma unidade encontrada"}
				styles={{
					menu: (base) => ({ ...base, zIndex: 90 }),
					control: (base) => ({
						...base,
						minHeight: "30px",
						padding: "0px",
					}),
					valueContainer: (base) => ({
						...base,
						padding: "0px 4px",
					}),
					indicatorsContainer: (base) => ({
						...base,
						height: "30px",
					}),
				}}
				{...rest}
			/>
		</div>
	);
}
