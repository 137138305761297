import { useState } from "react";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
	PregnancyByCityAndAgeResume,
	PregnancyByCityAndAgeTable,
} from "../PregnancyByCityAndAgeTable";
import { PregnancyByCityAndAgeTableFilters } from "../PregnancyByCityAndAgeTableFilters";

type Props = ModalProps & {
	pregnancyByCityAndAgeResumes: PregnancyByCityAndAgeResume[];
};

export function PregnancyByCityAndAgeModal({ pregnancyByCityAndAgeResumes, ...rest }: Props) {
	const [cityName, setCityName] = useState("");
	const pregnancyByCityAndAgeResumesFiltered = pregnancyByCityAndAgeResumes.filter((resume) =>
		resume.city.locale.toUpperCase().includes(cityName.toUpperCase())
	);
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">
						GESTANTES POR CIDADE E FAIXA ETÁRIA
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="mb-4">
					<Col>
						<PregnancyByCityAndAgeTableFilters
							cityName={cityName}
							setCityName={setCityName}
						/>
					</Col>
				</Row>
				<PregnancyByCityAndAgeTable
					pregnancyByCityAndAgeResumes={pregnancyByCityAndAgeResumesFiltered}
				/>
			</Modal.Body>
		</Modal>
	);
}
