import axios from "axios";

import * as auth from "@/modules/auth/redux/AuthRedux";
import { LOGIN_URL, refreshToken } from "@/services/auth/accountService";

type Token = {
	accessToken?: string;
	refreshToken?: string;
};

export function getApiInstance() {
	const api = axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
	});
	const token: Token = JSON.parse(localStorage.getItem("persist:v100-demo1-auth") || "{}");
	const userAccessToken = (token.accessToken || "").replaceAll('"', "");
	const userRefreshToken = (token.refreshToken || "").replaceAll('"', "");

	if (userAccessToken) {
		api.defaults.headers["Authorization"] = `Bearer ${userAccessToken}`;
	}

	api.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalConfig = error.config;

			if (
				originalConfig?.url !== LOGIN_URL &&
				error.response &&
				error.response.status === 401 &&
				!originalConfig._retry
			) {
				originalConfig._retry = true;
				if (userRefreshToken && userAccessToken) {
					try {
						const token = await refreshToken(userAccessToken, userRefreshToken);
						const newToken = auth.actions.login(
							token?.token || "",
							token?.refreshToken || ""
						);

						originalConfig.headers[
							"Authorization"
						] = `Bearer ${newToken.payload.accessToken}`;

						return api(originalConfig);
					} catch (err) {}
				}
			}
			auth.actions.logout();
			return Promise.reject(error);
		}
	);

	return api;
}
