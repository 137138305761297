import { useNavigate } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { NextButton } from "../NextButton";

export function ConfirmationNotification() {
	const navigate = useNavigate();
	return (
		<section className="text-center">
			<Row className="mb-20">
				<Col>
					<img src="/media/icons/duotune/general/check.svg" alt="email" width={100} />
					<h1 className="fw-bolder mt-8">Conta confirmada com sucesso</h1>
					<p className="mt-5 fs-7">
						Entre com suas credenciais para ter acesso as funcionalidades do sistema.
					</p>
					<NextButton
						text={"Voltar"}
						form="registration-form"
						previous
						className="bg-muted w-100 mt-8"
						onClick={() => navigate("/auth")}
					/>
				</Col>
			</Row>
		</section>
	);
}
