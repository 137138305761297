import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import { removeDuplicates } from "@/utils/genericals";

export type PopulationByGenderAndAge = {
	ageGroup: string;
	men: number;
	women: number;
};

type Props = {
	populationByGenderAndAges: PopulationByGenderAndAge[];
};

export function PopulationByGenderAndAgeGroupBarChart({ populationByGenderAndAges }: Props) {
	const categories = removeDuplicates<string[]>(
		populationByGenderAndAges.map((population) => population.ageGroup)
	);
	const menSerie = categories.map(
		(category) =>
			-(
				populationByGenderAndAges.find((population) => population.ageGroup === category)
					?.men || 0
			)
	);
	const womenSerie = categories.map(
		(category) =>
			populationByGenderAndAges.find((population) => population.ageGroup === category)
				?.women || 0
	);
	const maxValueMenSerie = [...menSerie].sort((a, b) => b - a).at(0) || 0;
	const maxValueWomenSerie = [...womenSerie].sort((a, b) => b - a).at(0) || 0;
	const maxValue = maxValueMenSerie > maxValueWomenSerie ? maxValueMenSerie : maxValueWomenSerie;

	const options: ApexOptions = {
		series: [
			{
				name: "Homens",
				data: menSerie,
			},
			{
				name: "Mulheres",
				data: womenSerie,
			},
		],
		chart: {
			type: "bar",
			stacked: true,
		},
		colors: ["#008FFB", "#FF4560"],
		plotOptions: {
			bar: {
				borderRadius: 5,
				borderRadiusApplication: "end", // 'around', 'end'
				borderRadiusWhenStacked: "all", // 'all', 'last'
				horizontal: true,
				barHeight: "70%",
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: 1,
			colors: ["#fff"],
		},

		grid: {
			xaxis: {
				lines: {
					show: false,
				},
			},
		},
		yaxis: {
			stepSize: 1,
			axisBorder: {
				offsetY: 30,
			},
		},
		tooltip: {
			shared: false,
			y: {
				formatter: function (val) {
					return Math.abs(val).toString();
				},
			},
		},
		xaxis: {
			categories: categories,
			labels: {
				formatter: function (val: string) {
					return Math.abs(Math.round(Number(val))).toLocaleString();
				},
			},
			min: -maxValue,
			max: maxValue,
		},
	};

	return (
		<ReactApexChart
			options={options}
			type="bar"
			series={options.series}
			width={"100%"}
			height={"100%"}
		/>
	);
}
