import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { SearchInput } from "@/components/SearchInput";

type Props = {
	specialtyDescription: string;
	setSpecialtyDescription: (specialtyDescription: string) => void;
};

export function AttendanceReferralRateBySpecialtyTableFilters({
	specialtyDescription,
	setSpecialtyDescription,
}: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
			}}
		>
			<Row>
				<Col sm={12} md={9} lg={6}>
					<label className="fw-bolder">Pesquisar</label>
					<SearchInput
						placeholder="Código ou descrição"
						value={specialtyDescription}
						setValue={setSpecialtyDescription}
					/>
				</Col>
			</Row>
		</Form>
	);
}
