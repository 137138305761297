import { useState } from "react";

import dayjs from "dayjs";

import { FaEllipsisV } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { BasePatient } from "@/@types/BasePatient";
import { Paginated } from "@/@types/paginated";

import { TableSkeletons } from "@/components/TableSkeletons";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { ModalIndicator4 } from "../ModalIndicator4";

import "./styles.scss";

type Props = {
	patient: Paginated<BasePatient>;
	isLoading: boolean;
};

type Column = {
	title: string;
	visible: boolean;
};

type ColumnsTable = {
	name: Column;
	birthdate: Column;
	identifier: Column;
	healthTeam: Column;
	mc: Column;
	lastProcedure: Column;
	nextProcedure: Column;
};

export function TableIndicator4({ isLoading, patient }: Props) {
	const [patientSelected, setPatientSelected] = useState<BasePatient>({} as BasePatient);
	const [showModalIndicator4, setShowModalIndicator4] = useState(false);
	const handleShowModalIndicator4 = () => {
		setShowModalIndicator4(true);
	};
	const handleCloseModalIndicator4 = () => {
		setShowModalIndicator4(false);
	};

	const [columns, setColumns] = useState<ColumnsTable>({
		name: { title: "Nome", visible: true },
		birthdate: { title: "DN", visible: true },
		identifier: { title: "CPF/CNS", visible: false },
		healthTeam: { title: "Equipe", visible: false },
		mc: { title: "MC", visible: false },
		lastProcedure: { title: "Última coleta", visible: true },
		nextProcedure: { title: "Próxima coleta", visible: true },
	});

	const handleColumnToggle = (column: keyof ColumnsTable) => {
		setColumns((prevColumns) => ({
			...prevColumns,
			[column]: {
				...prevColumns[column],
				visible: !prevColumns[column].visible,
			},
		}));
	};

	return (
		<div>
			<ModalIndicator4
				show={showModalIndicator4}
				onHide={handleCloseModalIndicator4}
				patientSelected={patientSelected}
			/>
			<Table responsive className="table-striped-custom table-rounded border gs-3">
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						{columns.name.visible && <th>CIDADÃO</th>}
						{columns.birthdate.visible && <th>DN</th>}
						{columns.identifier.visible && <th>CPF/CNS</th>}
						{columns.healthTeam.visible && <th>EQUIPE</th>}
						{columns.mc.visible && <th>MC</th>}
						{columns.lastProcedure.visible && (
							<th className="text-nowrap">ÚLTIMA COLETA</th>
						)}
						{columns.nextProcedure.visible && (
							<th className="text-nowrap">PRÓXIMA COLETA</th>
						)}
						<th className="d-flex align-items-baseline justify-content-between text-nowrap pb-0 no-print no-excel">
							<label>AÇÕES</label>
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={
									<Popover>
										<Popover.Body>
											<Form className="d-flex flex-column gap-2">
												{Object.keys(columns).map((key, index) => (
													<Form.Check
														key={`column-${index}`}
														type="checkbox"
														label={
															columns[key as keyof ColumnsTable].title
														}
														checked={
															columns[key as keyof ColumnsTable]
																.visible
														}
														onChange={() =>
															handleColumnToggle(
																key as keyof ColumnsTable
															)
														}
													/>
												))}
											</Form>
										</Popover.Body>
									</Popover>
								}
								rootClose
							>
								<Button
									title="Exibição de colunas da tabela"
									variant="white"
									className="px-2"
									disabled={isLoading}
								>
									<FaEllipsisV />
								</Button>
							</OverlayTrigger>
						</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && patient?.data ? (
						patient?.data?.map((patient, index) => (
							<tr key={`patient-pregnant-${index}`}>
								{columns.name.visible && (
									<td title="nome completo" className="text-nowrap">
										{patient.fullName || "N/A"}
									</td>
								)}
								{columns.birthdate.visible && (
									<td
										title="data de nascimento"
										className="text-nowrap text-center"
									>
										{patient.birthdate
											? dayjs(patient.birthdate).format("DD/MM/YYYY")
											: "N/A"}
									</td>
								)}
								{columns.identifier.visible && (
									<td title="cpf/nis" className="text-nowrap text-center">
										{identifierOrNationalHealthCard(patient) || "N/A"}
									</td>
								)}
								{columns.healthTeam.visible && (
									<td title="equipe" className="text-nowrap text-center">
										{patient.healthTeam?.name || "N/A"}
									</td>
								)}
								{columns.mc.visible && (
									<td className="text-nowrap text-center">
										{patient.area || "N/A"}
									</td>
								)}
								{columns.lastProcedure.visible && (
									<td title="ultima coleta" className="text-nowrap text-center">
										{patient.attendanceProcedures?.length !== 0
											? dayjs(
													patient?.attendanceProcedures?.[0]?.date
											  ).format("DD/MM/YYYY")
											: "N/A"}
									</td>
								)}
								{columns.nextProcedure.visible && (
									<td title="próxima coleta" className="text-nowrap text-center">
										{patient.attendanceProcedures?.length !== 0
											? dayjs(patient?.attendanceProcedures?.[0]?.date)
													.add(36, "month")
													.format("DD/MM/YYYY")
											: "N/A"}
									</td>
								)}
								<td className="text-center no-print">
									<ViewCitizenButton
										onClick={() => {
											setPatientSelected(patient);
											handleShowModalIndicator4();
										}}
									/>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons
							numberOfCells={
								Object.entries(columns).filter((a) => a[1]?.visible === true)
									.length + 1
							}
							height={20}
							numberOfRows={10}
						/>
					)}
				</tbody>
			</Table>
		</div>
	);
}
