import { useState } from "react";

import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import { FaFileMedical } from "react-icons/fa";

import {
	ChronicNotCommunicableDiseases,
	ResumeDiseasesChronicNotTransmissibleOfCities,
} from "./ChronicNotCommunicableDiseases";
import {
	ChronicCommunicableDiseases,
	ResumeDiseasesChronicTransmissibleOfCities,
} from "./ChronicCommunicableDiseases";

type Props = {
	resumeDiseasesChronicTransmissibleOfCities: ResumeDiseasesChronicTransmissibleOfCities[];
	resumeDiseasesChronicNotTransmissibleOfCities: ResumeDiseasesChronicNotTransmissibleOfCities[];
};

export function Diseases({
	resumeDiseasesChronicNotTransmissibleOfCities,
	resumeDiseasesChronicTransmissibleOfCities,
}: Props) {
	const [selectedOptionMenuDiseases, setSelectedOptionMenuDiseases] = useState(1);

	return (
		<Row>
			<Card className="p-0 w-100">
				<Card.Header className="p-2 w-100">
					<Row className="d-flex justify-content-between align-items-center w-100">
						<Col sm={12} md={12} lg={6} xl={6}>
							<Card.Title className="p-0 m-0 ms-2">
								<section className="d-flex align-items-center bg-white p-2 rounded ">
									<FaFileMedical
										size={30}
										className="bg-primary text-white rounded p-1 me-2"
									/>
									<h1 className="text-primary">
										Doenças crônicas: período anual
									</h1>
								</section>
							</Card.Title>
						</Col>
						<Col sm={12} md={12} lg={3} xl={3}>
							<Nav
								fill
								variant="tabs"
								defaultActiveKey="/dashboard-estadual/"
								className="d-flex align-items-center me-3"
							>
								<Nav.Item
									onClick={() => {
										setSelectedOptionMenuDiseases(1);
									}}
								>
									<Nav.Link
										eventKey="/dashboard-estadual/"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										Trasmissíveis
									</Nav.Link>
								</Nav.Item>
								<Nav.Item
									onClick={() => {
										setSelectedOptionMenuDiseases(2);
									}}
								>
									<Nav.Link
										eventKey="link-2"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										Não transmissível
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body className="p-0 bg-light">
					{selectedOptionMenuDiseases === 1 ? (
						<ChronicCommunicableDiseases
							resumeDiseasesChronicTransmissibleOfCities={
								resumeDiseasesChronicTransmissibleOfCities
							}
						/>
					) : (
						<ChronicNotCommunicableDiseases
							resumeDiseasesChronicNotTransmissibleOfCities={
								resumeDiseasesChronicNotTransmissibleOfCities
							}
						/>
					)}
				</Card.Body>
			</Card>
		</Row>
	);
}
