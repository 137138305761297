import { useEffect, useState } from "react";

import Select, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { fetchTImmunobiologicals } from "@/services/esus/TImmunobiologicalService";
import { TImmunobiological } from "@/@types/TImmunobiological";

type Props = SelectProps;

export function TImmunobiologicalSelect({ ...rest }: Props) {
	const { ibgeCode } = useCurrentAccount();
	const [immunobiologicals, setImmunobiologicals] = useState<TImmunobiological[]>([]);
	const animatedComponents = makeAnimated();

	async function handleTImmunobiologicals() {
		setImmunobiologicals(await fetchTImmunobiologicals({ ibgeCode }));
	}

	const defaultImmunobiological = { value: {} as TImmunobiological, label: "Todas as vacinas" };

	const options = [
		defaultImmunobiological,
		...immunobiologicals?.map((immunobiological) => ({
			value: immunobiological,
			label: immunobiological.name,
		})),
	];

	useEffect(() => {
		handleTImmunobiologicals();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex flex-column">
			<label htmlFor={rest.id}>Vacinas:</label>
			<Select
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhuma vacina encontrada"}
				styles={{
					menu: (base) => ({ ...base, zIndex: 90 }),
					control: (base) => ({
						...base,
						minHeight: "30px",
						padding: "0px",
					}),
					valueContainer: (base) => ({
						...base,
						padding: "0px 4px",
					}),
					indicatorsContainer: (base) => ({
						...base,
						height: "30px",
					}),
				}}
				{...rest}
			/>
		</div>
	);
}
