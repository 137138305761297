import React, { ReactNode, useLayoutEffect, useState } from "react";

import { Carousel } from "react-responsive-carousel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export type CarouselProps = {
	carouselItemsProps: ReactNode[];
	carouselItemFixedSize?: number;
	children?: React.ReactNode;
};

export function GenericCarousel({ carouselItemsProps, carouselItemFixedSize }: CarouselProps) {
	const [width, setWidth] = useState(window.innerWidth);

	const carouselItemCols = ((width: number) => {
		switch (true) {
			case width < 600:
				return 2;
			case width > 600 && width <= 1000:
				return 3;
			case width > 1000 && width <= 1400:
				return 4;
			case width > 1400 && width <= 1800:
				return 6;
			default:
				return 6;
		}
	})(width);

	useLayoutEffect(() => {
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
		});
	}, []);

	const chunkIntoSize = (arr: any[], arraySize: number) => {
		const size = Math.ceil(arr.length / arraySize);
		return Array.from({ length: size }, (v, i) =>
			arr.slice(i * arraySize, i * arraySize + arraySize)
		);
	};

	return (
		<Carousel
			className="rounded"
			autoPlay={false}
			showStatus={false}
			showThumbs={false}
			showIndicators={false}
		>
			{chunkIntoSize(carouselItemsProps, carouselItemCols).map(
				(carouselItem: React.ReactNode[], carouselIndex) => (
					<div key={`carousel-item-${carouselIndex}`}>
						<Row className="d-flex ms-5">
							{carouselItem.map(
								(
									carouselItemDetails: React.ReactNode,
									carouselItemDetailsIndex
								) => (
									<Col
										key={`carousel-item-details-${carouselItemDetailsIndex}`}
										xs={12 / (carouselItemFixedSize || carouselItemCols)}
										md={12 / (carouselItemFixedSize || carouselItemCols)}
										lg={12 / (carouselItemFixedSize || carouselItemCols)}
										xl={12 / (carouselItemFixedSize || carouselItemCols)}
										xxl={12 / (carouselItemFixedSize || carouselItemCols)}
										className="zoom"
									>
										{carouselItemDetails}
									</Col>
								)
							)}
						</Row>
					</div>
				)
			)}
		</Carousel>
	);
}
