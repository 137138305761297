import { DonutChart } from "@/components/Charts/DonutChart";

type Props = {
	vaccinated: number;
	notVaccinated: number;
};

export function TripleViralResumeChart({ vaccinated, notVaccinated }: Props) {
	function getChartColor(percentage: number) {
		if (percentage >= 95) return "#0F9BEB";
		if (percentage >= 20) return "#FFC700";
		return "#F1416C";
	}

	return (
		<DonutChart
			width={400}
			colors={[
				getChartColor((vaccinated * 100) / (vaccinated + notVaccinated || 1)),
				"#aeaeae",
			]}
			series={[vaccinated, notVaccinated]}
			labels={["Vacinados", "Não Vacinados"]}
		/>
	);
}
