import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { federalUnits } from "@/utils/federalUnits";
import { capitalize, onlyNumbers } from "@/utils/genericals";
import { AccountCreationEnum } from "@/utils/enums/AccountCreationEnum";
import { registerUser } from "@/services/app/userService";
import { City } from "@/@types/statesAndCities";
import {
	PasswordRegistration,
	passwordRegistrationSchema,
	UserPersonalInfo,
	userPersonalInfoRegistrationSchema,
	UserRegistration,
} from "@/utils/Schemas/registrationSchema";
import { register, validateAccount } from "@/services/auth/accountService";

import { UserPasswordForm } from "./UserPasswordForm";
import { UserPersonalInfoForm } from "./UserPersonalInfoForm";
import { ConfirmationNotification } from "./ConfirmationNotification";
import { ValidationCodeForm } from "./ValidationCodeForm";

import "./styles.scss";

export function Registration() {
	const navigate = useNavigate();
	const [step, setStep] = useState(AccountCreationEnum.PERSONAL_DATA);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
	const [userPersonalInfo, setUserPersonalInfo] = useState<UserPersonalInfo>(
		{} as UserPersonalInfo
	);
	const [userPassword, setUserPassword] = useState<PasswordRegistration>(
		{} as PasswordRegistration
	);
	const [validationCode, setValidationCode] = useState("");

	async function handleGoToNextStep() {
		if (step === AccountCreationEnum.PERSONAL_DATA) {
			setStep(AccountCreationEnum.CONFIRMATION_PASSWORD);
		}
		if (step === AccountCreationEnum.CONFIRMATION_PASSWORD) {
			const newUser = { ...userPersonalInfo, ...userPassword };
			const isRegistered = await fetchRegisterUser(newUser);

			if (isRegistered) {
				setStep(AccountCreationEnum.VALIDATION_CODE);
			} else {
				toast.error("Falha ao criar conta. Tente novamente.");
				setStep(AccountCreationEnum.CONFIRMATION_PASSWORD);
			}
			return;
		}
		if (step === AccountCreationEnum.VALIDATION_CODE) {
			const isValidated = await validateAccount(userPersonalInfo.identifier, validationCode);
			if (isValidated) {
				setStep(AccountCreationEnum.CREATED_ACCOUNT);
			} else {
				toast.error(
					"Código de verificação inválido, tente novamente ou solicite a recuperação de sua conta"
				);
				setStep(AccountCreationEnum.VALIDATION_CODE);
			}
		}
		if (step === AccountCreationEnum.CREATED_ACCOUNT) {
			navigate("/auth");
		}
	}

	async function validateStepForm() {
		if (step === AccountCreationEnum.PERSONAL_DATA) {
			const isValid = await userPersonalInfoRegistrationSchema.isValid(userPersonalInfo);
			setIsNextButtonDisabled(!isValid);
			return;
		}
		if (step === AccountCreationEnum.CONFIRMATION_PASSWORD) {
			const isValid = await passwordRegistrationSchema.isValid(userPassword);
			setIsNextButtonDisabled(!isValid);
			return;
		}
		if (step === AccountCreationEnum.VALIDATION_CODE) {
			const isValid = onlyNumbers(validationCode).length === 6;
			setIsNextButtonDisabled(!isValid);
			return;
		}
		setIsNextButtonDisabled(false);
	}

	async function fetchRegisterUser(user: UserRegistration) {
		const city = JSON.parse(user.city) as City;
		const isUserRegistered = await registerUser({
			fullName: capitalize(user.name),
			email: user.email,
			identifier: onlyNumbers(user.identifier),
			phone: onlyNumbers(user.phone),
			occupation: user.occupation,
			ibgeCode: String(city.id),
			locale: city.nome,
			federalUnitId: federalUnits.find(
				(federalUnit) => federalUnit.abbreviation === user.state
			)?.id,
		});

		if (!isUserRegistered) {
			toast.error("Não foi possível realizar o cadastro, tente novamente mais tarde.");
			return false;
		}

		const registeredUser = await register(user);
		if (!registeredUser) {
			toast.error("Não foi possível realizar o cadastro, tente novamente mais tarde.");
			return false;
		}
		return true;
	}

	useEffect(() => {
		validateStepForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step, userPersonalInfo, validationCode, userPassword]);

	return (
		<section>
			<div className="w-100">
				{step === AccountCreationEnum.PERSONAL_DATA && (
					<UserPersonalInfoForm
						userPersonalInfo={userPersonalInfo}
						handleSubmit={setUserPersonalInfo}
						handleGoToNextStep={handleGoToNextStep}
						isNextButtonDisabled={isNextButtonDisabled}
					/>
				)}
				{step === AccountCreationEnum.CONFIRMATION_PASSWORD && (
					<UserPasswordForm
						userPassword={userPassword}
						handleSubmit={setUserPassword}
						handleGoToNextStep={handleGoToNextStep}
						isNextButtonDisabled={isNextButtonDisabled}
						setStep={setStep}
					/>
				)}
				{step === AccountCreationEnum.VALIDATION_CODE && (
					<ValidationCodeForm
						setCode={setValidationCode}
						handleGoToNextStep={handleGoToNextStep}
						isNextButtonDisabled={isNextButtonDisabled}
					/>
				)}
				{step === AccountCreationEnum.CREATED_ACCOUNT && <ConfirmationNotification />}
			</div>
		</section>
	);
}
