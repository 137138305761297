/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";

import { AuthLayout } from "../components/AuthLayout";
import { Login } from "../components/Login";
import { Registration } from "../components/Registration";
import { ForgotPassword } from "../components/ForgotPassword";

import "./styles.scss";

export function AuthPage() {
	return (
		<Routes>
			<Route element={<AuthLayout isLogin={true} />}>
				<Route path="login" element={<Login />} />
				<Route index element={<Login />} />
			</Route>

			<Route element={<AuthLayout />}>
				<Route path="criar-conta" element={<Registration />} />
			</Route>
			<Route element={<AuthLayout isLogin={false} />}>
				<Route path="esqueci-a-senha" element={<ForgotPassword />} />
			</Route>
			<Route element={<AuthLayout isLogin={false} />}>
				<Route path="esqueci-a-senha" element={<ForgotPassword />} />
			</Route>
		</Routes>
	);
}
