import dayjs from "dayjs";

import { BsSearch } from "react-icons/bs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { useHealthTeams } from "@/hooks/useHealthTeam";
import { useUnits } from "@/hooks/useUnits";
import { useProfessionals } from "@/hooks/useProfessionals";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Option } from "@/@types/Option";

import { ProfessionalsSelect } from "@/components/ProfessionalsSelect";
import { UnitsSelect } from "@/components/UnitsSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";

type Props = {
	selectedStartDate: string;
	selectedEndDate: string;
	handleSearch: () => void;
	setSelectedHealthUnit: (selectedHealtUnit: Option<string>) => void;
	setSelectedHealthTeam: (selectedHealteam: Option<string>) => void;
	setSelectedProfessional: (selectedProfessional: Option<string>) => void;
	setSelectedStartDate: (selectedStartDate: string) => void;
	setSelectedEndDate: (selectedEndDate: string) => void;
};

export function FilterAcsReport({
	selectedStartDate,
	setSelectedHealthUnit,
	setSelectedHealthTeam,
	setSelectedProfessional,
	setSelectedEndDate,
	setSelectedStartDate,
	handleSearch,
}: Props) {
	const { cnes } = useCurrentAccount();
	const { healthTeams } = useHealthTeams();
	const { units } = useUnits();
	const { professionals } = useProfessionals();

	const unitCurrentDefault = units?.find((unit) => unit.cnes === cnes);

	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleSearch();
			}}
		>
			<Row className="d-flex justify-content-between mx-3 p-2 bg-white rounded">
				<Col sm={12} md={6} lg={5} xl={4}>
					<label className="fw-bold">Período:</label>
					<div className="d-flex mb-0 gap-2">
						<InputGroup className="mt-1">
							<Form.Control
								type="date"
								placeholder="Data inicial"
								defaultValue={selectedStartDate}
								min={dayjs().subtract(1, "year").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setSelectedStartDate(event.target.value)}
							/>
						</InputGroup>
						<InputGroup className="mt-1">
							<Form.Control
								type="date"
								placeholder="data final"
								defaultValue={dayjs().format("YYYY-MM-DD")}
								min={selectedStartDate}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setSelectedEndDate(event.target.value)}
							/>
						</InputGroup>
					</div>
				</Col>
				<Col sm={12} md={6} lg={3} xl={3}>
					<HealthTeamsSelect
						id="health-teams-select"
						healthTeams={healthTeams}
						onChange={(newValue) => setSelectedHealthTeam(newValue as Option<string>)}
					/>
				</Col>
				{!cnes && (
					<Col sm={12} md={12} lg={3} xl={3}>
						<UnitsSelect
							units={units}
							onChange={(event) => setSelectedHealthUnit(event as Option<string>)}
						/>
					</Col>
				)}

				<Col sm={12} md={12} lg={5} xl={3}>
					<ProfessionalsSelect
						professionals={professionals}
						onChange={(event) => setSelectedProfessional(event as Option<string>)}
					/>
				</Col>
				<Col sm={12} md={12} lg={2} xl={2} className="d-flex p-0 mt-3 align-items-end">
					<Button variant="light" type="submit" className="align-items-end w-100">
						<BsSearch className="fs-1 me-2" />
					</Button>
				</Col>
			</Row>
			{cnes && <h1 className="mx-3 p-2 fs-3 bg-white">{unitCurrentDefault?.name}</h1>}
		</Form>
	);
}
