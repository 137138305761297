import { useState } from "react";
import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FaEye, FaInfoCircle } from "react-icons/fa";

import { Period } from "@/@types/Period";
import { Procedure } from "@/@types/Procedure";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";
import { ProfessionalCategoryEnum } from "@/utils/enums/ProfessionalCategoryEnum";
import { ResumeGrouped } from "@/services/esus/attendanceService";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { AttendanceProcedureHistoryChart } from "./AttendanceProcedureHistoryChart";
import { BarChart } from "@/components/Charts/BarChart";
import { AttendanceProcedureRateByProcedureModal } from "./AttendanceProcedureRateByProcedureModal";

type Props = {
	proceduresAggregatedByPeriodAndProfessionalCategory: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	proceduresAggregatedByProcedureAndProfessionalCategory: ResumeGrouped<
		Procedure,
		ResumeGrouped<string, object>
	>[];
};

export function AttendanceProcedureDetails({
	proceduresAggregatedByPeriodAndProfessionalCategory,
	proceduresAggregatedByProcedureAndProfessionalCategory,
}: Props) {
	const [
		showAttendanceProcedureRateByProcedureModal,
		setShowAttendanceProcedureRateByProcedureModal,
	] = useState(false);
	const proceduresAggregatedByPeriodAndProfessionalCategorySorted =
		proceduresAggregatedByPeriodAndProfessionalCategory.sort((aggregatedA, aggregatedB) =>
			dayjs(new Date(aggregatedB.key.year, aggregatedB.key.month, 1)).isAfter(
				dayjs(new Date(aggregatedA.key.year, aggregatedA.key.month, 1))
			)
				? -1
				: 0
		);
	const periodProceduresSeries = proceduresAggregatedByPeriodAndProfessionalCategorySorted.map(
		(aggregatedAttendances) => aggregatedAttendances.total
	);
	const periodProceduresCategories =
		proceduresAggregatedByPeriodAndProfessionalCategorySorted?.map((period) =>
			period.key.year && period.key.month
				? `${period.key.year}/${SHORT_MONTHS[period.key.month - 1]}`
				: ""
		);

	const proceduresAggregatedByProcedureAndProfessionalCategorySorted =
		proceduresAggregatedByProcedureAndProfessionalCategory
			.sort((aggregatedA, aggregatedB) => aggregatedB.total - aggregatedA.total)
			.slice(0, 10);
	const proceduresAggregatedByProcedureAndProfessionalCategoryCategories =
		proceduresAggregatedByProcedureAndProfessionalCategorySorted
			.map((aggregatedAttendances) => aggregatedAttendances.key)
			.map((dashboard) =>
				dashboard.procedureCode ? `${dashboard.procedureCode} - ${dashboard.name}` : ""
			);
	const proceduresAggregatedByProcedureAndProfessionalCategorySeries = [
		{
			data: proceduresAggregatedByProcedureAndProfessionalCategorySorted.map(
				(professionalCategories) =>
					(professionalCategories.values || [])
						.filter(
							(professionalCategory) =>
								professionalCategory.key ===
								ProfessionalCategoryEnum.MEDIC.valueOf()
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Médico",
		},
		{
			data: proceduresAggregatedByProcedureAndProfessionalCategorySorted.map(
				(professionalCategories) =>
					(professionalCategories.values || [])
						.filter(
							(professionalCategory) =>
								professionalCategory.key ===
								ProfessionalCategoryEnum.NURSE.valueOf()
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Enfermeiro",
		},
		{
			data: proceduresAggregatedByProcedureAndProfessionalCategorySorted.map(
				(professionalCategories) =>
					(professionalCategories.values || [])
						.filter(
							(professionalCategory) =>
								professionalCategory.key ===
								ProfessionalCategoryEnum.NURSING_TECHNICIAN.valueOf()
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Tec. Enfermagem",
		},
		{
			data: proceduresAggregatedByProcedureAndProfessionalCategorySorted.map(
				(professionalCategories) =>
					(professionalCategories.values || [])
						.filter(
							(professionalCategory) =>
								![
									ProfessionalCategoryEnum.MEDIC.valueOf(),
									ProfessionalCategoryEnum.NURSE.valueOf(),
									ProfessionalCategoryEnum.NURSING_TECHNICIAN.valueOf(),
								].includes(professionalCategory.key)
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Outros",
		},
	];

	return (
		<section>
			<Row className="p-2">
				<Col>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-between align-items-center">
							<div className="d-flex">
								<div className="me-2">Evolução histórica de procedimentos</div>
								<CardOverlayTrigger
									header={"Procedimentos"}
									message={"Total de procedimentos realizados mensalmente"}
									icon={<FaInfoCircle />}
								/>
							</div>
						</Card.Header>
						<Card.Body style={{ height: 500 }}>
							<AttendanceProcedureHistoryChart
								series={[
									{
										data: periodProceduresSeries,
										name: "Procedimentos realizados",
									},
								]}
								categories={periodProceduresCategories}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-between align-items-center">
							<div className="d-flex">
								<div className="me-2">Procedimentos mais utilizados</div>
								<CardOverlayTrigger
									header={"Procedimentos mais utilizados"}
									message={
										"Total de procedimentos realizados no período por categoria profissional"
									}
									icon={<FaInfoCircle />}
								/>
							</div>
							<Button
								className="no-print"
								variant="secondary"
								onClick={() => setShowAttendanceProcedureRateByProcedureModal(true)}
							>
								<FaEye className="me-2" />
								Detalhes
							</Button>
						</Card.Header>
						<Card.Body className="ps-0">
							<div className="overflow-y-scroll" style={{ height: 600 }}>
								<BarChart
									series={
										proceduresAggregatedByProcedureAndProfessionalCategorySeries
									}
									categories={
										proceduresAggregatedByProcedureAndProfessionalCategoryCategories
									}
									categoryTextWidth={380}
									minHeight={600}
									isStacked
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<AttendanceProcedureRateByProcedureModal
				aggregatedAttendanceProcedures={
					proceduresAggregatedByProcedureAndProfessionalCategory
				}
				show={showAttendanceProcedureRateByProcedureModal}
				onHide={() => setShowAttendanceProcedureRateByProcedureModal(false)}
			/>
		</section>
	);
}
