import { CityResumeData } from "@/@types/CityResumeData";
import { get } from "../api";

type DashboardFederalUnitParams = {
	uf: string;
	quarter: string;
};

export async function fetchDashboardCities(dashboardFederalUnitParams: DashboardFederalUnitParams) {
	const response = await get<CityResumeData[]>(
		`/Indicator/v1/DashboardFederalUnit`,
		dashboardFederalUnitParams
	);
	return response.data || [];
}
