import dayjs from "dayjs";

import { FaSyringe } from "react-icons/fa";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { removeDuplicates } from "@/utils/genericals";
import { BasePatient } from "@/@types/BasePatient";
import { Vaccine } from "@/@types/Vaccine";
import { Immunobiological } from "@/@types/Immunobiological";

import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { VaccinesByAcronym } from "../VaccinesByAcronym";

type Props = ModalProps & {
	patientSelected: BasePatient;
};

export function ModalIndicator5({ patientSelected, ...rest }: Props) {
	function filterVaccinationsByImmunobiologicalAcronym(vaccinations: Vaccine[], acronym: string) {
		return vaccinations?.filter(
			(vaccination) =>
				vaccination.vaccine.immunobiological?.acronym.toUpperCase() ===
				acronym.toUpperCase()
		);
	}

	return (
		<Modal {...rest} size="xl" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-7">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-8 text-primary">PACIENTE: </span>
							<span>{patientSelected?.fullName}</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">DN: </span>
							<span>{dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")}</span>
						</Col>
						<Col sm={4} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">IDADE: </span>
							<span>{patientSelected.age}</span>
						</Col>
					</Row>
				</section>
				<section>
					{patientSelected.vaccinations?.length !== 0 ? (
						<div className="bg-light-dark border border-primary rounded p-5">
							<div className="d-flex flex-column gap-10">
								<div className="d-flex flex-wrap gap-2 fs-8">
									<span className="fw-bold">Legenda:</span>
									<span className="badge badge-light-success">Aplicada</span>
									<span className="badge badge-light-warning">
										Registro fora do prazo
									</span>
									<span className="badge badge-light-danger">Não aplicada</span>
								</div>
								<VaccinesByAcronym
									vaccinations={filterVaccinationsByImmunobiologicalAcronym(
										patientSelected.vaccinations,
										"PENTA"
									)}
									immunobiological={{ acronym: "PENTA", name: "DTP/HB/Hib" }}
									validateDosages={[
										{ acronym: "D1", name: "1ª DOSE" },
										{ acronym: "D2", name: "2ª DOSE" },
										{ acronym: "D3", name: "3ª DOSE" },
									]}
								/>
								<VaccinesByAcronym
									vaccinations={filterVaccinationsByImmunobiologicalAcronym(
										patientSelected.vaccinations,
										"VIP"
									)}
									immunobiological={{
										acronym: "VIP",
										name: "Poliomielite inativada",
									}}
									validateDosages={[
										{ acronym: "D1", name: "1ª DOSE" },
										{ acronym: "D2", name: "2ª DOSE" },
										{ acronym: "D3", name: "3ª DOSE" },
									]}
								/>
								{removeDuplicates<Array<Immunobiological>>(
									patientSelected.vaccinations
										?.filter(
											(vaccination) =>
												!["PENTA", "VIP"].includes(
													vaccination.vaccine.immunobiological.acronym.toUpperCase()
												)
										)
										.map((vaccination) => vaccination.vaccine.immunobiological)
								).map((immunobiological, index) => (
									<VaccinesByAcronym
										key={`vaccinations-by-acronym-${index}`}
										vaccinations={filterVaccinationsByImmunobiologicalAcronym(
											patientSelected.vaccinations,
											immunobiological.acronym
										)}
										immunobiological={immunobiological}
									/>
								))}
							</div>
						</div>
					) : (
						<NoRecordsFeedback
							icon={<FaSyringe />}
							message="Nenhuma vacina foi informada para este cidadão"
						/>
					)}
				</section>
			</Modal.Body>
		</Modal>
	);
}
