import { useEffect, useState } from "react";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { FaUser } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import {
	fetchCitizensWithHansen,
	fetchCitizensWithHansenResume,
	fetchCitizensWithTuberculosis,
	fetchCitizensWithTuberculosisResume,
} from "@/services/esus/epidemicService";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { DiseasesEnum } from "@/utils/enums/DiseasesEnum";
import { useDebounce } from "@/hooks/useDebounce";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useLayout } from "@/../_metronic/layout/core";
import { exportExcel } from "@/utils/exports";
import { Option } from "@/@types/Option";
import { Citizen } from "@/@types/Citizen";
import { Problem } from "@/@types/Problem";
import { Paginated } from "@/@types/paginated";
import { GenericObject } from "@/@types/genericals";

import { DonutChart } from "@/components/Charts/DonutChart";
import { Skeleton } from "@/components/Skeleton";
import { IndicatorQuantityStatistic } from "@/components/IndicatorsPanel/IndicatorQuantityStatistic";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import {
	reasonForIdentification,
	TableCitizensWithDiseases,
	treatmentIsOver,
} from "./TableCitizensWithDiseases";
import { FiltersTableCitizensWithDiseases } from "./FiltersTableCitizensWithDiseases";
import { LoadingScreen } from "@/components/LoadingScreen";

type DiseaseResume = {
	recoveredCount: number;
	underTreatmentCount: number;
	untreatedCount: number;
};

export type DiseaseData = {
	firstIndividualRecordDate: string;
	lastIndividualRecordWithDiseaseYesDate: string;
	lastIndividualRecordWithDiseaseNoDate: string;
	citizen: Citizen;
	firstProblemDate: string;
	lastProblemDate: string;
	problems: Problem[];
	currentStatusDisease: boolean;
	status: number;
};

export function MonitoringOfDiseases() {
	const { setTitle } = useLayout();
	const { uf, ibgeCode } = useCurrentAccount();
	const [showLoadingScreen, setShowLoadingScreen] = useState(false);
	const [isLoading, setIsloading] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [searchFullName, setSearchFullName] = useState<string>("");
	const debouncedFullName = useDebounce(searchFullName, 2000);
	const [selectedTreatmentType, setSelectedTreatmentType] = useState<Option<number | null>>({
		value: null,
		label: "Todos",
	} as Option<number | null>);

	const [diseaseData, setDiseaseData] = useState<Paginated<DiseaseData>>(
		{} as Paginated<DiseaseData>
	);
	const cacheExpirationDate = getExpirationDateMidnight();
	const [diseaseResumeData, setDiseaseResumeData] = useState<DiseaseResume>({} as DiseaseResume);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState<string>(
		dayjs().subtract(12, "months").format("YYYY-MM-DD")
	);
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState<string>(
		dayjs().format("YYYY-MM-DD")
	);
	dayjs.extend(isSameOrAfter);
	const [selectedOptionDisease, setSelectedOptionDisease] = useState<Option<number>>({
		label: "Hanseníase",
		value: DiseasesEnum.HANSEN,
	} as Option<number>);

	const chronicCommunicableDiseasesOptions: Option<number>[] = [
		{ label: "Hanseniase", value: DiseasesEnum.HANSEN },
		{ label: "Tuberculose", value: DiseasesEnum.TUBERCULOSIS },
	];

	async function handleCitizensWithTuberculosisResume() {
		return fetchCitizensWithTuberculosisResume({
			uf: uf,
			ibgeCode: ibgeCode,
			startDate: selectedOptionStartDate,
			endDate: selectedOptionEndDate,
		});
	}

	async function handleCitizensWithTuberculosis(pageSize?: number) {
		return fetchCitizensWithTuberculosis({
			uf: uf,
			startDate: selectedOptionStartDate,
			endDate: selectedOptionEndDate,
			ibgeCode: ibgeCode,
			pageNumber: pageNumber,
			searchName: searchFullName,
			filterStatus: selectedTreatmentType.value,
			pageSize: pageSize,
		});
	}

	async function handleCitizensWithHansenResume() {
		return fetchCitizensWithHansenResume({
			startDate: selectedOptionStartDate,
			endDate: selectedOptionEndDate,
			ibgeCode: ibgeCode,
			uf: uf,
		});
	}

	async function handleCitizensWithHansen(pageSize?: number) {
		return fetchCitizensWithHansen({
			uf: uf,
			startDate: selectedOptionStartDate,
			endDate: selectedOptionEndDate,
			ibgeCode: ibgeCode,
			pageNumber: pageNumber,
			searchName: searchFullName,
			filterStatus: selectedTreatmentType.value,
			pageSize: pageSize,
		});
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	async function fetchResume() {
		const cacheKey = `[monitoringDiseasesResume][${uf}][${ibgeCode}][${selectedOptionStartDate}][${selectedOptionEndDate}][${selectedOptionDisease.value}]`;
		setIsloading(true);
		if (selectedOptionDisease.value === DiseasesEnum.TUBERCULOSIS) {
			setDiseaseResumeData(
				await fetchWithCache(
					cacheKey,
					cacheExpirationDate,
					handleCitizensWithTuberculosisResume
				)
			);
		} else if (selectedOptionDisease.value === DiseasesEnum.HANSEN) {
			setDiseaseResumeData(
				await fetchWithCache(cacheKey, cacheExpirationDate, handleCitizensWithHansenResume)
			);
		}
		setIsloading(false);
		if (pageNumber !== 1) {
			setPageNumber(1);
		} else {
			fetchData();
		}
	}

	async function fetchData() {
		const cacheKey = `[monitoringDiseases][${uf}][${ibgeCode}][${selectedOptionStartDate}][${selectedOptionEndDate}][${searchFullName}][${selectedOptionDisease.value}][${selectedTreatmentType.value}][${pageNumber}]`;
		setIsloading(true);
		if (selectedOptionDisease.value === DiseasesEnum.TUBERCULOSIS) {
			setDiseaseData(
				await fetchWithCache(cacheKey, cacheExpirationDate, handleCitizensWithTuberculosis)
			);
		} else if (selectedOptionDisease.value === DiseasesEnum.HANSEN) {
			setDiseaseData(
				await fetchWithCache(cacheKey, cacheExpirationDate, handleCitizensWithHansen)
			);
		}
		setIsloading(false);
	}

	async function handleExportTable() {
		setShowLoadingScreen(true);
		let response;
		if (selectedOptionDisease.value === DiseasesEnum.TUBERCULOSIS) {
			response = await handleCitizensWithTuberculosis(diseaseData?.totalRecords);
		} else if (selectedOptionDisease.value === DiseasesEnum.HANSEN) {
			response = await handleCitizensWithHansen(diseaseData?.totalRecords);
		}
		setShowLoadingScreen(false);

		if (Object.keys(response?.data || []).length) {
			const excelData: GenericObject[] = transformToExportExcel(response?.data || []);
			exportExcel(excelData, `Tabela`);
		}
	}

	function transformToExportExcel(citizensWithProblems: DiseaseData[]) {
		return citizensWithProblems.map(
			(citisenWithProblem) =>
				({
					Encerramento: treatmentIsOver(citisenWithProblem),
					Nome: citisenWithProblem.citizen?.fullName,
					"Data de Nascimento": dayjs(citisenWithProblem.citizen?.birthdate).format(
						"DD/MM/YYYY"
					),
					Motivo: reasonForIdentification(citisenWithProblem),
					Cpf: citisenWithProblem.citizen?.cpf,
					Cns: citisenWithProblem.citizen?.cns,
					"Primeira FCI": dayjs(citisenWithProblem.firstIndividualRecordDate).format(
						"DD/MM/YYYY"
					),
					"Última FCI": dayjs(
						citisenWithProblem.lastIndividualRecordWithDiseaseYesDate
					).format("DD/MM/YYYY"),
					"Total Atendimentos": citisenWithProblem.problems?.length,
					"Primeiro Atendimento": dayjs(citisenWithProblem.firstProblemDate).format(
						"DD/MM/YYYY"
					),
					"Último atendimento": dayjs(citisenWithProblem.lastProblemDate).format(
						"DD/MM/YYYY"
					),
					Equipe: citisenWithProblem.citizen?.healthTeam?.name,
					Gênero: citisenWithProblem.citizen?.gender,
				} as GenericObject)
		);
	}

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
			return;
		}
		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFullName, selectedTreatmentType.value]);

	useEffect(() => {
		if (pageNumber !== 0) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		setTitle("VIGILÂNCIA EM SAÚDE");
		fetchResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="px-2">
			<LoadingScreen loading={showLoadingScreen} />
			<section>
				<Card>
					<Card.Body>
						<Form
							onSubmit={(event) => {
								event.preventDefault();
								fetchResume();
							}}
						>
							<Row className="d-flex justify-content-between align-items-end">
								<Col sm={12} lg={4} className="d-flex flex-column gap-2">
									<Form.Label className="text-start fw-bolder">
										Período
									</Form.Label>
									<InputGroup className="d-flex gap-1">
										<Form.Control
											type="date"
											placeholder="Data inicial"
											min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
											max={dayjs().format("YYYY/MM/DD")}
											defaultValue={dayjs()
												.subtract(12, "M")
												.format("YYYY-MM-DD")}
											onChange={(event) =>
												setSelectedOptionStartDate(event.target.value)
											}
										/>
										<Form.Control
											type="date"
											placeholder="data final"
											defaultValue={dayjs().format("YYYY-MM-DD")}
											min={selectedOptionStartDate}
											max={dayjs().format("YYYY-MM-DD")}
											onChange={(event) =>
												setSelectedOptionEndDate(event.target.value)
											}
										/>
									</InputGroup>
								</Col>
								<Col sm={12} lg={4} className="d-flex flex-column gap-2">
									<Form.Label>Doenças</Form.Label>
									<InputGroup>
										<Select
											className="w-100"
											name="Diseases"
											options={chronicCommunicableDiseasesOptions}
											onChange={(event) =>
												setSelectedOptionDisease(event as Option<number>)
											}
											defaultValue={{
												label: "Hanseniase",
												value: DiseasesEnum.HANSEN,
											}}
											noOptionsMessage={() =>
												"Nenhum resultado encontrado na pesquisa"
											}
											styles={{
												menu: (base) => ({ ...base, zIndex: 90 }),
												control: (base) => ({
													...base,
													minHeight: "30px",
													padding: "0px",
												}),
												valueContainer: (base) => ({
													...base,
													padding: "0px 4px",
												}),
												indicatorsContainer: (base) => ({
													...base,
													height: "30px",
												}),
											}}
										/>
									</InputGroup>
								</Col>
								<Col sm={12} lg={2}>
									<Button type="submit" className="w-100" disabled={isLoading}>
										<BsSearch className="me-2" />
										Consultar
									</Button>
								</Col>
							</Row>
						</Form>
					</Card.Body>
				</Card>
			</section>
			<section className="mt-2">
				<Row className="w-100 d-flex justify-content-stretch">
					<Col
						md={6}
						lg={6}
						xl={6}
						className="d-flex align-items-center justify-content-center"
					>
						<Card className="w-100 h-100">
							<Card.Header>
								<Card.Title>Resumo por status de acompanhamento</Card.Title>
							</Card.Header>
							<Card.Body className="p-0">
								<DonutChart
									series={
										isLoading
											? [0, 0, 0]
											: [
													Number(diseaseResumeData?.recoveredCount || 0),
													Number(
														diseaseResumeData?.underTreatmentCount || 0
													),
													Number(diseaseResumeData?.untreatedCount || 0),
											  ]
									}
									labels={["Resolvido", "Em acompanhamento", "Não acompanhado"]}
									colors={["#009ef7", "#ffc700", "#f1416c"]}
									width={450}
								/>
							</Card.Body>
						</Card>
					</Col>

					<Col md={6} lg={6} xl={6} className="p-0">
						<Card className="w-100 h-100">
							<Card.Header>
								<Card.Title className="d-flex justify-content-between w-100">
									<div>
										<label className="fw-bolder me-2 fs-7 mb-1">
											Quantidades identificadas
										</label>
										<CardOverlayTrigger message="Quando o cidadão tem um caso identificado no periodo filtrado, será exibido com sua situação FCI e atendimentos relacionados com o respectivo problema." />
									</div>
									<span className="mx-3 py-1 fs-1 fw-bold rounded">
										{!isLoading ? (
											<span className="bg-secondary px-5 fs-7">
												{Number(
													(diseaseResumeData?.recoveredCount || 0) +
														(diseaseResumeData?.underTreatmentCount ||
															0) +
														(diseaseResumeData.untreatedCount || 0)
												).toLocaleString()}
											</span>
										) : (
											<Skeleton />
										)}
									</span>
								</Card.Title>
							</Card.Header>
							<Card.Body className="p-0">
								<div className="d-flex flex-column gap-3">
									<IndicatorQuantityStatistic
										isLoading={isLoading}
										theme="primary"
										value={Number(diseaseResumeData?.recoveredCount || 0)}
										valueDescription="Cidadãos com atendimento realizado e que foram encerrados na FCI."
									/>
									<IndicatorQuantityStatistic
										isLoading={isLoading}
										theme="warning"
										value={Number(diseaseResumeData?.underTreatmentCount || 0)}
										valueDescription="Cidadãos com atendimento realizado e que não foram encerrados na FCI"
									/>
									<IndicatorQuantityStatistic
										isLoading={isLoading}
										theme="danger"
										value={Number(diseaseResumeData?.untreatedCount || 0)}
										valueDescription="Cidadãos sem atendimento realizado após a última identificação da doença por FCI ou FAI"
									/>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</section>
			<section className="p-2">
				<Card className="w-100">
					<Card.Body className="p-2">
						<FiltersTableCitizensWithDiseases
							searchFullName={searchFullName}
							selectedTreatmentType={selectedTreatmentType}
							setSearchFullName={setSearchFullName}
							setSelectedTreatmentType={setSelectedTreatmentType}
							handleExportTable={handleExportTable}
							isLoading={showLoadingScreen}
						/>
						<TableCitizensWithDiseases
							diseaseData={diseaseData}
							isLoading={isLoading}
						/>
					</Card.Body>
					<Card.Footer className="no-print">
						{diseaseData.totalRecords ? (
							<PaginationLinks
								itemsPerPage={diseaseData.pageSize}
								totalPages={diseaseData.totalRecords}
								changeSelectedPage={handleChangePageNumber}
								pageNumber={pageNumber || 1}
							/>
						) : (
							diseaseData.totalRecords === 0 &&
							!isLoading && (
								<NoRecordsFeedback
									message={"Nenhum resultado encontrado."}
									icon={<FaUser />}
								/>
							)
						)}
					</Card.Footer>
				</Card>
			</section>
		</div>
	);
}
