import { useState } from "react";
import { FaCircle } from "react-icons/fa";
import { MdOutlinePregnantWoman } from "react-icons/md";
import { RiCheckboxBlankFill } from "react-icons/ri";

export type RacialColor = "white" | "black" | "yellow" | "indigenous" | "brown";

// Total number of people (100% = 100 people)
const totalPeople = 100;

// Function to calculate number of people per racialColor

type Props = {
	racialColorDistribution: Record<RacialColor, number>;
};

type SelectedRacialColor = {
	name: string;
	classNameColor: string;
};

export function PeopleChart({ racialColorDistribution }: Props) {
	const [selectedRacialColor, setSelectedRacialColor] = useState<SelectedRacialColor>(
		{} as SelectedRacialColor
	);
	const total =
		racialColorDistribution.black +
		racialColorDistribution.brown +
		racialColorDistribution.indigenous +
		racialColorDistribution.white +
		racialColorDistribution.yellow;
	const racialCategoriesPeople = [
		{
			name: "black",
			value: calculatePeoplePerRacialColor(racialColorDistribution.black, total),
		},
		{
			name: "brown",
			value: calculatePeoplePerRacialColor(racialColorDistribution.brown, total),
		},
		{
			name: "indigenous",
			value: calculatePeoplePerRacialColor(racialColorDistribution.indigenous, total),
		},
		{
			name: "white",
			value: calculatePeoplePerRacialColor(racialColorDistribution.white, total),
		},
		{
			name: "yellow",
			value: calculatePeoplePerRacialColor(racialColorDistribution.yellow, total),
		},
	].sort((a, b) => b.value - a.value);
	const totalRacialCategoriesPeople = racialCategoriesPeople
		.map((racialCategoryPeople) => racialCategoryPeople.value)
		.reduce((a, b) => a + b);

	function calculatePeoplePerRacialColor(people: number, total: number) {
		if (!total) return 0;
		return Math.round((people / total) * totalPeople);
	}

	//adjust rounded values when total is greater or less than 100%
	if (totalRacialCategoriesPeople !== 100 && total > 0) {
		Array.from({
			length:
				totalRacialCategoriesPeople > 100
					? totalRacialCategoriesPeople - 100
					: 100 - totalRacialCategoriesPeople,
		}).forEach((_, index) => {
			racialCategoriesPeople[
				index %
					(racialCategoriesPeople.filter((racialCategory) => racialCategory.value > 0)
						.length || 1)
			].value =
				racialCategoriesPeople[
					index %
						(racialCategoriesPeople.filter((racialCategory) => racialCategory.value > 0)
							.length || 1)
				].value + (totalRacialCategoriesPeople > 100 ? -1 : +1);
		});
	}

	// Create an array representing the distribution of people by racialColor
	const people = [
		...Array.from({
			length:
				racialCategoriesPeople.find((category) => category.name === "white")?.value || 0,
		}).fill("white"),
		...Array.from({
			length:
				racialCategoriesPeople.find((category) => category.name === "black")?.value || 0,
		}).fill("black"),
		...Array.from({
			length:
				racialCategoriesPeople.find((category) => category.name === "yellow")?.value || 0,
		}).fill("yellow"),
		...Array.from({
			length:
				racialCategoriesPeople.find((category) => category.name === "indigenous")?.value ||
				0,
		}).fill("indigenous"),
		...Array.from({
			length:
				racialCategoriesPeople.find((category) => category.name === "brown")?.value || 0,
		}).fill("brown"),
	];

	function showTooltip(
		evt: React.MouseEvent<SVGElement, MouseEvent>,
		racialColor: SelectedRacialColor
	) {
		const tooltip = document.getElementById("people-tooltip");
		if (!tooltip) return;
		setSelectedRacialColor(racialColor);
		tooltip.style.display = "block";
		tooltip.style.left = evt.clientX - 65 + "px";
		tooltip.style.top = evt.clientY - 100 + "px";
	}

	function hideTooltip() {
		const tooltip = document.getElementById("people-tooltip");
		if (!tooltip) return;
		setSelectedRacialColor({ name: "", classNameColor: "" });
		tooltip.style.display = "none";
	}
	const [iconWidth, setIconWidth] = useState(window.innerWidth / 40);

	window.onresize = () => {
		setIconWidth(window.innerWidth / 40);
	};

	return (
		<div className="d-flex flex-column justify-content-center " style={{ minHeight: 300 }}>
			<table style={{ flexGrow: 1, height: "100%" }}>
				<tbody>
					{Array(10)
						.fill("")
						.map((_, rowIndex) => (
							<tr
								key={rowIndex}
								className="border-none m-0 p-0"
								style={{ borderBottomStyle: "none" }}
							>
								{Array(10)
									.fill("")
									.map((_, colIndex) => {
										const personIndex = rowIndex * 10 + colIndex;
										const racialColor = people[personIndex];

										// Define color based on racialColor
										const iconColor = {
											white: "text-primary",
											black: "text-warning",
											yellow: "text-success",
											indigenous: "text-danger",
											brown: "text-info",
										}[racialColor as RacialColor];

										return (
											<td key={colIndex} className="m-0 p-0">
												<MdOutlinePregnantWoman
													onMouseEnter={(event) => {
														if (iconColor === "text-primary") {
															showTooltip(event, {
																name: "Brancas",
																classNameColor: "primary",
															});
														} else if (iconColor === "text-warning") {
															showTooltip(event, {
																name: "Negras",
																classNameColor: "warning",
															});
														} else if (iconColor === "text-success") {
															showTooltip(event, {
																name: "Amarelas",
																classNameColor: "success",
															});
														} else if (iconColor === "text-danger") {
															showTooltip(event, {
																name: "índigenas",
																classNameColor: "danger",
															});
														} else if (iconColor === "text-info") {
															showTooltip(event, {
																name: "Pardas",
																classNameColor: "info",
															});
														}
													}}
													onMouseLeave={() => hideTooltip()}
													size={iconWidth}
													className={iconColor}
												/>
											</td>
										);
									})}
							</tr>
						))}
				</tbody>
			</table>
			<div className="d-flex w-100 justify-content-center">
				<div className="d-flex gap-2 mt-3">
					<div className="d-flex flex-column text-center">
						<div>
							<RiCheckboxBlankFill className="text-primary" />
							<span>{`Brancas : ${
								racialCategoriesPeople.find((category) => category.name === "white")
									?.value || 0
							}%`}</span>
						</div>
						<span>{racialColorDistribution.white}</span>
					</div>
					<div className="d-flex flex-column text-center">
						<div>
							<RiCheckboxBlankFill className="text-warning" />
							<span>{`Negras : ${
								racialCategoriesPeople.find((category) => category.name === "black")
									?.value || 0
							}%`}</span>
						</div>
						<span>{racialColorDistribution.black}</span>
					</div>
					<div className="d-flex flex-column text-center">
						<div>
							<RiCheckboxBlankFill className="text-success" />
							<span>{`Amarelas : ${
								racialCategoriesPeople.find(
									(category) => category.name === "yellow"
								)?.value || 0
							}%`}</span>
						</div>
						<span>{racialColorDistribution.yellow}</span>
					</div>
					<div className="d-flex flex-column text-center">
						<div>
							<RiCheckboxBlankFill className="text-danger" />
							<span>{`Índigenas : ${
								racialCategoriesPeople.find(
									(category) => category.name === "indigenous"
								)?.value || 0
							}%`}</span>
						</div>
						<span>{racialColorDistribution.indigenous}</span>
					</div>
					<div className="d-flex flex-column text-center">
						<div>
							<RiCheckboxBlankFill className="text-info" />
							<span>{`Pardas : ${
								racialCategoriesPeople.find((category) => category.name === "brown")
									?.value || 0
							}%`}</span>
						</div>
						<span>{racialColorDistribution.brown}</span>
					</div>
				</div>
			</div>
			<div
				id="people-tooltip"
				className="rounded"
				style={{ position: "fixed", display: "none", zIndex: "1" }}
			>
				<div className="d-flex flex-column align-items-center py-2 px-4">
					<div className="bg-secondary p-2 w-100">
						<label className="text-nowrap">{selectedRacialColor.name}</label>
					</div>
					<div className="border border-secondary text-nowrap bg-white p-2 w-100">
						<FaCircle className={`me-3 text-${selectedRacialColor?.classNameColor}`} />
						<label className="text-nowrap">
							<label className="me-2">Total:</label>
							<label className="fw-bolder">
								{selectedRacialColor.name === "Brancas"
									? racialColorDistribution.white?.toLocaleString()
									: selectedRacialColor.name === "Negras"
									? racialColorDistribution.black?.toLocaleString()
									: selectedRacialColor.name === "Amarelas"
									? racialColorDistribution.yellow?.toLocaleString()
									: selectedRacialColor.name === "índigenas"
									? racialColorDistribution.indigenous?.toLocaleString()
									: selectedRacialColor.name === "Pardas"
									? racialColorDistribution.brown?.toLocaleString()
									: 0}
							</label>
						</label>
					</div>
				</div>
			</div>
		</div>
	);
}
