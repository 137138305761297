import Form from "react-bootstrap/Form";

import { SearchInput } from "@/components/SearchInput";

type Props = {
	cityName: string;
	setCityName: (cityName: string) => void;
};

export function PregnancyDashboardIndicatorsTableFilters({ cityName, setCityName }: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
			}}
		>
			<div className="d-flex justify-content-between align-items-center">
				<div>
					<h3>Ranking dos indicadores por município</h3>
				</div>
				<div>
					<SearchInput
						placeholder="Pesquisar município"
						value={cityName}
						setValue={setCityName}
					/>
				</div>
			</div>
		</Form>
	);
}
