import { useEffect, useState } from "react";

import { FaChartBar, FaCloudDownloadAlt, FaPhoneAlt } from "react-icons/fa";
import { MdComputer } from "react-icons/md";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useApi } from "@/hooks/useApi";
import { BannerTypeEnum } from "@/utils/enums/BannerTypeEnum";
import { ReleaseNote } from "@/@types/ReleaseNote";

import { ModalReleaseNoteView } from "@/components/ReleaseNotes/ModalReleaseNoteView";
import { BannerDisplay } from "@/components/BannerDisplay";
import "./styles.scss";

export function Home() {
	const api = useApi();
	const { setTitle } = useLayout();
	const { ibgeCode } = useCurrentAccount();

	const [releaseNote, setReleaseNote] = useState<ReleaseNote>({} as ReleaseNote);

	async function fetchPendingReleaseNote() {
		try {
			const { data } = await api.get<ReleaseNote>("/releasenote/v1/GetPendingReleaseNote");
			setReleaseNote(data || ({} as ReleaseNote));
			if (data?.id) {
				handleShowModalReleaseNoteView();
			}
		} catch (error) {
			console.log(error);
			setReleaseNote({} as ReleaseNote);
		}
	}

	const [showModalReleaseNoteView, setShowModalReleaseNoteView] = useState(false);
	const handleShowModalReleaseNoteView = () => {
		setShowModalReleaseNoteView(true);
	};
	const handleCloseModalReleaseNoteView = () => {
		setShowModalReleaseNoteView(false);
	};

	useEffect(() => {
		setTitle("");
		if (ibgeCode) {
			fetchPendingReleaseNote();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div>
			<ModalReleaseNoteView
				titlemodal="Notas de Atualização"
				show={showModalReleaseNoteView}
				onHide={handleCloseModalReleaseNoteView}
				releaseNote={releaseNote}
			/>
			<section>
				<BannerDisplay bannerTypeEnum={BannerTypeEnum.HOME} />
			</section>
			<Row className="d-flex justify-content-around">
				<Col sm={12} md={4} lg={3} className="m-3">
					<Card className="h-100">
						<FaCloudDownloadAlt className="h-25 w-25 px-2 text-primary" />
						<Card.Body>
							<Card.Title>Centralizador em nuvem</Card.Title>
							<Card.Text>
								Backups automatizados de forma segura unificando informações de
								registros
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={4} lg={3} className="m-3">
					<Card className="h-100">
						<MdComputer className="h-25 w-25 px-2 text-primary" />
						<Card.Body>
							<Card.Title>Prontuário Eletrônico</Card.Title>
							<Card.Text>
								Leitura dos registros de atendimento dos profissionais de saúde da
								atenção básica
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={4} lg={3} className="m-3">
					<Card className="h-100">
						<FaChartBar className="h-25 w-25 px-2 text-primary" />
						<Card.Body>
							<Card.Title>Dashboard</Card.Title>
							<Card.Text>
								Monitoramento dos indicadores para tomada de decisões mais precisas
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<a rel="noreferrer" href="https://wa.me/5585992487956" target="_blank">
				<div title="Contato suporte" className="btn-whatsapp pulsaDelay">
					<FaPhoneAlt />
				</div>
			</a>
		</div>
	);
}
