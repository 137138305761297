import { useState } from "react";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { City } from "@/@types/statesAndCities";

import { Classification, StateGeographicalNetwork } from "@/components/StateGeographicalNetwork";
import {
	calculatePregnantIndicatorPercentage,
	calculateTotalPregnantIndicatorsPercentage,
	getPregnantIndicatorColor,
	getPregnantIndicatorDescription,
} from "../PregnancyDashboardIndicatorsTable";
import { PregnancyIndicatorsResumeCity } from "..";
import { LegendData } from "@/components/LegendData";

type Props = {
	pregnancyIndicatorsResumeCities: PregnancyIndicatorsResumeCity[];
	indicatorType?: 1 | 2 | 3;
};

export function PregnancyDashboardIndicatorsMap({
	pregnancyIndicatorsResumeCities,
	indicatorType,
}: Props) {
	const [selectedCity, setSelectedCity] = useState<City>();
	const { currentFederalUnit } = useCurrentAccount();

	const citiesClassificated: Classification[] = pregnancyIndicatorsResumeCities.map((resume) => {
		return {
			city: resume.city,
			color:
				getPregnantIndicatorColor(
					!indicatorType
						? calculateTotalPregnantIndicatorsPercentage(resume)
						: calculatePregnantIndicatorPercentage(
								indicatorType === 1
									? resume.indicator1Resumes
									: indicatorType === 2
									? resume.indicator2Resumes
									: resume.indicator3Resumes
						  )
				) || "",
			cityDetails: (
				<>
					<p>
						Indicador:{" "}
						<strong>
							{!indicatorType
								? calculateTotalPregnantIndicatorsPercentage(resume)
								: calculatePregnantIndicatorPercentage(
										indicatorType === 1
											? resume.indicator1Resumes
											: indicatorType === 2
											? resume.indicator2Resumes
											: resume.indicator3Resumes
								  )}
							%
						</strong>
					</p>
					<p>
						Classificação:{" "}
						<strong
							style={{
								color:
									getPregnantIndicatorColor(
										!indicatorType
											? calculateTotalPregnantIndicatorsPercentage(resume)
											: calculatePregnantIndicatorPercentage(
													indicatorType === 1
														? resume.indicator1Resumes
														: indicatorType === 2
														? resume.indicator2Resumes
														: resume.indicator3Resumes
											  )
									) || "",
							}}
						>
							{getPregnantIndicatorDescription(
								!indicatorType
									? calculateTotalPregnantIndicatorsPercentage(resume)
									: calculatePregnantIndicatorPercentage(
											indicatorType === 1
												? resume.indicator1Resumes
												: indicatorType === 2
												? resume.indicator2Resumes
												: resume.indicator3Resumes
									  )
							)}
						</strong>
					</p>
				</>
			),
		};
	});

	function showTooltip(evt: MouseEvent) {
		const tooltip = document.getElementById("map-tooltip");
		if (!tooltip) return;

		tooltip.style.display = "block";
		tooltip.style.left = evt.clientX + 20 + "px";
		tooltip.style.top = evt.clientY - 50 + "px";
	}

	function hideTooltip(evt: MouseEvent) {
		const tooltip = document.getElementById("map-tooltip");
		if (!tooltip) return;
		setSelectedCity({} as City);
		tooltip.style.display = "none";
	}

	return (
		<div>
			<LegendData
				ClassNamesColors={["text-primary", "text-success", "text-warning", "text-danger"]}
				titles={["Ótimo", "Bom", "Regular", "Ruim"]}
			/>
			<StateGeographicalNetwork
				setSelectedCity={setSelectedCity}
				federalUnit={currentFederalUnit}
				height="400"
				width="400"
				classifications={citiesClassificated}
				showTooltip={showTooltip}
				hideTooltip={hideTooltip}
			>
				<div id="map-tooltip" style={{ position: "fixed", display: "none", zIndex: "5" }}>
					<div>
						<div className="fw-bolder text-center text-white bg-primary p-3">
							{selectedCity?.nome}
						</div>
						<div className="border border-secondary bg-white px-3">
							{
								citiesClassificated?.find(
									(classification) =>
										classification.city.ibgeCode === String(selectedCity?.id)
								)?.cityDetails
							}
						</div>
					</div>
				</div>
			</StateGeographicalNetwork>
		</div>
	);
}
