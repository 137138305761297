import Select, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";

type Props = SelectProps & {
	noDefaultOption?: boolean;
};

const animatedComponents = makeAnimated();

export const defaultGender = { value: "", label: "Todos" };

export function GenderSelect({ noDefaultOption, ...rest }: Props) {
	const options = [
		{ value: "", label: "Todos" },
		{ value: "m", label: "Masculino" },
		{ value: "f", label: "Feminino" },
		{ value: "i", label: "Ignorado" },
	];
	return (
		<div className="d-flex flex-column">
			<label title="Lista de Unidades Disponíveis" htmlFor={rest.id}>
				Gênero:
			</label>
			<Select
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhuma opção encontrada"}
				styles={{
					menu: (base) => ({ ...base, zIndex: 90 }),
					control: (base) => ({
						...base,
						minHeight: "30px",
						padding: "0px",
					}),
					valueContainer: (base) => ({
						...base,
						padding: "0px 4px",
					}),
					indicatorsContainer: (base) => ({
						...base,
						height: "30px",
					}),
				}}
				{...rest}
			/>
		</div>
	);
}
