import dayjs from "dayjs";

import { FaSyringe } from "react-icons/fa";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { WeightClassificationEnum } from "@/utils/enums/WeightClassificationEnum";
import { PregnancyTypeEnum } from "@/utils/enums/PregnancyTypeEnum";
import { RacialColorEnum } from "@/utils/enums/RacialColorEnum";
import { Pregnancy } from "@/services/esus/attendanceService";

import { TimeLine, TimeLineContent } from "@/components/TimeLine";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { VaccinationCard, VaccinationCardData } from "@/components/VaccinationCard";

type Props = ModalProps & {
	pregnantWithChildBirth: Pregnancy;
};

export function PregnancyModal({ pregnantWithChildBirth, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h1 className="text-uppercase text-dark fw-bold fs-7">SAÚDE DA GESTANTE</h1>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h1 className="fs-7">INFORMAÇÕES PESSOAIS</h1>
				<section className="my-3">
					<Row>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Paciente: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.fullName
									? pregnantWithChildBirth?.fullName
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Data de nascimento: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.birthdate
									? dayjs(pregnantWithChildBirth?.birthdate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Idade: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.age ? pregnantWithChildBirth?.age : "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Nis: </span>
							<span className="fs-8">{pregnantWithChildBirth?.nis || "N/A"}</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Ocupação: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.citizenCbo?.description
									? `${pregnantWithChildBirth?.citizenCbo?.code} - ${pregnantWithChildBirth?.citizenCbo?.description}`
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Telefone: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.phone
									? pregnantWithChildBirth?.phone
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Fuma: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.smokerStatus === 1 ? "SIM" : "NÃO"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Bebe: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.alcoholicStatus === 1 ? "SIM" : "NÃO"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Diabetes: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.haveDiabetes === 1 ? "SIM" : "NÃO"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Hipertenso: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.haveHighBloodPressure === 1
									? "SIM"
									: "NÃO"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">
								Última atualização cadastral:{" "}
							</span>
							<span className="fs-8">
								{pregnantWithChildBirth?.lastIndividualRecordDate
									? dayjs(pregnantWithChildBirth.lastIndividualRecordDate).format(
											"DD/MM/YYYY"
									  )
									: "N/A"}
							</span>
						</Col>

						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Unidade: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.healthUnit?.name
									? pregnantWithChildBirth?.healthUnit.name
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Equipe: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.healthTeam?.name
									? pregnantWithChildBirth?.healthTeam?.name
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Cor ou raça: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.racialColor === RacialColorEnum.WHITE
									? "BRANCA"
									: pregnantWithChildBirth?.racialColor === RacialColorEnum.BLACK
									? "PRETA"
									: pregnantWithChildBirth?.racialColor === RacialColorEnum.YELLOW
									? "PARDA"
									: pregnantWithChildBirth?.racialColor === RacialColorEnum.BROWN
									? "AMARELA"
									: pregnantWithChildBirth?.racialColor ===
									  RacialColorEnum.INDIGENOUS
									? "INDÍGENA"
									: "N/A"}
							</span>
						</Col>
					</Row>
				</section>
				<h1 className="fs-7">INFORMAÇÕES DA GESTAÇÃO</h1>
				<section className="my-3">
					<Row>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">DUM: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.firstDumDate
									? dayjs(pregnantWithChildBirth?.firstDumDate).format(
											"DD/MM/YYYY"
									  )
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">DPP: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.dppDate
									? dayjs(pregnantWithChildBirth?.dppDate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">1ª IG: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.firstGestationalAge
									? `${pregnantWithChildBirth?.firstGestationalAge} SEMANAS`
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Tipo de gravidez: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.pregnancyType === PregnancyTypeEnum.SINGLE
									? "ÚNICA"
									: pregnantWithChildBirth?.pregnancyType ===
									  PregnancyTypeEnum.DOUBLE
									? "DUPLA"
									: pregnantWithChildBirth?.pregnancyType ===
									  PregnancyTypeEnum.TRIPLEORMORE
									? "TRÊS OU MAIS"
									: pregnantWithChildBirth?.pregnancyType ===
									  PregnancyTypeEnum.IGNORED
									? "IGNORADO"
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Gravidez de risco: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.highRiskStatus === 1
									? "SIM"
									: pregnantWithChildBirth?.highRiskStatus === 0
									? "NÃO"
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Gravidez planejada: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.plannedPregnancyStatus === 1
									? "SIM"
									: pregnantWithChildBirth?.plannedPregnancyStatus === 0
									? "NÃO"
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">
								Classificação de peso:{" "}
							</span>
							<span className="fs-8">
								{pregnantWithChildBirth?.weightClassification ===
								WeightClassificationEnum.LIGHTWEIGHT
									? "ABAIXO DO PESO"
									: pregnantWithChildBirth?.weightClassification ===
									  WeightClassificationEnum.PROPERWEIGHT
									? "PESO IDEAL"
									: pregnantWithChildBirth?.weightClassification ===
									  WeightClassificationEnum.OVERWEIGHT
									? "ACIMA DO PESO"
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Peso: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.attendances &&
								pregnantWithChildBirth?.attendances.length > 0 &&
								pregnantWithChildBirth?.attendances[0].weight
									? `${pregnantWithChildBirth?.attendances[0].weight || 0} KG`
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Altura: </span>
							<span className="fs-8">
								{pregnantWithChildBirth?.attendances &&
								pregnantWithChildBirth?.attendances.length > 0 &&
								pregnantWithChildBirth?.attendances[0].height
									? `${pregnantWithChildBirth?.attendances[0].height || 0} CM`
									: "N/A"}
							</span>
						</Col>

						{pregnantWithChildBirth.childBirthDate && (
							<>
								<Col sm={12} md={4} lg={4} xl={4}>
									<span className="fw-bold fs-8 text-primary">
										Data do parto:{" "}
									</span>
									<span className="fs-8">
										{dayjs(pregnantWithChildBirth.childBirthDate).format(
											"DD/MM/YYYY"
										)}
									</span>
								</Col>
								<Col sm={12} md={8} lg={8} xl={8}>
									<span className="fw-bold fs-8 text-primary">
										Tipo de parto:{" "}
									</span>
									<span className="fs-8">
										{pregnantWithChildBirth?.childBirthType === "normal"
											? "NORMAL"
											: pregnantWithChildBirth?.childBirthType === "cesarean"
											? "CESÁREO"
											: pregnantWithChildBirth?.childBirthType ===
											  "normalstillbirth"
											? "NATIMORTO (parto normal)"
											: pregnantWithChildBirth?.childBirthType ===
											  "cesareanstillbirth"
											? "NATIMORTO (parto cesário)"
											: pregnantWithChildBirth?.childBirthType === "abortion"
											? "ABORTO"
											: pregnantWithChildBirth?.childBirthType ===
											  "unspecified"
											? "NÃO ESPECIFICADO"
											: pregnantWithChildBirth?.childBirthType ===
											  "unregistered"
											? "NÃO REGISTRADO"
											: "N/A"}
									</span>
								</Col>
							</>
						)}
						{pregnantWithChildBirth.childBirthProblem && (
							<>
								<Col sm={12} md={4} lg={4} xl={4}>
									<span className="fw-bold fs-8 text-primary">CID: </span>
									<span className="fs-8">
										{`${pregnantWithChildBirth.childBirthProblem.cid?.cidCode} - ${pregnantWithChildBirth.childBirthProblem.cid?.description}`}
									</span>
								</Col>
								<Col sm={12} md={8} lg={8} xl={8}>
									<span className="fw-bold fs-8 text-primary">CIAP: </span>
									<span className="fs-8">
										{`${pregnantWithChildBirth.childBirthProblem.ciap?.ciapCode} - ${pregnantWithChildBirth.childBirthProblem.ciap?.description}`}
									</span>
								</Col>
							</>
						)}
					</Row>
				</section>

				<TimeLine
					timeLineTitle="CONSULTAS"
					timeLineContents={pregnantWithChildBirth.attendances?.map<TimeLineContent>(
						(attendance, index) => {
							return {
								content: (
									<Row key={`patient-attendance-${index}`}>
										<Col sm={6} md={6} lg={9} xl={9}>
											<div className="fs-8">
												<span className="text-primary">PROFISSIONAL: </span>
												<p className="fs-8">
													<span className="fw-bolder fs-8">Nome: </span>
													{attendance?.professional1?.name?.toUpperCase() ||
														"N/A"}
												</p>
												<p className="fs-8">
													<span className="fw-bolder fs-8">CBO: </span>
													{attendance?.cbo1?.description?.toUpperCase() ||
														"N/A"}
												</p>
												<p className="fs-8">
													<span className="fw-bolder fs-8">
														Unidade:{" "}
													</span>
													{attendance?.healthUnit?.name?.toUpperCase() ||
														"N/A"}
												</p>
												<p className="fs-8">
													<span className="fw-bolder fs-8">Equipe: </span>
													{attendance?.healthTeam?.name?.toUpperCase() ||
														"N/A"}
												</p>
												<span className="text-primary fs-8">MOTIVO: </span>
												<p className="fs-8">
													<span className="fw-bolder fs-8">Cid: </span>
													{`${
														attendance?.problems?.at(0)?.cid?.cidCode
													} ${
														attendance?.problems?.at(0)?.cid
															?.description
													}`}
												</p>
												<p className="fs-8">
													<span className="fw-bolder">Ciap: </span>
													{`${
														attendance?.problems?.at(0)?.ciap?.ciapCode
													} - ${
														attendance?.problems?.at(0)?.ciap
															?.description
													}`}
												</p>
											</div>
										</Col>
									</Row>
								),
								eventDate: attendance?.date
									? dayjs(attendance?.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>

				<TimeLine
					timeLineTitle="EXAMES DE HIV E SÍFILIS"
					timeLineContents={pregnantWithChildBirth.attendanceProcedures?.map<TimeLineContent>(
						(attendanceProcedure, index) => {
							return {
								content: (
									<Row key={`patient-exams-${index}`}>
										<Col sm={6} md={6} lg={9} xl={9}>
											<div>
												<span className="text-primary fs-8">
													PROFISSIONAL:{" "}
												</span>
												<p className="fs-8">
													<span className="fw-bolder fs-8">Nome: </span>
													{attendanceProcedure?.professional?.name?.toUpperCase() ||
														"N/A"}
												</p>
												<p className="fs-8">
													<span className="fw-bolder fs-8">CBO: </span>
													{attendanceProcedure?.cbo?.description?.toUpperCase() ||
														"N/A"}
												</p>
												<span className="text-primary fs-8">EXAME: </span>
												<p>
													<span className="fw-bolder fs-8">
														Procedimento:{" "}
													</span>
													{`${
														attendanceProcedure?.procedure
															?.procedureCode ?? "N/A"
													} - ${
														attendanceProcedure?.procedure?.name ?? ""
													}`}
												</p>
											</div>
										</Col>
									</Row>
								),
								eventDate: attendanceProcedure?.date
									? dayjs(attendanceProcedure?.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>

				<TimeLine
					timeLineTitle="OUTROS EXAMES GESTACIONAIS"
					timeLineContents={pregnantWithChildBirth.pregnancyProcedures?.map<TimeLineContent>(
						(attendanceProcedure, index) => {
							return {
								content: (
									<Row key={`patient-exams-${index}`}>
										<Col sm={6} md={6} lg={9} xl={9}>
											<div>
												<span className="text-primary fs-8">
													PROFISSIONAL:{" "}
												</span>
												<p className="fs-8">
													<span className="fw-bolder fs-8">Nome: </span>
													{attendanceProcedure?.professional1?.name?.toUpperCase() ||
														"N/A"}
												</p>
												<p className="fs-8">
													<span className="fw-bolder">CBO: </span>
													{attendanceProcedure?.cbo1?.description?.toUpperCase() ||
														"N/A"}
												</p>
												<span className="text-primary fs-8">EXAME: </span>
												<p className="fs-8">
													<span className="fw-bolder">
														Procedimento:{" "}
													</span>
													{`${
														attendanceProcedure?.attProcedureEvaluated
															?.procedureCode ?? "N/A"
													} - ${
														attendanceProcedure?.attProcedureEvaluated
															?.name ?? ""
													}`}
												</p>
											</div>
										</Col>
									</Row>
								),
								eventDate: attendanceProcedure?.date
									? dayjs(attendanceProcedure?.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>

				<TimeLine
					timeLineTitle="ATENDIMENTO ODONTOLÓGICO"
					timeLineContents={pregnantWithChildBirth.dentalAttendances?.map<TimeLineContent>(
						(dentalAttendance, index) => {
							return {
								content: (
									<Row key={`patient-dental-${index}`}>
										<Col sm={6} md={6} lg={9} xl={9}>
											<div>
												<span className="text-primary fs-8">
													PROFISSIONAL:{" "}
												</span>
												<p className="fs-8">
													<span className="fw-bolder fs-8">Nome: </span>
													{dentalAttendance?.professional1?.name?.toUpperCase() ||
														"N/A"}
												</p>
												<p className="fs-8">
													<span className="fw-bolder fs-8">
														Categoria:{" "}
													</span>
													{dentalAttendance?.cbo1?.description?.toUpperCase() ||
														"N/A"}
												</p>
												{dentalAttendance.professional2?.name && (
													<>
														<span className="text-primary fs-8">
															PROFISSIONAL 2:
														</span>
														<p className="fs-8">
															<span className="fw-bolder">
																Nome:{" "}
															</span>
															{dentalAttendance?.professional2?.name?.toUpperCase() ||
																"N/A"}
														</p>
														<p className="fs-8">
															<span className="fw-bolder fs-8">
																Categoria:{" "}
															</span>
															{dentalAttendance?.cbo2?.description?.toUpperCase() ||
																"N/A"}
														</p>
													</>
												)}
											</div>
										</Col>
									</Row>
								),
								eventDate: dentalAttendance?.date
									? dayjs(dentalAttendance?.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>

				{pregnantWithChildBirth?.pregnancyVaccinations?.length &&
				pregnantWithChildBirth?.pregnancyVaccinations?.length > 0 ? (
					<Row className="mt-4">
						<VaccinationCard
							vaccinations={
								pregnantWithChildBirth.pregnancyVaccinations?.map<VaccinationCardData>(
									(vaccination) => {
										return {
											...vaccination,
											dateofVaccine: vaccination.dateofVaccine
												? dayjs(vaccination.dateofVaccine).format(
														"DD/MM/YYYY"
												  )
												: "",
											status: "vaccinated",
										};
									}
								) || []
							}
						/>
					</Row>
				) : (
					<div>
						<h1 className="ms-20 fs-5">Vacinações</h1>
						<NoRecordsFeedback
							icon={<FaSyringe size={30} />}
							message="Nenhuma vacinação foi encontrada no período."
						/>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
}
