import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ResumeGrouped } from "@/services/esus/attendanceService";
import { Account } from "@/@types/Account";

import { PregnancyDashboardIndicatorsDetails } from "./PregnancyDashboardIndicatorsDetails";
import { PregnancyDashboardIndicatorsHeader } from "./PregnancyDashboardIndicatorsHeader";

export type PregnancyIndicatorsResume = {
	indicator1Resumes: ResumeGrouped<"finished" | "pending" | "lost" | null, object>[];
	indicator2Resumes: ResumeGrouped<"finished" | "pending" | "lost" | null, object>[];
	indicator3Resumes: ResumeGrouped<"finished" | "pending" | "lost" | null, object>[];
};

export type PregnancyIndicatorsResumeCity = PregnancyIndicatorsResume & {
	city: Account;
};

type Props = {
	pregnancyIndicatorsResumeCities: PregnancyIndicatorsResumeCity[];
};

export function PregnancyDashboardIndicators({ pregnancyIndicatorsResumeCities }: Props) {
	return (
		<section className="px-2">
			<Row>
				<Col>
					<Card className="m-2">
						<Card.Body>
							<PregnancyDashboardIndicatorsHeader />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<PregnancyDashboardIndicatorsDetails
				pregnancyIndicatorsResumeCities={pregnancyIndicatorsResumeCities}
			/>
		</section>
	);
}
