import { useState } from "react";

import dayjs from "dayjs";

import { TbAlertCircleFilled } from "react-icons/tb";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaCheckCircle, FaEllipsisV, FaEye } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { OrderBy } from "@/@types/OrderBy";
import { Pregnancy } from "@/services/esus/attendanceService";
import { formatIdentifier } from "@/utils/formatIdentifier";

import { TableSkeletons } from "@/components/TableSkeletons";
import { PregnancyModal } from "./PregnancyModal";
import { OrderByButton } from "@/components/OrderByButton";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { Column, handleColumnToggle } from "@/utils/handleColumnTogle";

type Props = {
	isLoading: boolean;
	pregnantsWithChildBirth: Pregnancy[];
	orderBy?: OrderBy<Pregnancy>[];
	setOrderBy?: (orderBy: OrderBy<Pregnancy>[]) => void;
};

type PregnancyColumnTable = Pick<
	Pregnancy,
	| "fullName"
	| "cpf"
	| "cns"
	| "birthdate"
	| "area"
	| "dppDate"
	| "firstDumDate"
	| "healthTeam"
	| "healthUnit"
	| "firstGestationalAge"
	| "lastGestationalAge"
	| "childBirthDate"
	| "childBirthType"
	| "puerperalStatus"
	| "attendances"
>;

type ColumnsTable = {
	[K in keyof PregnancyColumnTable]: Column; // Para cada chave de Pregnancy, cria uma chave no ColumnsTable do tipo Column
};

export function PregnancyTable({ isLoading, pregnantsWithChildBirth, orderBy, setOrderBy }: Props) {
	const [selectedPregnancy, setSelectedPregnancy] = useState<Pregnancy>({} as Pregnancy);

	const [showPregnantModal, setShowPregnantModal] = useState(false);
	const handleShowPregnantModal = () => {
		setShowPregnantModal(true);
	};
	const handleClosePregnantModal = () => {
		setShowPregnantModal(false);
	};

	const [columns, setColumns] = useState<ColumnsTable>({
		fullName: { title: "Nome", visible: true },
		cpf: { title: "CPF", visible: false },
		cns: { title: "CNS", visible: false },
		birthdate: { title: "DN", visible: false },
		area: { title: "MC", visible: false },
		healthTeam: { title: "Equipe", visible: false },
		healthUnit: { title: "Unidade", visible: false },
		dppDate: { title: "DPP", visible: true },
		childBirthDate: { title: "Parto", visible: true },
		firstDumDate: { title: "DUM", visible: false },
		firstGestationalAge: { title: "1º IG", visible: false },
		lastGestationalAge: { title: "Última IG", visible: true },
		puerperalStatus: { title: "Puerperio", visible: true },
		childBirthType: { title: "Tipo Parto", visible: true },
		attendances: { title: "Consultas", visible: false },
	});

	function changeOrderBy(property: keyof Pregnancy) {
		const orderItem = (orderBy || []).find((orderItem) => orderItem.property === property);
		if (orderItem) {
			const reverse =
				orderItem.method === "asc"
					? ({ property, method: "desc" } as OrderBy<Pregnancy>)
					: null;
			setOrderBy &&
				setOrderBy(
					(orderBy || [])
						.filter((orderItem) => !(orderItem.property === property))
						.concat(reverse ? [reverse] : [])
				);
		} else {
			setOrderBy && setOrderBy((orderBy || []).concat([{ property, method: "asc" }]));
		}
	}

	return (
		<div>
			<PregnancyModal
				pregnantWithChildBirth={selectedPregnancy}
				show={showPregnantModal}
				onHide={handleClosePregnantModal}
			/>
			<Table
				bordered
				responsive
				striped
				hover
				id="table-pregnantsWithChildBirth"
				className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
			>
				<thead>
					<tr className="text-center text-muted">
						<th
							className={`border border-0 ps-2 ${
								!columns.fullName?.visible && "d-none"
							}`}
						>
							<div className="d-flex align-items-center gap-1">
								NOME
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="fullName"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "fullName"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th className={`border border-0 ps-2 ${!columns.cpf?.visible && "d-none"}`}>
							<div className="d-flex align-items-center gap-1">
								CPF
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="cpf"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "cpf"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th className={`border border-0 ps-2 ${!columns.cns?.visible && "d-none"}`}>
							<div className="d-flex align-items-center gap-1">
								CNS
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="cns"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "cns"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th
							className={`border border-0 ${!columns.birthdate?.visible && "d-none"}`}
						>
							<div className="d-flex align-items-center gap-1">
								DN
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="birthdate"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "birthdate"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th
							className={`border border-0 ps-2 ${!columns.area?.visible && "d-none"}`}
						>
							<div className="d-flex align-items-center gap-1">
								MC
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="area"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "area"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th
							className={`border border-0 ps-2 ${
								!columns.healthTeam?.visible && "d-none"
							}`}
						>
							Equipe
						</th>
						<th
							className={`border border-0 ps-2 ${
								!columns.healthUnit?.visible && "d-none"
							}`}
						>
							Unidade
						</th>
						<th
							className={`border border-0 ps-2 ${
								!columns.dppDate?.visible && "d-none"
							}`}
						>
							<div className="d-flex align-items-center gap-1">
								DPP
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="dppDate"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "dppDate"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th
							className={`border border-0 ${
								!columns.firstDumDate?.visible && "d-none"
							}`}
						>
							<div className="d-flex align-items-center gap-1">
								DUM
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="firstDumDate"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "firstDumDate"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th
							className={`border border-0 ${
								!columns.firstGestationalAge?.visible && "d-none"
							}`}
						>
							<div className="d-flex align-items-center gap-1">
								1ª IG
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="firstGestationalAge"
										method={
											orderBy.find(
												(orderItem) =>
													orderItem.property === "firstGestationalAge"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th
							className={`border border-0 ${
								!columns.lastGestationalAge?.visible && "d-none"
							}`}
						>
							<div className="d-flex align-items-center gap-1">
								ÚLTIMA IG
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="lastGestationalAge"
										method={
											orderBy.find(
												(orderItem) =>
													orderItem.property === "lastGestationalAge"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th
							className={`border border-0 ${
								!columns.childBirthDate?.visible && "d-none"
							}`}
						>
							<div className="d-flex align-items-center gap-1">
								PARTO
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="childBirthDate"
										method={
											orderBy.find(
												(orderItem) =>
													orderItem.property === "childBirthDate"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th className={`${!columns.childBirthType?.visible && "d-none"}`}>
							TIPO PARTO
						</th>
						<th className={`${!columns.puerperalStatus?.visible && "d-none"}`}>
							PUERPERIO
						</th>
						<th className={`${!columns.attendances?.visible && "d-none"}`}>
							CONSULTAS
						</th>
						<th className="d-flex align-items-center justify-content-between text-nowrap no-print no-excel">
							<label>AÇÕES</label>
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={
									<Popover>
										<Popover.Body>
											<Form className="d-flex flex-column gap-2">
												{Object.keys(columns).map((key, index) => (
													<Form.Check
														key={`column-${index}`}
														type="checkbox"
														label={
															columns[key as keyof ColumnsTable]
																?.title
														}
														checked={
															columns[key as keyof ColumnsTable]
																?.visible
														}
														onChange={() =>
															handleColumnToggle(
																key as keyof ColumnsTable,
																setColumns
															)
														}
													/>
												))}
											</Form>
										</Popover.Body>
									</Popover>
								}
								rootClose
							>
								<Button
									title="Exibição de colunas da tabela"
									variant="white"
									className="px-2"
									disabled={isLoading}
								>
									<FaEllipsisV />
								</Button>
							</OverlayTrigger>
						</th>
					</tr>
				</thead>
				<tbody className="table-tbody" id="pregnantsWithChildBirth">
					{!isLoading && pregnantsWithChildBirth?.length > 0
						? pregnantsWithChildBirth?.map((pregnant: Pregnancy, index: number) => (
								<tr
									key={`pregnantsWithChildBirth-${index}`}
									className="text-center align-items-center border-dark ms-2"
								>
									<td
										title="nome"
										className={`text-start text-nowrap ps-2 ${
											!columns.fullName?.visible && "d-none"
										}`}
									>
										{pregnant.fullName}
									</td>
									<td
										title="cpf"
										className={`text-start text-nowrap ps-2 ${
											!columns.cpf?.visible && "d-none"
										}`}
									>
										{formatIdentifier(pregnant.cpf)}
									</td>
									<td
										title="cns"
										className={`text-start text-nowrap ps-2 ${
											!columns.cns?.visible && "d-none"
										}`}
									>
										{pregnant.cns}
									</td>
									<td
										title={pregnant.age}
										className={`text-start text-nowrap ${
											!columns.birthdate?.visible && "d-none"
										}`}
									>
										{pregnant.birthdate
											? dayjs(pregnant.birthdate).format("DD/MM/YYYY")
											: ""}
									</td>
									<td
										title="mc"
										className={`text-start text-nowrap ps-2 ${
											!columns.area?.visible && "d-none"
										}`}
									>
										{pregnant.area}
									</td>
									<td
										title="mc"
										className={`text-start text-nowrap ps-2 ${
											!columns.healthTeam?.visible && "d-none"
										}`}
									>
										{pregnant.healthTeam?.name}
									</td>
									<td
										title="mc"
										className={`text-start text-nowrap ps-2 ${
											!columns.healthUnit?.visible && "d-none"
										}`}
									>
										{pregnant.healthUnit?.name}
									</td>
									<td
										title="dpp"
										className={`text-start text-nowrap ps-2 ${
											!columns.dppDate?.visible && "d-none"
										}`}
									>
										{pregnant.dppDate
											? dayjs(pregnant.dppDate).format("DD/MM/YYYY")
											: "N/A"}
									</td>
									<td
										title="DUM"
										className={`text-start text-nowrap ${
											!columns.firstDumDate?.visible && "d-none"
										}`}
									>
										{pregnant.firstDumDate
											? dayjs(pregnant.firstDumDate).format("DD/MM/YYYY")
											: "N/A"}
									</td>
									<td
										title="1ª IG"
										className={`text-start ${
											!columns.firstGestationalAge?.visible && "d-none"
										}`}
									>
										{pregnant.firstGestationalAge
											? `${pregnant.firstGestationalAge} semanas`
											: "N/A"}
									</td>
									<td
										title="Última IG"
										className={`text-start ${
											!columns.lastGestationalAge?.visible && "d-none"
										}`}
									>
										{pregnant.lastGestationalAge
											? `${pregnant.lastGestationalAge} semanas`
											: "N/A"}
									</td>
									<td
										title="Parto"
										className={`text-start ${
											!columns.childBirthDate?.visible && "d-none"
										}`}
									>
										{pregnant.childBirthDate
											? dayjs(pregnant.childBirthDate).format("DD/MM/YYYY")
											: "N/A"}
									</td>
									<td
										title="Tipo de parto"
										className={`${
											!columns.childBirthType?.visible && "d-none"
										}`}
									>
										{pregnant.childBirthType === "normal"
											? "Normal"
											: pregnant.childBirthType === "cesarean"
											? "Cesário"
											: pregnant.childBirthType === "abortion"
											? "Aborto"
											: pregnant.childBirthType === "normalstillbirth"
											? "Natimorto (normal)"
											: pregnant.childBirthType === "cesareanstillbirth"
											? "Natimorto (cesário)"
											: pregnant.childBirthType === "unspecified"
											? "Não especificado"
											: pregnant.childBirthType === "unregistered"
											? "Não registrado"
											: "N/A"}
									</td>
									<td
										title="Puerperio"
										className={`${
											!columns.puerperalStatus?.visible && "d-none"
										}`}
									>
										{pregnant.puerperalStatus === "finished" ? (
											<FaCheckCircle
												className="text-success fs-2"
												title="Concluido"
											/>
										) : pregnant.puerperalStatus === "pending" ? (
											<TbAlertCircleFilled
												className="text-warning fs-2"
												title="Pendente"
											/>
										) : pregnant.puerperalStatus === "lost" ? (
											<RiCloseCircleFill
												className="text-danger fs-2"
												title="Perdido"
											/>
										) : null}
									</td>
									<td
										title="Consultas"
										className={`${!columns.attendances?.visible && "d-none"}`}
									>
										{pregnant.attendances?.length}
									</td>
									<td className="no-print">
										<div className="d-flex justify-content-center gap-2 w-100">
											<Button
												title="Visualizar detalhamento"
												variant="primary"
												className="fs-8 px-5 py-2 ml-auto"
												onClick={() => {
													setSelectedPregnancy(pregnant);
													handleShowPregnantModal();
												}}
											>
												<FaEye />
											</Button>
										</div>
									</td>
								</tr>
						  ))
						: isLoading && (
								<TableSkeletons
									numberOfCells={
										Object.entries(columns).filter(
											(a) => a[1]?.visible === true
										).length + 1
									}
									height={20}
									numberOfRows={10}
								/>
						  )}
				</tbody>
			</Table>
		</div>
	);
}
