import { Vaccination } from "@/@types/Vaccination";
import { get } from "../api";
import { Paginated } from "@/@types/paginated";
import { Citizen } from "@/@types/Citizen";

export type TripleViralResumeType = {
	identified: number;
	finished: number;
	pending: number;
	delayed: number;
	abandoned: number;
	lost: number;
	totalD1: number;
	totalD2: number;
	totalDU: number;
};

export type CitizenVaccinations = {
	citizen: Citizen & {
		area?: string;
		phone?: string;
	};
	statusD1: boolean;
	statusD2: boolean;
	statusDU: boolean;
	vaccinationStatus: "finished" | "pending" | "delayed" | "abandoned" | "lost";
	vaccinations: Vaccination[];
};

type VaccinationHistoryParams = {
	uf: string;
	ibgeCode: string;
	citizenExtraId: number;
};

type VaccinationParams = {
	uf: string;
	ibgeCode: string;
	startDate: string;
	endDate: string;
	cnes: string;
	ine: string;
};

type VaccinationPaginatedParams = {
	uf: string;
	ibgeCode: string;
	startDate: string;
	endDate: string;
	cnes: string;
	ine: string;
	fullName: string;
	vaccinationStatus: string;
	pageNumber: number;
	pageSize?: number;
};

export async function fetchVaccinationHistory(vaccinationHistoryParams: VaccinationHistoryParams) {
	const response = await get<Vaccination[]>(
		`/Vaccination/v1/VaccinationHistory`,
		vaccinationHistoryParams
	);
	return response.data || [];
}

export async function fetchTripleViralResume(vaccinarionParams: VaccinationParams) {
	const response = await get<TripleViralResumeType>(
		`/vaccination/v1/ChildTripleViralResume`,
		vaccinarionParams
	);
	return response.data || ({} as TripleViralResumeType);
}

export async function fetchTripleViralCitizensVaccinationsPaginated(
	vaccinarionPaginatedParams: VaccinationPaginatedParams
) {
	const response = await get<Paginated<CitizenVaccinations>>(
		`/vaccination/v1/childtripleviralvaccinations`,
		vaccinarionPaginatedParams
	);
	return response.data || ({} as Paginated<CitizenVaccinations>);
}
