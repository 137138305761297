import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useFormik } from "formik";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { ImageFileFormData, registerBanner } from "@/services/app/storageService";

import { CardDropzone } from "@/components/CardDropzone";

type Props = ModalProps & {
	bannerType?: number;
	fetchBanners: () => Promise<void>;
};

export function BannerRegisterModal({ fetchBanners, bannerType, ...rest }: Props) {
	const [selectedPeriod, setSelectedPeriod] = useState("");
	const initialValues = { bannerTypeId: bannerType } as ImageFileFormData;

	const formik = useFormik<ImageFileFormData>({
		initialValues,
		onSubmit: handleRegisterBanner,
	});

	async function handleRegisterBanner(imageFileData: ImageFileFormData) {
		if (!formik.values.imageFile) return;
		const registerData = {
			bannerTypeId: bannerType,
			startDate:
				selectedPeriod === "permanent" ? dayjs().toString() : imageFileData.startDate,
			fileDescription: imageFileData.fileDescription,
			imageFile: imageFileData.imageFile,
			endDate: imageFileData.endDate,
		} as ImageFileFormData;
		const response = await registerBanner(registerData);
		if (response.status !== 200) {
			toast.error("Erro ao registrar arquivo");
		} else {
			toast.success("Arquivo registrado com sucesso.");
			fetchBanners();
			rest.onHide && rest.onHide();
		}
	}

	useEffect(() => {
		return () => {
			formik.setValues({} as ImageFileFormData);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Modal size="lg" scrollable {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Registrar Banner</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
					<Row className="mb-2 px-10">
						<Form.Label>Período</Form.Label>
						<Col lg={6} className="d-flex gap-5">
							<Form.Check
								inline
								label="Permanente"
								className="text-nowrap"
								name="option"
								type="radio"
								id="permanentRadio"
								onChange={() => setSelectedPeriod("permanent")}
								checked={selectedPeriod === "permanent"}
							/>
							<Form.Check
								inline
								label="Temporário"
								className="text-nowrap"
								name="option"
								type="radio"
								id="temporaryRadio"
								onChange={() => setSelectedPeriod("temporary")}
								checked={selectedPeriod === "temporary"}
							/>
						</Col>
					</Row>
					{selectedPeriod === "temporary" && (
						<Row className="px-10">
							<Col sm={12} md={6} lg={6} xl={6} className="mb-2">
								<Form.Label>Data Inicial</Form.Label>
								<Form.Control
									type="date"
									name="startDate"
									value={formik.values.startDate}
									onChange={formik.handleChange}
									required
								/>
							</Col>
							<Col sm={12} md={6} lg={6} xl={6}>
								<Form.Label>Data Final</Form.Label>
								<Form.Control
									type="date"
									name="endDate"
									value={formik.values.endDate}
									onChange={formik.handleChange}
									required
								/>
							</Col>
						</Row>
					)}
					<Row className="px-10">
						<Col sm={12} md={12} lg={12} xl={12}>
							<Form.Label className="fs-6">Titulo:</Form.Label>
							<Form.Control
								type="text"
								name="fileDescription"
								value={formik.values.fileDescription}
								onChange={formik.handleChange}
							/>
						</Col>
					</Row>
					<CardDropzone
						inputName="imageFile"
						description="Solte o arquivo de banner aqui, para fazer o upload."
						acceptedFileType={{ "image/*": [".jpeg", ".png"] }}
						descriptionAcceptedFilesExtension="Upload somente de arquivo .jpg/.png (tamanho máximo: 10MB)"
						handleChange={(file: File) => formik.setFieldValue("imageFile", file)}
					/>
					<Row>
						<Col
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className="d-flex justify-content-center"
						>
							<Button type="submit">Upload</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
