import { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { FaImages } from "react-icons/fa";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { BannerTypeEnum } from "@/utils/enums/BannerTypeEnum";

import { BannersModal } from "./BannersModal";

export function StorageManagement() {
	const { setTitle } = useLayout();
	const [selectedBannerType, setSelectedBannerType] = useState<number>(0);
	const [showBannersModal, setShowBannersModal] = useState(false);

	useEffect(() => {
		setTitle("Gestão de Banners");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2 h-100">
			<BannersModal
				show={showBannersModal}
				onHide={() => setShowBannersModal(false)}
				bannerType={selectedBannerType}
			/>
			<Card className="bg-white h-100 w-100">
				<Row>
					{Object.entries(BannerTypeEnum).map(
						([key, value], index) =>
							typeof value === "string" && (
								<Col
									key={index}
									sm={2}
									md={2}
									lg={2}
									xl={2}
									className="d-flex flex-column justify-content-center align-items-center ms-2"
									onClick={() => {
										setSelectedBannerType(Number(key));
										setShowBannersModal(true);
									}}
								>
									<FaImages size={80} className="cursor-pointer text-warning" />
									<label className="cursor-pointer">{value}</label>
								</Col>
							)
					)}
				</Row>
			</Card>
		</div>
	);
}
