import { FaBaby } from "react-icons/fa";

export function PregnancyDashboardChildBirthHeader() {
	return (
		<section className="d-flex align-items-center">
			<label className="bg-primary text-secondary py-2 px-4 rounded fs-2 text-center">
				<FaBaby />
			</label>
			<h1 className="text-primary rounded ms-2 my-0">Acompanhamento do parto</h1>
		</section>
	);
}
