import dayjs from "dayjs";

import { SelectOptionsProps } from "@/@types/genericals";

type Props = {
	title: string;
	quarter: SelectOptionsProps;
	healthUnit: SelectOptionsProps;
	healthTeam: SelectOptionsProps;
};

export function ReportIndicator7({ title, quarter, healthUnit, healthTeam }: Props) {
	return (
		<div>
			<h3 className="text-center py-10 px-5 fs-7 text-uppercase">{title}</h3>
			<div className="px-5">
				<section className="w-100 d-flex flex-stack align-items-end">
					<article className="d-flex flex-column">
						<span>
							<strong>Quadrimestre:</strong> {quarter?.label}
						</span>
						<span>
							<strong>Unidade:</strong>{" "}
							{healthUnit ? healthUnit?.label : "Todas as unidades"}
						</span>
						<span>
							<strong>Equipe:</strong>
							{healthTeam
								? `${healthTeam?.value}-${healthTeam?.label}`
								: "Todas as equipes"}
						</span>
					</article>
					<article className="small">
						Emitido em: {dayjs()?.format("DD/MM/YYYY hh:mm:ss")}
					</article>
				</section>
			</div>
		</div>
	);
}
