import { Carousel } from "react-responsive-carousel";

import { CarouselImageProps } from "@/pages/InstitutionalPlatform/CarouselImages";
import "react-responsive-carousel/lib/styles/carousel.min.css";

type Props = {
	carouselImages: CarouselImageProps[];
	style?: React.CSSProperties;
	className?: string;
};

export function CarouselBanner({ carouselImages, style, className }: Props) {
	return (
		<Carousel
			className="z-1 position-relative"
			interval={3000}
			infiniteLoop
			autoPlay
			showStatus={false}
			showThumbs={false}
			animationHandler={"fade"}
		>
			{carouselImages.map((item, index) => (
				<div key={`carousel-item-index-${index}`}>
					<img
						alt="banner"
						className={className || "w-100"}
						src={item.url}
						style={{ minWidth: "300px", ...style }}
					/>
				</div>
			))}
		</Carousel>
	);
}
