import { Account } from "@/@types/Account";
import { get } from "../api";

export type ResumeCitieEpidemic = {
	city: Account;
	total: number;
};

export type ResumeDiseasesChronicNotTransmissibleOfCities = {
	city: Account;
	totalDiabetics: number;
	totalAttendancesCitizensWithDiabetics: number;
	totalHypertension: number;
	totalAttendancesCitizensWithHypertension: number;
};

export type ResumeDiseasesChronicTransmissibleOfCities = {
	city: Account;
	totalHansen: number;
	totalAttendancesCitizensWithHansen: number;
	totalTuberculosis: number;
	totalAttendancesCitizensWithTuberculosis: number;
};

type DiseasesChronicParams = {
	uf: string;
	startDate: string;
	endDate: string;
};

export async function fetchResumeDiseasesChronicTransmissibleOfCities(
	diseasesParams: DiseasesChronicParams
) {
	const response = await get<ResumeDiseasesChronicTransmissibleOfCities[]>(
		`Epidemic/v1/GetResumeDiseasesChronicTransmissibleOfCities`,
		diseasesParams
	);
	return response.data || [];
}

export async function fetchResumeDiseasesChronicNotTransmissibleOfCities(
	diseasesParams: DiseasesChronicParams
) {
	const response = await get<ResumeDiseasesChronicNotTransmissibleOfCities[]>(
		`Epidemic/v1/GetResumeDiseasesChronicNotTransmissibleOfCities`,
		diseasesParams
	);
	return response.data || [];
}
