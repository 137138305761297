import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsGraphUp } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useLayout } from "@/../_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { TScheduleStatusEnum } from "@/utils/enums/TScheduleStatusEnum";
import {
	AttendancePerformanceDashboard,
	handleAttendancePerformanceDashboard,
} from "@/services/esus/attendanceService";

import { LoadingScreen } from "@/components/LoadingScreen";
import { ProfessionalAttendanceResume } from "./ProfessionalAttendanceResume";
import { ProfessionalAttendanceFilters } from "./ProfessionalAttendanceFilters";
import { AttendanceProcedureDetails } from "./AttendanceProcedureDetails";
import { CitizenProfileAttendanceDetails } from "./CitizenProfileAttendanceDetails";
import { ProfessionalAttendanceDetails } from "./ProfessionalAttendanceDetails";
import { AttendanceReferralDetails } from "./AttendanceReferralDetails";

export function ProfessionalAttendanceDashboard() {
	const { uf, ibgeCode } = useCurrentAccount();
	const { setTitle } = useLayout();
	const [startDate, setStartDate] = useState(dayjs().subtract(2, "months").format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
	const [selectedCnes, setSelectedCnes] = useState("");
	const [selectedIne, setSelectedIne] = useState("");
	const [selectedCns, setSelectedCns] = useState("");
	const [isLoading, setIsloading] = useState(false);
	const [attendancePerformanceDashboard, setAttendancePerformanceDashboard] =
		useState<AttendancePerformanceDashboard>({} as AttendancePerformanceDashboard);

	async function handleDashboard() {
		return await handleAttendancePerformanceDashboard({
			uf,
			ibgeCode,
			cnes: selectedCnes,
			ine: selectedIne,
			cns: selectedCns,
			startDate: startDate,
			endDate: endDate,
		});
	}

	async function fetchDashboard() {
		setIsloading(true);
		setAttendancePerformanceDashboard(
			await handleDashboard()
		);
		setIsloading(false);
	}

	const periodsSeries =
		attendancePerformanceDashboard.attendancesAggregatedByPeriodAndProfessionalCategory?.map(
			(aggregatedAttendances) => aggregatedAttendances.total
		) || [];
	const scheduleStatusAggregated =
		attendancePerformanceDashboard.schedulesAggregatedByPeriodAndScheduleStatus?.flatMap(
			(aggregatedSchedule) => aggregatedSchedule.values || []
		) || [];

	const scheduleResume = {
		totalCanceled: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.CANCELED.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalExcluded: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.EXCLUDED.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalNotShowUp: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier ===
						TScheduleStatusEnum.DID_NOT_SHOW_UP.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalNotWait: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.DID_NOT_WAIT.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalAttended: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.ATTENDED.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
	};

	const resume = {
		totalAttendances: periodsSeries.reduce((acc, curr) => acc + curr, 0),
		totalAttendedWithSchedule: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.ATTENDED
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalNotAttendedWithSchedule: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier !== TScheduleStatusEnum.ATTENDED
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalAbsence: scheduleResume.totalNotShowUp + scheduleResume.totalNotWait,
		totalDropout: scheduleResume.totalExcluded + scheduleResume.totalCanceled,
	};

	async function handleFilters() {
		await fetchDashboard();
	}

	useEffect(() => {
		setTitle("RELATÓRIO DE ATENDIMENTOS");
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<LoadingScreen
				loading={isLoading}
				messages={[
					"Já estamos processando a consulta do periodo.",
					"aguarde alguns instantes.",
					"Estamos quase lá!",
				]}
			/>
			<Row className="p-2">
				<Col>
					<Card className="h-100">
						<Card.Body>
							<ProfessionalAttendanceFilters
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate}
								setCnes={setSelectedCnes}
								setIne={setSelectedIne}
								setCns={setSelectedCns}
								handleFilters={handleFilters}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col>
					<Card>
						<Card.Body className="p-4">
							<section className="d-flex align-items-center">
								<label className="bg-primary text-secondary py-2 px-4 rounded fs-2 text-center">
									<BsGraphUp />
								</label>
								<h1 className="text-primary rounded ms-2 my-0">
									Relatório de produção
								</h1>
							</section>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col>
					<ProfessionalAttendanceResume
						totalAttendance={resume.totalAttendances}
						totalAttendedWithSchedule={resume.totalAttendedWithSchedule}
						totalAttendedWithoutSchedule={
							resume.totalAttendances - resume.totalAttendedWithSchedule > 0 ? resume.totalAttendances - resume.totalAttendedWithSchedule : 0
						}
						totalAbsence={resume.totalAbsence}
					/>
				</Col>
			</Row>
			<ProfessionalAttendanceDetails
				attendancesAggregatedByHealthTeamAndProfessionalCategory={
					attendancePerformanceDashboard.attendancesAggregatedByHealthTeamAndProfessionalCategory ||
					[]
				}
				attendancesAggregatedByHealthUnitAndProfessionalCategory={
					attendancePerformanceDashboard.attendancesAggregatedByHealthUnitAndProfessionalCategory ||
					[]
				}
				attendancesAggregatedByPeriodAndProfessionalCategory={
					attendancePerformanceDashboard.attendancesAggregatedByPeriodAndProfessionalCategory ||
					[]
				}
				attendancesAggregatedByProfessionalCategoryAndProfessional={
					attendancePerformanceDashboard.attendancesAggregatedByProfessionalCategoryAndProfessional ||
					[]
				}
				schedulesAggregatedByPeriodAndScheduleStatus={
					attendancePerformanceDashboard.schedulesAggregatedByPeriodAndScheduleStatus ||
					[]
				}
			/>
			<CitizenProfileAttendanceDetails
				attendancesAggregatedByAgeAndGender={
					attendancePerformanceDashboard.attendancesAggregatedByAgeAndGender || []
				}
				attendancesAggregatedByShift={
					attendancePerformanceDashboard.attendancesAggregatedByShift || []
				}
				problemsAggregatedByCiapAndProfessionalCategory={
					attendancePerformanceDashboard.problemsAggregatedByCiapAndProfessionalCategory ||
					[]
				}
				problemsAggregatedByCidAndProfessionalCategory={
					attendancePerformanceDashboard.problemsAggregatedByCidAndProfessionalCategory ||
					[]
				}
			/>
			<Row className="p-2">
				<Col>
					<Card>
						<Card.Body className="p-4">
							<section className="d-flex align-items-center">
								<label className="bg-primary text-secondary py-2 px-4 rounded fs-2 text-center">
									<BsGraphUp />
								</label>
								<h1 className="text-primary rounded ms-2 my-0">Procedimentos</h1>
							</section>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<AttendanceProcedureDetails
				proceduresAggregatedByPeriodAndProfessionalCategory={
					attendancePerformanceDashboard.attendanceProceduresAggregatedByPeriodAndProfessionalCategory ||
					[]
				}
				proceduresAggregatedByProcedureAndProfessionalCategory={
					attendancePerformanceDashboard.attendanceProceduresAggregatedByProcedureAndProfessionalCategory ||
					[]
				}
			/>
			<Row className="p-2">
				<Col>
					<Card>
						<Card.Body className="p-4">
							<section className="d-flex align-items-center">
								<label className="bg-primary text-secondary py-2 px-4 rounded fs-2 text-center">
									<BsGraphUp />
								</label>
								<h1 className="text-primary rounded ms-2 my-0">Encaminhamentos</h1>
							</section>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<AttendanceReferralDetails
				attendanceReferralsAggregatedByPeriodAndProfessionalCategory={
					attendancePerformanceDashboard.attendanceReferralsAggregatedByPeriodAndProfessionalCategory ||
					[]
				}
				attendanceReferralsAggregatedByCidAndProfessionalCategory={
					attendancePerformanceDashboard.attendanceReferralsAggregatedByCidAndProfessionalCategory ||
					[]
				}
				attendanceReferralsAggregatedBySpecialty={
					attendancePerformanceDashboard.attendanceReferralsAggregatedBySpecialty || []
				}
			/>
		</div>
	);
}
