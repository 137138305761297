import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { BasePatient } from "@/@types/BasePatient";

import { TimeLine } from "@/components/TimeLine";

type Props = ModalProps & {
	patientSelected: BasePatient;
};

export function ModalIndicator6({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-4 text-primary">Paciente: </span>
							<span>{patientSelected?.fullName}</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-4 text-primary">DN: </span>
							<span>
								{patientSelected.birthdate
									? dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={4} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">IDADE: </span>
							<span>{patientSelected.age}</span>
						</Col>
					</Row>
				</section>
				<TimeLine
					timeLineTitle="ATENDIMENTOS"
					timeLineContents={patientSelected.attendances?.map((attendance, index) => {
						return {
							content: (
								<section key={index}>
									<article>
										<p>
											<strong className="text-primary me-2">Equipe:</strong>
											{patientSelected.healthTeam?.ine
												? `${patientSelected.healthTeam?.ine}-${patientSelected.healthTeam?.name}`
												: "N/A"}
										</p>
									</article>
									<article>
										<p>
											<strong className="text-primary me-2">Cid:</strong>
											<label>{`${attendance.problems[0]?.cid?.cidCode} - ${attendance.problems[0]?.cid?.description}`}</label>
										</p>
										<p>
											<strong className="text-primary me-2">Ciap:</strong>
											<label>{`${attendance.problems[0]?.ciap?.ciapCode} - ${attendance.problems[0]?.ciap?.description}`}</label>
										</p>
									</article>
									<article>
										<strong className="text-primary">1º Profissional: </strong>
										<p>
											<strong className="me-2">Nome:</strong>
											{attendance.problems[0]?.professional1?.name || "N/A"}
										</p>
										<p>
											<strong className="me-2">Categoria:</strong>
											{attendance.problems[0]?.cbo1?.description || "N/A"}
										</p>
									</article>
								</section>
							),
							eventDate: attendance?.date
								? dayjs(attendance.date).format("DD/MM/YYYY")
								: "N/A",
						};
					})}
				/>

				<TimeLine
					timeLineTitle="PROCEDIMENTOS"
					timeLineContents={patientSelected.attendanceProcedures?.map(
						(attendance, index) => {
							return {
								content: (
									<section key={index}>
										<article>
											<p>
												<strong className="text-primary me-2">
													Equipe:
												</strong>
												{patientSelected.healthTeam?.ine
													? `${patientSelected.healthTeam?.ine}-${patientSelected.healthTeam?.name}`
													: "N/A"}
											</p>
										</article>
										<article>
											<p>
												<strong className="text-primary me-2">
													Código/Procedimento:
												</strong>
												<label>{`${attendance.procedure?.procedureCode} - ${attendance.procedure?.name}`}</label>
											</p>
										</article>
										<article>
											<strong className="text-primary">
												1º Profissional:{" "}
											</strong>
											<p>
												<strong className="me-2">Nome:</strong>
												{attendance.professional?.name || "N/A"}
											</p>
											<p>
												<strong className="me-2">Categoria:</strong>
												{attendance.cbo?.description || "N/A"}
											</p>
										</article>
									</section>
								),
								eventDate: attendance.date
									? dayjs(attendance.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>
			</Modal.Body>
		</Modal>
	);
}
