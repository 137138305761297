import FormControl from "react-bootstrap/FormControl";
import { FaSearch } from "react-icons/fa";

import "./styles.css";

type Props = {
	value: string;
	placeholder: string;
	setValue: (search: string) => void;
};

export function SearchInput({ value, placeholder, setValue }: Props) {
	return (
		<div className="d-flex align-items-center">
			<FaSearch className="position-absolute search-input-icon text-muted" />

			<FormControl
				className="form-control search-input ps-10"
				placeholder={placeholder}
				value={value}
				onChange={(event) => setValue(event.target.value)}
				style={{ minWidth: "14rem" }}
			/>
		</div>
	);
}
