import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { DAYS_OF_WEEK, MONTHS, SHORT_DAYS_OF_WEEK, SHORT_MONTHS } from "@/utils/enums/DateEnum";

type Props = {
	title?: string;
	heigth?: number;
	width?: number;
	series?: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
	categories?: string[];
	apexOptions?: ApexOptions;
};

export function AreaChart({ title, series, categories, heigth, width, apexOptions }: Props) {
	const areaChartOptions: ApexOptions = apexOptions || {
		chart: {
			type: "area",
			height: 350,
			width: width || "100%",
			zoom: {
				enabled: false,
			},
			locales: [
				{
					name: "pt",
					options: {
						months: MONTHS,
						shortMonths: SHORT_MONTHS,
						days: DAYS_OF_WEEK,
						shortDays: SHORT_DAYS_OF_WEEK,
					},
				},
			],
			defaultLocale: "pt",
		},
		dataLabels: {
			enabled: true,
		},
		markers: { size: 5 },
		stroke: {
			curve: "smooth",
		},

		title: {
			text: title,
			align: "left",
		},
		xaxis: {
			type: "category",
			categories: categories,
		},
		tooltip: {
			x: {
				format: "yyyy/MM",
			},
		},
		yaxis: {
			opposite: false,
		},
		legend: {
			horizontalAlign: "left",
		},
	};
	return (
		<ReactApexChart
			options={areaChartOptions}
			series={series}
			type="area"
			height={heigth || 350}
		/>
	);
}
