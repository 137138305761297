import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import pregnantNormalImage from "@/../_metronic/assets/images/pregnant/pregnant-normal.svg";
import pregnantCesareanImage from "@/../_metronic/assets/images/pregnant/pregnant-cesarean.svg";
import pregnantAbortImage from "@/../_metronic/assets/images/pregnant/pregnant-abort.svg";

export type ChildBirthTypeResume = {
	totalNormal: number;
	totalCesarean: number;
	totalNormalStillBirth: number;
	totalCesareanStillBirth: number;
	totalAbortion: number;
	totalUnspecified: number;
	totalUnregistered: number;
};

type Props = {
	childBirthTypeResume: ChildBirthTypeResume;
};

export function PregnancyDashboardChildBirthResume({ childBirthTypeResume }: Props) {
	return (
		<Row>
			<Col sm={12} md={6} lg={4} xl={4} className="mb-2">
				<Card className="py-0">
					<Card.Body className="py-0">
						<div className="d-flex justify-content-between py-4">
							<div className="d-flex flex-column justify-content-between">
								<h3 className="fw-bolder">Partos normais: </h3>
								<label className="fw-bolder" style={{ fontSize: 32 }}>
									{(
										childBirthTypeResume.totalNormal +
										childBirthTypeResume.totalNormalStillBirth
									).toLocaleString()}
								</label>
							</div>
							<div style={{ position: "absolute", right: 0, bottom: 0 }}>
								<img height={100} src={pregnantNormalImage} alt="Partos normais" />
							</div>
						</div>
					</Card.Body>
				</Card>
			</Col>
			<Col sm={12} md={6} lg={4} xl={4} className="mb-2">
				<Card className="py-0">
					<Card.Body className="py-0">
						<div className="d-flex justify-content-between py-4">
							<div className="d-flex flex-column justify-content-between">
								<h3 className="fw-bolder">Partos Cesarianos: </h3>
								<label className="fw-bolder" style={{ fontSize: 32 }}>
									{(
										childBirthTypeResume.totalCesarean +
										childBirthTypeResume.totalCesareanStillBirth
									).toLocaleString()}
								</label>
							</div>
							<div style={{ position: "absolute", right: 0, bottom: 0 }}>
								<img
									height={100}
									src={pregnantCesareanImage}
									alt="Partos cesarianos"
								/>
							</div>
						</div>
					</Card.Body>
				</Card>
			</Col>
			<Col sm={12} md={6} lg={4} xl={4} className="mb-2">
				<Card className="py-0">
					<Card.Body className="py-0">
						<div className="d-flex justify-content-between py-4">
							<div className="d-flex flex-column justify-content-between">
								<h3 className="fw-bolder">Abortos: </h3>
								<label className="fw-bolder" style={{ fontSize: 32 }}>
									{childBirthTypeResume.totalAbortion.toLocaleString()}
								</label>
							</div>
							<div style={{ position: "absolute", right: 0, bottom: 0 }}>
								<img height={100} src={pregnantAbortImage} alt="Abortos" />
							</div>
						</div>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}
