import { useState } from "react";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ResumeGrouped } from "@/services/esus/attendanceService";
import { Specialty } from "@/@types/Specialty";

import { AttendanceReferralRateBySpecialtyTableFilters } from "../AttendanceReferralRateBySpecialtyTableFilters";
import { AttendanceReferralRateBySpecialtyTable } from "../AttendanceReferralRateBySpecialtyTable";

type Props = ModalProps & {
	aggregatedAttendanceReferrals: ResumeGrouped<Specialty, object>[];
};

export function AttendanceReferralRateBySpecialtyModal({
	aggregatedAttendanceReferrals,
	...rest
}: Props) {
	const [specialtyDescription, setSpecialtyDescription] = useState("");
	const aggregatedAttendanceReferralsFiltered = aggregatedAttendanceReferrals.filter(
		(aggregatedAttendanceReferral) =>
			aggregatedAttendanceReferral.key.description
				?.toUpperCase()
				.includes(specialtyDescription.toUpperCase())
	);

	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">
						Ranking de encaminhamentos por especialidade
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="mb-8">
					<Col>
						<AttendanceReferralRateBySpecialtyTableFilters
							specialtyDescription={specialtyDescription}
							setSpecialtyDescription={setSpecialtyDescription}
						/>
					</Col>
				</Row>
				<AttendanceReferralRateBySpecialtyTable
					aggregatedAttendanceReferrals={aggregatedAttendanceReferralsFiltered}
				/>
			</Modal.Body>
		</Modal>
	);
}
