import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Select from "react-select";
import { FaDatabase } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";
import { RiFileExcel2Fill } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import { useLayout } from "@/../_metronic/layout/core";
import { useUnits } from "@/hooks/useUnits";
import { useProfessionals } from "@/hooks/useProfessionals";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useDebounce } from "@/hooks/useDebounce";
import { fetchCiaps } from "@/services/esus/ciapService";
import { fetchCids } from "@/services/esus/cidService";
import { fetchProblemPaginated } from "@/services/esus/problemService";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { exportExcel } from "@/utils/exports";
import { Paginated } from "@/@types/paginated";
import { GenericObject, SelectOptionsProps } from "@/@types/genericals";
import { Option } from "@/@types/Option";
import { Cid } from "@/@types/Cid";
import { Ciap } from "@/@types/Ciap";
import { Problem } from "@/@types/Problem";

import { TableSkeletons } from "@/components/TableSkeletons";
import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { UnitsSelect } from "@/components/UnitsSelect";

import { LoadingScreen } from "@/components/LoadingScreen";
import { GenderSelect } from "@/components/GenderSelect";
import { ProfessionalsSelect } from "@/components/ProfessionalsSelect";

import "./styles.scss";

export function AttendanceReport() {
	const { setTitle } = useLayout();
	const { professionals } = useProfessionals();
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const { units } = useUnits();
	const [isLoading, setIsloading] = useState(false);
	const [isloadingExport, setIsLoadingExport] = useState(false);
	const [isLoadingCids, setIsLoadingCids] = useState(false);
	const [isLoadingCiaps, setIsLoadingCiaps] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [cids, setCids] = useState<Cid[]>([]);
	const [ciaps, setCiaps] = useState<Ciap[]>([]);
	const [searchCid, setSearchCid] = useState("");
	const debouncedSearchCid = useDebounce(searchCid, 1500);
	const [searchCiap, setSearchCiap] = useState("");
	const debouncedSearchCiap = useDebounce(searchCiap, 1500);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState(
		dayjs().subtract(1, "month").format("YYYY-MM-DD")
	);
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState(
		dayjs().format("YYYY-MM-DD")
	);
	const [paginatedProblems, setPaginatedProblems] = useState<Paginated<Problem>>(
		{} as Paginated<Problem>
	);
	const [selectedOptionUnit, setSelectedOptionUnit] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);
	const [selectedOptionGender, setSelectedOptionGender] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);
	const [selectedOptionCid, setSelectedOptionCid] = useState<Option<string>>(
		{} as Option<string>
	);
	const [selectedOptionCiap, setSelectedOptionCiap] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);
	const [selectedOptionYoungerAge, setSelectedOptionYoungerAge] = useState<number>({} as number);
	const [selectedOptionOlderAge, setSelectedOptionOlderAge] = useState<number>({} as number);

	const genderOptions = [
		{ value: "", label: "Todos" },
		{ value: "m", label: "Masculino" },
		{ value: "f", label: "Feminino" },
		{ value: "i", label: "Ignorado" },
	];

	const ciapsOptions = [
		{ value: "", label: "TODOS OS CIAPS" },
		...ciaps?.map((ciap) => ({ value: ciap.ciapCode, label: ciap.description })),
	];

	const cidsOptions = [
		{ value: "", label: "TODOS OS CIDS" },
		...cids?.map((cid) => ({ value: cid.cidCode, label: cid.description })),
	];

	async function handleCids(search?: string) {
		if (!search || !ibgeCode) return;
		setIsLoadingCids(true);
		setCids(
			await fetchCids({
				uf,
				ibgeCode,
				search: searchCid,
			})
		);
		setIsLoadingCids(false);
		setSelectedOptionCiap({} as SelectOptionsProps);
	}

	async function handleCiaps(search?: string) {
		if (!search || !ibgeCode) return;
		setIsLoadingCiaps(true);
		setCiaps(await fetchCiaps({ uf, ibgeCode, search: searchCiap }));
		setIsLoadingCiaps(false);
	}

	async function fetchProblems(pageSize?: number) {
		if (!(selectedOptionStartDate && selectedOptionEndDate && ibgeCode)) {
			return {} as Paginated<Problem>;
		}
		return await fetchProblemPaginated({
			uf,
			ibgeCode,
			cnes: cnes || selectedOptionUnit.value,
			cids: [selectedOptionCid.value],
			ciaps: [selectedOptionCiap.value],
			youngerAge: selectedOptionYoungerAge,
			olderAge: selectedOptionOlderAge,
			startDate: selectedOptionStartDate,
			endDate: selectedOptionEndDate,
			gender: selectedOptionGender.value,
			pageNumber: pageNumber,
			pageSize: pageSize,
		});
	}

	async function fetch() {
		setIsloading(true);
		setPaginatedProblems(await fetchProblems());
		setIsloading(false);
	}

	async function handleExportTable() {
		setIsLoadingExport(true);
		const response = await fetchProblems(paginatedProblems?.totalRecords);
		setIsLoadingExport(false);

		if (Object.keys(response?.data || []).length) {
			const excelData: GenericObject[] = transformToExportExcel(response?.data);
			exportExcel(excelData, `Relatorio de atendimento`);
		}
	}

	function transformToExportExcel(data: Problem[]) {
		return data.map(
			(problem) =>
				({
					"Data do atendimento": problem.attendance?.date,
					Nome: problem.citizen?.fullName,
					"Data de Nascimento": dayjs(problem.citizen?.birthdate).format("DD/MM/YYYY"),
					Cpf: problem.citizen?.cpf,
					Cns: problem.citizen?.cns,
					profissional: problem.professional1?.name,
					cid: problem.cid?.cidCode,
					"cid-description": problem.cid?.description,
					ciap: problem.ciap?.ciapCode,
					"ciap-description": problem.ciap?.description,
				} as GenericObject)
		);
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	useEffect(() => {
		handleCiaps(searchCiap);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchCiap]);

	useEffect(() => {
		handleCids(searchCid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchCid]);

	useEffect(() => {
		if (selectedOptionStartDate && selectedOptionEndDate) {
			fetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		setTitle("RELATÓRIO DE ATENDIMENTOS CONSOLIDADOS");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="px-2 pb-2">
			<LoadingScreen loading={isloadingExport} />
			<Form
				className="py-2 bg-white"
				onSubmit={(event) => {
					event.preventDefault();
					fetch();
				}}
			>
				<Row className="mx-2 gap-2">
					<Col sm={5} md={4} lg={4} xl={4} className="p-0">
						<label>Período</label>
						<div className="d-flex gap-1">
							<InputGroup>
								<Form.Control
									type="date"
									className="fs-8 p-2"
									placeholder="Data inicial"
									defaultValue={dayjs().subtract(1, "month").format("YYYY-MM-DD")}
									min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
									max={dayjs().format("YYYY-MM-DD")}
									onChange={(event) =>
										setSelectedOptionStartDate(event.target.value)
									}
									required
								/>
							</InputGroup>
							<InputGroup>
								<Form.Control
									type="date"
									className="fs-8 p-2"
									placeholder="data final"
									defaultValue={dayjs().format("YYYY-MM-DD")}
									min={selectedOptionStartDate}
									max={dayjs().format("YYYY-MM-DD")}
									onChange={(event) =>
										setSelectedOptionEndDate(event.target.value)
									}
									required
								/>
							</InputGroup>
						</div>
					</Col>
					<Col sm={3} md={4} lg={4} xl={3} className="p-0">
						<UnitsSelect
							units={units}
							onChange={(event) => setSelectedOptionUnit(event as Option<string>)}
							defaultValue={{ value: "", label: "selecione..." }}
							noOptionsMessage={() => "Nenhuma unidade encontrada"}
						/>
					</Col>
					<Col sm={3} md={3} lg={3} xl={2} className="p-0">
						<ProfessionalsSelect professionals={professionals} />
					</Col>
					<Col sm={3} md={3} lg={3} xl={2} className="p-0">
						<GenderSelect
							name="gender"
							options={genderOptions}
							onChange={(event) => setSelectedOptionGender(event as Option<string>)}
							defaultValue={{ value: "", label: "selecione..." }}
						/>
					</Col>
					<Col sm={2} md={2} lg={2} xl={2} className="p-0">
						<label>Faixa etária:</label>
						<InputGroup className="gap-1">
							<Form.Control
								type="number"
								className="fs-7 p-2"
								placeholder="mín"
								onChange={(event) =>
									setSelectedOptionYoungerAge(Number(event.target.value))
								}
							/>
							<Form.Control
								type="number"
								className="fs-7 p-2"
								placeholder="max"
								onChange={(event) =>
									setSelectedOptionOlderAge(Number(event.target.value))
								}
							/>
						</InputGroup>
					</Col>
					<Col sm={3} md={3} lg={3} xl={3} className="d-flex align-items-end p-0">
						<InputGroup>
							<label>CIAP</label>
							<Select
								className="w-100 fs-8"
								name="ciap"
								isLoading={isLoadingCiaps}
								inputValue={searchCiap}
								onInputChange={(value) => setSearchCiap(value)}
								options={ciapsOptions}
								onChange={(event) => setSelectedOptionCiap(event as Option<string>)}
								defaultValue={{ value: "", label: "Pesquisar CIAP" }}
								noOptionsMessage={() => "nenhum resultado encontrado na pesquisa"}
								styles={{
									menu: (base) => ({ ...base, zIndex: 90 }),
									control: (base) => ({
										...base,
										minHeight: "30px",
										padding: "0px",
									}),
									valueContainer: (base) => ({
										...base,
										padding: "0px 4px",
									}),
									indicatorsContainer: (base) => ({
										...base,
										height: "30px",
									}),
								}}
							/>
						</InputGroup>
					</Col>

					<Col sm={3} md={3} lg={3} xl={3} className="d-flex align-items-end p-0">
						<InputGroup>
							<label>CID</label>
							<Select
								className="w-100 fs-8"
								name="cid"
								isLoading={isLoadingCids}
								inputValue={searchCid}
								options={cidsOptions}
								onInputChange={(value) => setSearchCid(value)}
								onChange={(event) => setSelectedOptionCid(event as Option<string>)}
								defaultValue={{ value: "", label: "Pesquisar CID" }}
								noOptionsMessage={() => "nenhum resultado encontrado na pesquisa"}
								styles={{
									menu: (base) => ({ ...base, zIndex: 90 }),
									control: (base) => ({
										...base,
										minHeight: "30px",
										padding: "0px",
									}),
									valueContainer: (base) => ({
										...base,
										padding: "0px 4px",
									}),
									indicatorsContainer: (base) => ({
										...base,
										height: "30px",
									}),
								}}
							/>
						</InputGroup>
					</Col>
					<Col
						sm={4}
						md={3}
						lg={3}
						xl={3}
						className="d-flex align-items-end justify-content-center gap-2"
					>
						<Button
							className="fs-8 p-3 text-nowrap w-100"
							variant="secondary"
							onClick={() =>
								paginatedProblems?.data &&
								paginatedProblems.data.length > 0 &&
								handleExportTable()
							}
						>
							<RiFileExcel2Fill />
							Exportar
						</Button>
						<Button type="submit" className="fs-8 p-3 text-nowrap w-100">
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</Col>
				</Row>
			</Form>
			<section className="d-flex align-items-end bg-white p-2 my-2 rounded ">
				<TbReportSearch size={30} className="bg-primary text-white rounded p-1 me-2" />
				<h1 className=" text-primary">Tabela relacionada</h1>
			</section>
			<Card className="bg-white">
				<Card.Body className="p-2">
					<Table className="bg-white" striped bordered hover responsive>
						<thead className="text-center fs-7">
							<tr className="fw-bolder">
								<th className="px-2">Atendimento</th>
								<th>Nome cidadão</th>
								<th className="text-nowrap">CPF</th>
								<th>DN</th>
								<th>Profissional</th>
								<th>CID</th>
								<th>CIAP</th>
							</tr>
						</thead>
						<tbody className="fs-8">
							{paginatedProblems?.data && !isLoading
								? paginatedProblems?.data?.map((problem, index) => (
										<tr key={`item-epidemic-data-${index}`}>
											<td className="px-2">
												{problem.attendance?.date &&
													dayjs(problem.attendance?.date).format(
														"DD/MM/YYYY"
													)}
											</td>
											<td>{problem.citizen?.fullName}</td>
											<td className="text-nowrap">
												{problem.citizen?.cpf &&
													formatIdentifier(problem.citizen?.cpf)}
											</td>
											<td>
												{problem.citizen?.birthdate &&
													dayjs(problem.citizen?.birthdate).format(
														"DD/MM/YYYY"
													)}
											</td>
											<td>{problem.attendance?.professional1?.name}</td>
											<td>{problem.cid?.description}</td>
											<td className="pe-3">{problem.ciap?.description}</td>
										</tr>
								  ))
								: isLoading && <TableSkeletons numberOfCells={7} />}
						</tbody>
					</Table>
				</Card.Body>
				<Card.Footer>
					{paginatedProblems?.totalRecords ? (
						<PaginationLinks
							itemsPerPage={paginatedProblems.pageSize}
							totalPages={paginatedProblems.totalRecords}
							changeSelectedPage={handleChangePageNumber}
							pageNumber={pageNumber || 0}
						/>
					) : (
						!isLoading && (
							<NoRecordsFeedback
								message={"Nenhum resultado encontrado."}
								icon={<FaDatabase />}
							/>
						)
					)}
				</Card.Footer>
			</Card>
		</div>
	);
}
