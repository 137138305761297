import { BsFillHouseCheckFill } from "react-icons/bs";
import { MdFamilyRestroom } from "react-icons/md";
import { FaInfoCircle, FaLandmark, FaMapMarkedAlt, FaMapMarkerAlt, FaUsers } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { CardInformative } from "@/pages/ReterritorializationAcsDashboard/CardInformative";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";

type Props = {
	totalCitizens: number;
	totalAcs: number;
	totalRegistrationMc: number;
	totalRegistraionFa: number;
	totalFamily: number;
	totalResidences: number;
	totalOtherBuildings: number;
};

export function GlobalInformationVisits({
	totalCitizens,
	totalAcs,
	totalFamily,
	totalOtherBuildings,
	totalRegistraionFa,
	totalRegistrationMc,
	totalResidences,
}: Props) {
	return (
		<Row className="mx-3 bg-white gap-1 mt-2 justify-content-evenly rounded">
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={1}
				className="border border-muted card-information-acs bg-white rounded p-2 my-2"
			>
				<CardInformative
					title="Total de cidadãos"
					classNameValue="text-muted"
					classNameTitle="text-muted"
					value={totalCitizens ? totalCitizens.toLocaleString() : 0}
					icon={<FaUsers className="fs-2 text-muted" />}
					metaData={
						<CardOverlayTrigger
							header="Total de cidadãos"
							message="Quantidade de cidadãos identificados"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>

			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs bg-info rounded p-2 my-2"
			>
				<CardInformative
					title="Nº de Profissionais"
					classNameValue="text-white"
					classNameTitle="text-white"
					value={totalAcs}
					icon={<FaUsers className="fs-2 text-white" />}
					metaData={
						<CardOverlayTrigger
							header="Nº de Profissionais"
							message="Quantidade de profissionais que realizaram cadastro individual no período selecionado (Não necessariamente ACS)."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs rounded p-2 my-2"
				style={{ backgroundColor: "#F1416C" }}
			>
				<CardInformative
					title="Cadastro ACS/MC"
					value={totalRegistrationMc}
					icon={<FaMapMarkedAlt className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Cadastro ACS/MC"
							message="Quantidade de cadastros realizados pelos ACS,s ativos  dentro das microáreas(MC) no período selecionado e de acordo com o filtro utilizado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs rounded p-2 my-2"
				style={{ backgroundColor: "#FFD52F" }}
			>
				<CardInformative
					title="Cadastro ACS/FA"
					value={totalRegistraionFa}
					icon={<FaMapMarkerAlt className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Cadastro ACS/FA"
							message="Quantidade de cadastros realizados pelos ACS,s ativos fora de área (FA) no período selecionado e de acordo com o filtro utilizado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs rounded p-2 my-2"
				style={{ backgroundColor: "#0C6FD1" }}
			>
				<CardInformative
					title="Nº de Familias"
					value={totalFamily}
					icon={<MdFamilyRestroom className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Nº de familias"
							message="Quantidade de famílias cadastradas no período selecionado e de acordo com o filtro selecionado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs bg-success rounded p-2 my-2"
				style={{ backgroundColor: "#04E69A" }}
			>
				<CardInformative
					title="Nº de Domicílios"
					value={totalResidences}
					icon={<BsFillHouseCheckFill className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Nº de Domicílios"
							message="Quantidade de domicílios cadastrados no período selecionado e de acordo com o filtro utilizado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col
				sm={6}
				md={4}
				lg={2}
				xxl={2}
				className="card-information-acs bg-muted rounded p-2 my-2"
			>
				<CardInformative
					title="Outros imóveis"
					value={totalOtherBuildings}
					icon={<FaLandmark className="fs-2 text-white" />}
					classNameValue="text-white"
					classNameTitle="text-white"
					metaData={
						<CardOverlayTrigger
							header="Outros imóveis"
							message="Quantidade de outros tipos de imóveis cadastrados no período selecionado e de acordo com o filtro utilizado."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
		</Row>
	);
}
