import { InferType } from "yup";
import Yup from "../mixins/yup";

export const userPersonalInfoRegistrationSchema = Yup.object({
	state: Yup.string().required("O estado é obrigatório"),
	city: Yup.string().required("A cidade é obrigatória"),
	occupation: Yup.string().required("A ocupação é obrigatória"),
	name: Yup.string()
		.min(4, "Mínimo de 4 caracteres")
		.max(55, "Máximo de 55 caracteres")
		.required("Nome é obrigatório")
		.test("name", "Digite seu nome completo", (value) => {
			return Boolean(value && value.split(" ").length >= 2);
		}),
	email: Yup.string()
		.email("Digite um e-mail válido")
		.min(1, "Digite um e-mail válido")
		.required("E-mail é obrigatório"),
	phone: Yup.string()
		.DDIPhone("Digite um telefone válido")
		.min(19, "Digite um telefone válido")
		.max(19, "Digite um telefone válido")
		.required("Telefone é obrigatório"),
	identifier: Yup.string()
		.cpf("Digite um CPF válido")
		.min(14, "Digite o CPF corretamente")
		.required("CPF é obrigatório"),
});

export const passwordRegistrationSchema = Yup.object({
	password: Yup.string()
		.min(6, "Mínimo de 6 caracteres")
		.max(20, "Máximo de 20 caracteres")
		.required("Senha é obrigatória"),
	confirmPassword: Yup.string()
		.min(6, "Mínimo de 6 caracteres")
		.max(20, "Máximo de 20 caracteres")
		.required("Confirmação de senha é obrigatória")
		.oneOf([Yup.ref("password"), null], "Senhas não conferem"),
});

export type UserPersonalInfo = InferType<typeof userPersonalInfoRegistrationSchema>;
export type PasswordRegistration = InferType<typeof passwordRegistrationSchema>;
export type UserRegistration = UserPersonalInfo & PasswordRegistration;
