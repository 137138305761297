import Select from "react-select";
import { RiFileExcel2Fill } from "react-icons/ri";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { DiseaseClasssificationEnum } from "@/utils/enums/DiseaseClassificationEnum";
import { Option } from "@/@types/Option";

import { SearchInput } from "@/components/SearchInput";

type Props = {
	isLoading: boolean;
	searchFullName: string;
	selectedTreatmentType: Option<number | null>;
	setSearchFullName: (value: string) => void;
	setSelectedTreatmentType: (value: Option<number | null>) => void;
	handleExportTable: () => void;
};

export function FiltersTableCitizensWithDiseases({
	isLoading,
	searchFullName,
	setSearchFullName,
	selectedTreatmentType,
	setSelectedTreatmentType,
	handleExportTable,
}: Props) {
	const selectTreatmentOptions: Option<number | null>[] = [
		{ label: "Todos", value: null },
		{ label: "Resolvido", value: DiseaseClasssificationEnum.RECOVERD },
		{ label: "Em tratamento", value: DiseaseClasssificationEnum.UNDERTREATMENT },
		{ label: "Não acompanhado", value: DiseaseClasssificationEnum.UNTREATEDMENT },
	];
	return (
		<Row className="d-flex w-100 align-items-end justify-content-between text-nowrap">
			<Col xs={12} sm={6} md={4} lg={4} xl={4} className="d-flex flex-column my-2">
				<label className="mb-2">
					<strong>Pesquisar Cidadão:</strong>
				</label>
				<SearchInput
					placeholder="Pesquisar"
					value={searchFullName}
					setValue={setSearchFullName}
				/>
			</Col>
			<Col xs={12} sm={6} md={3} lg={4} xl={3} className="d-flex flex-column my-2">
				<div>
					<label className="mb-2">
						<strong>Classificação:</strong>
					</label>
					<Select
						className="w-100"
						value={selectedTreatmentType}
						onChange={(option) => {
							setSelectedTreatmentType(option as Option<number | null>);
						}}
						options={selectTreatmentOptions}
						placeholder="Selecione..."
						styles={{
							menu: (base) => ({ ...base, zIndex: 90 }),
							control: (base) => ({
								...base,
								minHeight: "30px",
								padding: "0px",
							}),
							valueContainer: (base) => ({
								...base,
								padding: "0px 4px",
							}),
							indicatorsContainer: (base) => ({
								...base,
								height: "30px",
							}),
						}}
					/>
				</div>
			</Col>
			<Col xs={12} sm={6} md={2} lg={2} xl={2} className="d-flex flex-column my-2">
				<Button
					variant="secondary"
					className="px-2"
					disabled={isLoading}
					onClick={handleExportTable}
				>
					<RiFileExcel2Fill />
					Exportar
				</Button>
			</Col>
		</Row>
	);
}
