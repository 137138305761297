import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Account } from "@/@types/Account";
import { Period } from "@/@types/Period";
import { ResumeGrouped } from "@/services/esus/attendanceService";

import { PregnancyDashboardChildBirthHeader } from "./PregnancyDashboardChildBirthHeader";
import {
	ChildBirthTypeResume,
	PregnancyDashboardChildBirthResume,
} from "./PregnancyDashboardChildBirthResume";
import { PregnancyDashboardChildBirthDetails } from "./PregnancyDashboardChildBirthDetails";

export type ChildBirthResume = {
	childBirthTypeResumes: ResumeGrouped<
		| "normal"
		| "cesarean"
		| "normalstillbirth"
		| "cesareanstillbirth"
		| "abortion"
		| "unspecified"
		| "unregistered"
		| null,
		object
	>[];
	childBirthByPeriodAndTypeResumes: ResumeGrouped<
		Period | null,
		ResumeGrouped<
			| "normal"
			| "cesarean"
			| "normalstillbirth"
			| "cesareanstillbirth"
			| "abortion"
			| "unspecified"
			| "unregistered"
			| null,
			object
		>[]
	>[];
	childBirthByPeriodAndAverageGestationalAgeResumes: ResumeGrouped<Period | null, object>[];
};

export type ChildBirthResumeCity = ChildBirthResume & {
	city: Account;
};

type Props = {
	childBirthResumeCities: ChildBirthResumeCity[];
};

export function PregnancyDashboardChildBirth({ childBirthResumeCities }: Props) {
	const childBirthTypeResume: ChildBirthTypeResume = {
		totalNormal: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "normal"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalCesarean: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "cesarean"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalNormalStillBirth: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "normalstillbirth"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalCesareanStillBirth: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "cesareanstillbirth"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalAbortion: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "abortion"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalUnspecified: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "unspecified"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalUnregistered: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "unregistered"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
	};

	return (
		<section>
			<Row>
				<Col>
					<Card className="m-2">
						<Card.Body>
							<PregnancyDashboardChildBirthHeader />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col className="m-2">
					<PregnancyDashboardChildBirthResume
						childBirthTypeResume={childBirthTypeResume}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="m-2">
					<PregnancyDashboardChildBirthDetails
						childBirthResumeCities={childBirthResumeCities}
					/>
				</Col>
			</Row>
		</section>
	);
}
