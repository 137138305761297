import { ResumeGrouped } from "@/services/esus/attendanceService";

import Table from "react-bootstrap/Table";
import { PregnancyIndicatorsResumeCity } from "..";

export function calculatePregnantIndicatorPercentage(
	resumes: ResumeGrouped<"finished" | "pending" | "lost" | null, object>[]
) {
	const finished = resumes.find((resume) => resume.key === "finished")?.total || 0;
	const lost = resumes.find((resume) => resume.key === "lost")?.total || 0;
	if (finished + lost === 0) return 0;
	return Math.round((finished / (finished + lost)) * 1000) / 10;
}

export function calculateTotalPregnantIndicatorsPercentage(resume: PregnancyIndicatorsResumeCity) {
	return (
		Math.round(
			((calculatePregnantIndicatorPercentage(resume.indicator1Resumes) +
				calculatePregnantIndicatorPercentage(resume.indicator2Resumes) +
				calculatePregnantIndicatorPercentage(resume.indicator3Resumes) * 2) *
				10) /
				4
		) / 10
	);
}

export function getPregnantIndicatorColor(percentage: number) {
	if (percentage <= 25) return "#f1416c";
	if (percentage > 25 && percentage <= 50) return "#ffc700";
	if (percentage > 50 && percentage <= 75) return "#5ecf8f";
	if (percentage > 75) return "#009ef7";
}

export function getPregnantIndicatorDescription(percentage: number) {
	if (percentage <= 25) return "Ruim";
	if (percentage > 25 && percentage <= 50) return "Regular";
	if (percentage > 50 && percentage <= 75) return "Bom";
	if (percentage > 75) return "Ótimo";
	return "";
}

type Props = {
	pregnancyIndicatorsResumeCities: PregnancyIndicatorsResumeCity[];
};

export function PregnancyDashboardIndicatorsTable({ pregnancyIndicatorsResumeCities }: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			id="table-pregnancy-indicators"
			className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Ranking</th>
					<th>Município</th>
					<th>Indicador 1</th>
					<th>Indicador 2</th>
					<th>Indicador 3</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{pregnancyIndicatorsResumeCities
					.sort(
						(a, b) =>
							calculateTotalPregnantIndicatorsPercentage(b) -
							calculateTotalPregnantIndicatorsPercentage(a)
					)
					.map((resume, index) => (
						<tr>
							<td>{index + 1}</td>
							<td>{resume.city.locale}</td>
							<td
								style={{
									color: `${
										getPregnantIndicatorColor(
											calculatePregnantIndicatorPercentage(
												resume.indicator1Resumes
											)
										) || ""
									}`,
								}}
							>
								{calculatePregnantIndicatorPercentage(resume.indicator1Resumes)}%
							</td>
							<td
								style={{
									color: `${
										getPregnantIndicatorColor(
											calculatePregnantIndicatorPercentage(
												resume.indicator2Resumes
											)
										) || ""
									}`,
								}}
							>
								{calculatePregnantIndicatorPercentage(resume.indicator2Resumes)}%
							</td>
							<td
								style={{
									color: `${
										getPregnantIndicatorColor(
											calculatePregnantIndicatorPercentage(
												resume.indicator3Resumes
											)
										) || ""
									}`,
								}}
							>
								{calculatePregnantIndicatorPercentage(resume.indicator3Resumes)}%
							</td>
							<td
								style={{
									color: `${
										getPregnantIndicatorColor(
											calculateTotalPregnantIndicatorsPercentage(resume)
										) || ""
									}`,
								}}
							>
								{calculateTotalPregnantIndicatorsPercentage(resume)}%
							</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
