import { useEffect, useState } from "react";

import { useLayout } from "@/../_metronic/layout/core";

import { FaPlus } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useApi } from "@/hooks/useApi";
import { Import } from "@/@types/imports";

import { ModalUploadHealthInformationSystemForPrimaryCare } from "@/components/ModalUploadHealthInformationSystemForPrimaryCare";
import { UploadedReportsTable } from "@/components/UploadedReportsTable";

import "./styles.scss";

export function ImportData() {
	const { setTitle } = useLayout();
	useEffect(() => {
		setTitle("IMPORTAÇÃO DE DADOS");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const api = useApi();
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const [show, setShow] = useState(false);
	const handleShowModal = () => setShow(true);
	const handleCloseModal = () => setShow(false);

	const [uploadedReports, setUploadedReports] = useState<Import[]>([]);

	async function getUploadedReports() {
		try {
			const { data } = await api.get("/sisabpreliminaryreport/v1/getuploadedreports", {
				params: { uf, ibgeCode, cnes },
			});

			setUploadedReports(data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getUploadedReports();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<ToastContainer />

			<div className="row g-5 g-xl-8 pb-5">
				<div className="tab-content m-0">
					<div className="tab-pane fade show active" id="imports-content">
						<div className="col-xl-12">
							<ModalUploadHealthInformationSystemForPrimaryCare
								show={show}
								onHide={handleCloseModal}
								handleGetReports={getUploadedReports}
							/>

							<Button
								variant="primary"
								className="mb-4 fs-7"
								onClick={handleShowModal}
							>
								<FaPlus className="me-2" />
								Adicionar novo relatório
							</Button>
						</div>
						<div className="col-xl-12 d-flex imports mt-8">
							<Card className="card-reports col-xl-8 me-2">
								<Card.Body>
									<div className="table-responsive">
										<UploadedReportsTable reports={uploadedReports} />
									</div>
								</Card.Body>
							</Card>
							<Card className="ms-6 pt-4">
								<Card.Header>
									<Card.Title className="fs-6 text-center">
										Entenda como baixar os arquivos diretamente do SISAB e
										importar para o Indicadores ESUS
									</Card.Title>
								</Card.Header>
								<Card.Body></Card.Body>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
