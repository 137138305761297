import { TImmunobiological } from "@/@types/TImmunobiological";
import { get } from "../api";

type Params = {
	ibgeCode: string;
};
export async function fetchTImmunobiologicals(params: Params) {
	const { data } = await get<TImmunobiological[]>(
		`/TImmunobiological/v1/GetImmunobiologicals`,
		params
	);
	return data || [];
}
