import { IndicatorsData } from "@/services/esus/indicatorService";
import { IndicatorMeta } from "./enums/indicatorMeta";
import { calculateIndicatorDataPercentage } from "./calculatePercentage";

export function calculateISF(indicators: IndicatorsData) {
	const maximumTargetValue = 10;
	const weight = 10;
	const calculateIndicatorOne = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorOne)
	);
	const calculateIndicatorTwo = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorTwo)
	);
	const calculateIndicatorThree = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorThree)
	);
	const calculateIndicatorFour = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorFour)
	);
	const calculateIndicatorFive = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorFive)
	);
	const calculateIndicatorSix = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorSix)
	);
	const calculateIndicatorSeven = Math.round(
		calculateIndicatorDataPercentage(indicators?.indicatorSeven)
	);

	const sumValidIdentifiedRatio =
		(calculateIndicatorOne > IndicatorMeta.indicatorOneTargetValue
			? maximumTargetValue
			: (calculateIndicatorOne * weight) / IndicatorMeta.indicatorOneTargetValue) +
		(calculateIndicatorTwo > IndicatorMeta.indicatorTwoTargetValue
			? maximumTargetValue
			: (calculateIndicatorTwo * weight) / IndicatorMeta.indicatorTwoTargetValue) +
		(calculateIndicatorThree > IndicatorMeta.indicatorThreeTargetValue
			? maximumTargetValue * 2
			: ((calculateIndicatorThree * weight) / IndicatorMeta.indicatorThreeTargetValue) * 2) +
		(calculateIndicatorFour > IndicatorMeta.indicatorFourTargetValue
			? maximumTargetValue
			: (calculateIndicatorFour * weight) / IndicatorMeta.indicatorFourTargetValue) +
		(calculateIndicatorFive > IndicatorMeta.indicatorFiveTargetValue
			? maximumTargetValue * 2
			: ((calculateIndicatorFive * weight) / IndicatorMeta.indicatorFiveTargetValue) * 2) +
		(calculateIndicatorSix > IndicatorMeta.indicatorSixTargetValue
			? maximumTargetValue * 2
			: ((calculateIndicatorSix * weight) / IndicatorMeta.indicatorSixTargetValue) * 2) +
		(calculateIndicatorSeven > IndicatorMeta.indicatorSevenTargetValue
			? maximumTargetValue
			: (calculateIndicatorSeven * weight) / IndicatorMeta.indicatorSevenTargetValue);

	return Math.round(sumValidIdentifiedRatio * 10) / 100 || 0;
}

export function calculateIndicatorIsf(
	indicator: number,
	percentageIndicator: number,
	key: keyof typeof IndicatorMeta
) {
	const maximumTargetValue = 10;
	const weight = 10;
	if (indicator === 3 || indicator === 5 || indicator === 6) {
		return (
			Math.round(
				(percentageIndicator > IndicatorMeta[key]
					? maximumTargetValue * 2
					: ((percentageIndicator * weight) / IndicatorMeta[key]) * 2) * 10
			) / 100
		);
	}
	return (
		Math.round(
			(percentageIndicator > IndicatorMeta[key]
				? maximumTargetValue
				: (percentageIndicator * weight) / IndicatorMeta[key]) * 10
		) / 100
	);
}
