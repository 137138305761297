import ReactPaginate from "react-paginate";

type PaginatedLinksProps = {
	itemsPerPage: number;
	changeSelectedPage: (value: number) => void;
	totalPages: number;
	pageNumber: number;
};

type PageChangeEvent = {
	selected: number;
};

export function PaginationLinks({
	itemsPerPage,
	changeSelectedPage,
	totalPages,
	pageNumber,
}: PaginatedLinksProps) {
	const pageCount = Math.ceil(totalPages / itemsPerPage);

	const handlePageClick = (event: PageChangeEvent) => {
		const newOffset = event.selected + 1;
		changeSelectedPage(newOffset);
	};

	return (
		<ReactPaginate
			containerClassName="pagination"
			pageClassName="page-item"
			pageLinkClassName="page-link"
			activeClassName="active"
			nextClassName="page-item next"
			nextLinkClassName="page-link"
			previousClassName="page-item previous"
			previousLinkClassName="page-link"
			breakLabel="..."
			nextLabel={<i className="next"></i>}
			previousLabel={<i className="previous"></i>}
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
			forcePage={pageNumber - 1}
		/>
	);
}
