import { capitalize, onlyNumbers } from "@/utils/genericals";
import { authApi } from "../api";
import { MessageSenderTypeEnum } from "@/utils/enums/MessageSenderTypeEnum";

const AUTH_API_URL = process.env.REACT_APP_AUTH_BASE_URL;
export const GET_USER_BY_ACCESSTOKEN_URL = `${AUTH_API_URL}/verify_token`;
export const REGISTER_URL = `${AUTH_API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${AUTH_API_URL}/forgot_password`;

export const LOGIN_URL = `${AUTH_API_URL}/v1/auth/login`;
export const REFRESH_TOKEN_URL = `${AUTH_API_URL}/v1/auth/refresh`;

type UserForm = {
	state: string;
	city: string;
	occupation: string;
	name: string;
	identifier: string;
	email: string;
	phone: string;
	password: string;
	confirmPassword: string;
};

type RecoveryAccountForm = {
	identifier: string;
	code: string;
	password: string;
	confirmPassword: string;
};

type SendRecoveryAccountCodeForm = {
	identifier: string;
	senderType: MessageSenderTypeEnum;
};

type UserToken = {
	token: string;
	refreshToken: string;
	expiration: string;
};

type RegisteredUser = {
	fullName: string;
	email: string;
	phoneNumer: string;
	identifier: string;
};

type RecoveryInformation = {
	email: string;
	phone: string;
};

export async function login(userIdentifier: string, password: string) {
	try {
		const { data } = await authApi.post<UserToken>(LOGIN_URL, {
			identifier: onlyNumbers(userIdentifier),
			password: password,
		});
		return data;
	} catch (error) {
		console.log(error);
	}
}

export async function refreshToken(token: string, refreshToken: string) {
	try {
		const { data } = await authApi.post<UserToken>(REFRESH_TOKEN_URL, {
			token,
			refreshToken,
		});
		return data;
	} catch (error) {
		console.log(error);
	}
}

export async function validateAccount(identifier: string, validationCode: string) {
	try {
		const { data } = await authApi.get("/v1/auth/validateAccount", {
			params: {
				identifier: onlyNumbers(identifier),
				code: validationCode,
			},
		});
		return Boolean(data);
	} catch (error) {
		console.log(error);
	}
}

export async function register(user: UserForm) {
	try {
		const { data } = await authApi.post<RegisteredUser>("/v1/auth/register", {
			fullName: capitalize(user.name),
			email: user.email,
			identifier: onlyNumbers(user.identifier),
			phoneNumber: onlyNumbers(user.phone),
			password: user.password,
			confirmPassword: user.confirmPassword,
		});
		return data;
	} catch (error) {
		console.log(error);
	}
}

export async function SendRecoveryAccountCode(params: SendRecoveryAccountCodeForm) {
	try {
		const response = await authApi.get("/v1/auth/SendRecoveryAccountCode", {
			params: {
				...params,
				identifier: onlyNumbers(params.identifier),
			},
		});
		return response.status === 200;
	} catch (error) {
		console.log(error);
	}
}

export async function recoveryAccount(params: RecoveryAccountForm) {
	try {
		const response = await authApi.post("/v1/auth/RecoveryAccount", {
			...params,
			identifier: onlyNumbers(params.identifier),
		});
		return response.status === 200;
	} catch (error) {
		console.log(error);
	}
}

export async function recoveryInformation(identifier: string) {
	try {
		const { data } = await authApi.get<RecoveryInformation>("/v1/auth/RecoveryInformation", {
			params: {
				identifier: onlyNumbers(identifier),
			},
		});
		return data;
	} catch (error) {
		console.log(error);
	}
}

export async function checkUserExists(identifier?: string, email?: string) {
	try {
		const { data } = await authApi.get<boolean>("/v1/auth/CheckUserExists", {
			params: { identifier: onlyNumbers(identifier || ""), email: email },
		});
		return data;
	} catch (error) {
		console.log(error);
		return false;
	}
}
