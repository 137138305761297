import { useState } from "react";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ResumeGrouped } from "@/services/esus/attendanceService";
import { Cid } from "@/@types/Cid";

import { AttendanceRateByCidTableFilters } from "../AttendanceRateByCidTableFilters";
import { AttendanceRateByCidTable } from "../AttendanceRateByCidTable";

type Props = ModalProps & {
	aggregatedAttendanceCids: ResumeGrouped<Cid, object>[];
};

export function AttendanceRateByCidModal({ aggregatedAttendanceCids, ...rest }: Props) {
	const [cidDescription, setCidDescription] = useState("");
	const aggregatedAttendanceCidsFiltered = aggregatedAttendanceCids.filter(
		(aggregatedAttendanceCid) =>
			aggregatedAttendanceCid.key.description
				.toUpperCase()
				.includes(cidDescription.toUpperCase()) ||
			aggregatedAttendanceCid.key.cidCode.toUpperCase().includes(cidDescription.toUpperCase())
	);

	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">
						Ranking de atendimentos por cid
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="mb-8">
					<Col>
						<AttendanceRateByCidTableFilters
							cidDescription={cidDescription}
							setCidDescription={setCidDescription}
						/>
					</Col>
				</Row>
				<AttendanceRateByCidTable
					aggregatedAttendanceCids={aggregatedAttendanceCidsFiltered}
				/>
			</Modal.Body>
		</Modal>
	);
}
