import { ReactNode } from "react";

import Card from "react-bootstrap/Card";

type Props = {
	title: string;
	value: number;
	children: ReactNode;
};

export function CardDiseases({ title, value, children }: Props) {
	return (
		<Card className="border border-text-black w-100 h-50 mb-2 ">
			<Card.Body className=" justify-content-center p-0">
				<Card>
					<Card.Header style={{ minHeight: "0px" }}>
						<Card.Title className="justify-content-between w-100">
							<label>{title} </label>
							<label className="text-primary fw-bolder">
								{value?.toLocaleString()}
							</label>
						</Card.Title>
					</Card.Header>
					<Card.Body className="d-flex align-items-center justify-content-evenly p-0">
						<div className="d-flex flex-column justify-content-evenly text-center">
							<div className="d-flex flex-center">{children}</div>
						</div>
					</Card.Body>
				</Card>
			</Card.Body>
		</Card>
	);
}
