import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import pregnantGestationalImage from "@/../_metronic/assets/images/pregnant/pregnant-gestational.svg";

type Props = {
	totalActivePregnants: number;
};

export function PregnancyDashboardPrenatalResume({ totalActivePregnants }: Props) {
	return (
		<Row>
			<Col sm={12} md={12} lg={6} xl={6} className="h-100 mb-2">
				<Card className="m-2 h-100 bg-primary">
					<Card.Body className="py-2 h-100">
						<div className="d-flex flex-row justify-content-between align-items-center">
							<h1 className="text-white" style={{ fontSize: 24 }}>
								Gestantes ativas:{" "}
							</h1>
							<label className="text-white" style={{ fontSize: 48 }}>
								{totalActivePregnants}
							</label>
						</div>
					</Card.Body>
				</Card>
			</Col>
			<Col sm={12} md={12} lg={6} xl={6} className="h-100 mb-2">
				<Card className="m-2 h-100" style={{ minHeight: "100px" }}>
					<Card.Body
						className="h-100 rounded"
						style={{
							backgroundImage: `url(${pregnantGestationalImage})`,
							backgroundSize: "100%",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "top",
							backgroundPositionY: 5,
						}}
					></Card.Body>
				</Card>
			</Col>
		</Row>
	);
}
