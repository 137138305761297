import Table from "react-bootstrap/Table";

import { Specialty } from "@/@types/Specialty";
import { ResumeGrouped } from "@/services/esus/attendanceService";

type Props = {
	aggregatedAttendanceReferrals: ResumeGrouped<Specialty, object>[];
};

export function AttendanceReferralRateBySpecialtyTable({ aggregatedAttendanceReferrals }: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th className="text-start">Descrição</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center overflow-y-scroll" style={{ maxHeight: 600 }}>
				{aggregatedAttendanceReferrals
					.sort((aggregatedA, aggregatedB) => aggregatedB.total - aggregatedA.total)
					.map((aggregatedAttendanceReferral, index) => (
						<tr
							key={`attendance-procedure-rate-${index}`}
							className="overflow-y-scroll"
							style={{ maxHeight: 600 }}
						>
							<td className="text-start">
								{aggregatedAttendanceReferral.key.description}
							</td>
							<td className="fw-bolder">{aggregatedAttendanceReferral.total}</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
