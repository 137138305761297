import { get } from "../api";
import { BasePatient } from "@/@types/BasePatient";
import { Attendance } from "@/@types/Attendance";
import { DentalAttendance } from "@/@types/DentalAttendance";
import { AttendanceProcedureReqEval } from "@/@types/AttendanceProcedureReqEval";
import { Vaccination } from "@/@types/Vaccination";
import { Problem } from "@/@types/Problem";
import { WeightClassificationEnum } from "@/utils/enums/WeightClassificationEnum";
import { RacialColorEnum } from "@/utils/enums/RacialColorEnum";
import { PregnancyTypeEnum } from "@/utils/enums/PregnancyTypeEnum";
import { Cbo } from "@/@types/Cbo";
import { Period } from "@/@types/Period";
import { Paginated } from "@/@types/paginated";
import { HealthTeam } from "@/@types/HealthTeam";
import { HealthUnit } from "@/@types/HealthUnit";
import { Cid } from "@/@types/Cid";
import { Ciap } from "@/@types/Ciap";
import { TScheduleStatus } from "@/@types/TScheduleStatus";
import { Professional } from "@/@types/Professional";
import { Shift } from "@/@types/Shift";
import { Procedure } from "@/@types/Procedure";
import { Specialty } from "@/@types/Specialty";

export type ResumeGrouped<T, K> = {
	key: T;
	total: number;
	values?: K[];
};

export type AttendancePerformanceDashboard = {
	attendancesAggregatedByAgeAndGender?: ResumeGrouped<string, ResumeGrouped<string, object>>[];
	attendancesAggregatedByHealthTeamAndProfessionalCategory?: ResumeGrouped<
		HealthTeam,
		ResumeGrouped<string, object>
	>[];
	attendancesAggregatedByHealthUnitAndProfessionalCategory?: ResumeGrouped<
		HealthUnit,
		ResumeGrouped<string, object>
	>[];
	attendancesAggregatedByPeriodAndProfessionalCategory?: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendancesAggregatedByProfessionalCategoryAndProfessional?: ResumeGrouped<
		string,
		ResumeGrouped<Professional, object>
	>[];
	attendancesAggregatedByShift?: ResumeGrouped<Shift, object>[];

	attendanceProceduresAggregatedByPeriodAndProfessionalCategory?: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendanceProceduresAggregatedByProcedureAndProfessionalCategory?: ResumeGrouped<
		Procedure,
		ResumeGrouped<string, object>
	>[];

	attendanceProcedureRequestedsAggregated?: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendanceProcedureEvaluatedsAggregated?: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];

	problemsAggregatedByCidAndProfessionalCategory?: ResumeGrouped<
		Cid,
		ResumeGrouped<string, object>
	>[];
	problemsAggregatedByCiapAndProfessionalCategory?: ResumeGrouped<
		Ciap,
		ResumeGrouped<string, object>
	>[];

	attendanceReferralsAggregatedByCiapAndProfessionalCategory?: ResumeGrouped<
		Ciap,
		ResumeGrouped<string, object>
	>[];
	attendanceReferralsAggregatedByCidAndProfessionalCategory?: ResumeGrouped<
		Cid,
		ResumeGrouped<string, object>
	>[];
	attendanceReferralsAggregatedByPeriodAndProfessionalCategory?: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendanceReferralsAggregatedBySpecialty?: ResumeGrouped<Specialty, object>[];

	schedulesAggregatedByPeriodAndScheduleStatus?: ResumeGrouped<
		Period,
		ResumeGrouped<TScheduleStatus, object>
	>[];
};

export type AttendanceDashboard = {
	attendancesAggregatedByAgeAndGender?: ResumeGrouped<string, ResumeGrouped<string, object>>[];
	attendancesAggregatedByHealthTeamAndProfessionalCategory?: ResumeGrouped<
		HealthTeam,
		ResumeGrouped<string, object>
	>[];
	attendancesAggregatedByHealthUnitAndProfessionalCategory?: ResumeGrouped<
		HealthUnit,
		ResumeGrouped<string, object>
	>[];
	attendancesAggregatedByPeriodAndProfessionalCategory?: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendancesAggregatedByProfessionalCategoryAndProfessional?: ResumeGrouped<
		string,
		ResumeGrouped<Professional, object>
	>[];
};

export type AttendanceReferralDashboard = {
	attendanceReferralsAggregatedByCiapAndProfessionalCategory?: ResumeGrouped<
		Ciap,
		ResumeGrouped<string, object>
	>[];
	attendanceReferralsAggregatedByCidAndProfessionalCategory?: ResumeGrouped<
		Cid,
		ResumeGrouped<string, object>
	>[];
	attendanceReferralsAggregatedByPeriodAndProfessionalCategory?: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendanceReferralsAggregatedBySpecialty?: ResumeGrouped<Specialty, object>[];
};

export type AttendanceProcedureDashboard = {
	attendanceProceduresAggregatedByPeriodAndProfessionalCategory?: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendanceProceduresAggregatedByProcedureAndProfessionalCategory?: ResumeGrouped<
		Procedure,
		ResumeGrouped<string, object>
	>[];
};

export type AttendanceProcedureReqEvalDashboard = {
	attendanceProcedureReqEvalsAggregated?: ResumeGrouped<Period, ResumeGrouped<string, object>>[];
};

export type ProblemDashboard = {
	problemsAggregatedByCidAndProfessionalCategory?: ResumeGrouped<
		Cid,
		ResumeGrouped<string, object>
	>[];
	problemsAggregatedByCiapAndProfessionalCategory?: ResumeGrouped<
		Ciap,
		ResumeGrouped<string, object>
	>[];
};

export type TScheduleDashboard = {
	schedulesAggregatedByPeriodAndScheduleStatus?: ResumeGrouped<
		Period,
		ResumeGrouped<TScheduleStatus, object>
	>[];
};

export type ResumePregnant = {
	resumeChildBirthType: ResumeGrouped<string | null, object>[];
	resumePregnancyStatus: ResumeGrouped<string | null, object>[];
	resumePuerperalStatus: ResumeGrouped<string | null, object>[];
	resumeGestationalAge: ResumeGrouped<string | null, object>[];
	resumeGestationalAgeTrimester: ResumeGrouped<number | null, object>[];
	resumeHivAndSifilisStatus: ResumeGrouped<string | null, object>[];
	resumeRacialColor: ResumeGrouped<RacialColorEnum | null, object>[];
	resumeHighRisk: ResumeGrouped<number | null, object>[];
	resumeChildBirthByPeriodAndType: ResumeGrouped<
		Period | null,
		ResumeGrouped<string | null, object>[]
	>[];
	averageGestationalAge: number;
	averagePregnantAge: number;
};

export type Pregnancy = BasePatient & {
	socialName: string;
	firstDumDate: string;
	firstAttendanceDate: string;
	dppDate: string;
	gender: number;
	inPreliminaryReport: boolean;
	preliminaryReportStatus: number;
	phone?: string;
	alcoholicStatus?: number;
	smokerStatus?: number;
	haveDiabetes?: number;
	haveHighBloodPressure?: number;
	lastIndividualRecordDate?: string;
	attendances: Attendance[];
	dentalAttendances?: DentalAttendance[];
	childBirthDate: string;
	puerperalAttendanceProcedures: AttendanceProcedureReqEval[];
	pregnancyProcedures?: AttendanceProcedureReqEval[];
	pregnancyVaccinations?: Vaccination[];
	childBirthType?:
		| "normal"
		| "cesarean"
		| "normalstillbirth"
		| "cesareanstillbirth"
		| "abortion"
		| "unspecified"
		| "unregistered";
	childBirthProblem?: Problem;
	attendanceStatus: string;
	puerperalStatus: string;
	pregnancyStatus: string;
	highRiskStatus?: number;
	plannedPregnancyStatus?: number;
	weightClassification?: WeightClassificationEnum;
	racialColor?: RacialColorEnum;
	pregnancyType?: PregnancyTypeEnum;
	firstGestationalAge?: number;
	lastGestationalAge?: number;
	citizenCbo?: Cbo;
};

type AttendanceDashboardParams = {
	uf: string;
	ibgeCode: string;
	cnes?: string;
	cns?: string;
	ine?: string;
	startDate: string;
	endDate: string;
};

type EpidemicDashboardParams = {
	uf: string;
	ibgeCode: string;
	startDate: string;
	endDate: string;
	epidemicType: string;
};

type PregnantsWithChildBirthParams = {
	startDate: string;
	endDate: string;
	cnes?: string;
	ine?: string;
	fullName?: string;
	ibgeCode: string;
	pageNumber: number;
	pageSize?: number;
	uf: string;
	orderBy: string;
};

type ResumePregnantsWithChildBirthParams = {
	startDate: string;
	endDate: string;
	cnes?: string;
	ine?: string;
	ibgeCode: string;
	uf: string;
};

export async function handleAttendancePerformanceDashboard(params: AttendanceDashboardParams) {
	const response = await get<AttendancePerformanceDashboard>(
		`/Attendance/v1/GetAttendancePerformanceDashboard`,
		params
	);
	return response.data || ({} as AttendancePerformanceDashboard);
}

export async function handleAttendanceDashboard(params: AttendanceDashboardParams) {
	const response = await get<AttendanceDashboard>(`/Attendance/v1/GetDashboard`, params);
	return response.data || ({} as AttendanceDashboard);
}

export async function handleEpidemicDashboard(params: EpidemicDashboardParams) {
	const response = await get<AttendanceDashboard>(`/Attendance/v1/GetEpidemicDashboard`, params);
	return response.data || ({} as AttendanceDashboard);
}

export async function handlePregnantsWithChildBirthPaginated(
	params: PregnantsWithChildBirthParams
) {
	const response = await get<Paginated<Pregnancy>>(
		`/Attendance/v1/GetPregnantsWithChildBirthPaginated`,
		params
	);
	return response.data || ({} as Paginated<Pregnancy>);
}

export async function handlePregnantsWithChildBirth(
	params: Omit<PregnantsWithChildBirthParams, "pageNumber">
) {
	const response = await get<Pregnancy[]>(`/Attendance/v1/GetPregnantsWithChildBirth`, params);
	return response.data || [];
}

export async function handleResumePregnantsWithChildBirth(
	params: ResumePregnantsWithChildBirthParams
) {
	const response = await get<ResumePregnant>(
		`/Attendance/v1/ResumePregnantsWithChildBirth`,
		params
	);
	return response.data || ({} as ResumePregnant);
}
