import Table from "react-bootstrap/Table";

import { Account } from "@/@types/Account";
import { ResumeGrouped } from "@/services/esus/attendanceService";

export type ChildBirthByCityAndTypeResume = {
	resumes: ResumeGrouped<
		| "normal"
		| "cesarean"
		| "normalstillbirth"
		| "cesareanstillbirth"
		| "abortion"
		| "unspecified"
		| "unregistered"
		| null,
		object
	>[];
	city: Account;
};

type Props = {
	childBirthByCityAndTypeResumes: ChildBirthByCityAndTypeResume[];
};

export function ChildBirthByCityAndTypeTable({ childBirthByCityAndTypeResumes }: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			id="table-child-birth-city-type"
			className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Município</th>
					<th>Normal</th>
					<th>Cesário</th>
					<th>Natimorto normal</th>
					<th>Natimorto cesário</th>
					<th>Aborto</th>
					<th>Não especificado</th>
					<th>Não registrado</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{childBirthByCityAndTypeResumes
					.sort((a, b) => a.city.locale.localeCompare(b.city.locale))
					.map((cityResume, index) => (
						<tr key={`table-child-birth-city-type-${index}`}>
							<td>{cityResume.city.locale}</td>
							<td>
								{cityResume.resumes.find((resume) => resume.key === "normal")
									?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find((resume) => resume.key === "cesarean")
									?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.key === "normalstillbirth"
								)?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.key === "cesareanstillbirth"
								)?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find((resume) => resume.key === "abortion")
									?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find((resume) => resume.key === "unspecified")
									?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find((resume) => resume.key === "unregistered")
									?.total || 0}
							</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
