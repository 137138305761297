import { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import Select from "react-select";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import statesAndCities from "@/utils/static/states-and-cities.json";
import { LicenseEnum } from "@/utils/enums/LicenseEnum";
import { SelectOptionsProps } from "@/@types/genericals";
import { StatesAndCities } from "@/@types/statesAndCities";
import { Account } from "@/@types/Account";
import { Role } from "@/@types/Role";

import { ProfileSelect } from "@/components/ProfileSelect";
import { FederalUnit } from "@/@types/FederalUnit";
import { RoleEnum, StateRoleEnum } from "@/utils/enums/RoleEnum";

type Props = ModalProps & {
	handleClose?: () => void;
};

export function ModalNewCityLicense({ handleClose, ...rest }: Props) {
	const { user, getCurrentAccount } = useCurrentAccount();
	const api = useApi();
	const { states, cities } = statesAndCities as StatesAndCities;
	const [confirmTerms, setConfirmTerms] = useState(false);
	const [citiesActivated, setCitiesActivated] = useState<Account[]>([]);
	const [selectedOptionProfile, setSelectedOptionProfile] = useState<SelectOptionsProps>();
	const [selectedOptionState, setSelectedOptionState] = useState<SelectOptionsProps>();
	const [selectedOptionCity, setSelectedOptionCity] = useState<SelectOptionsProps>();
	const [selectedOptionAccessType, setSelectedOptionAccessType] = useState("city");
	const [isLoading, setIsLoading] = useState(false);
	const [permittedFederalUnits, setPermittedFederalUnits] = useState<FederalUnit[]>([]);

	const [notLinkedAccounts, setNotLinkedAccounts] = useState<Account[]>([]);
	const [roles, setRoles] = useState<Role[]>([]);

	const filteredRoles = roles.filter((role) =>
		selectedOptionAccessType === "uf"
			? Object.values(StateRoleEnum).find((stateRole) => String(role.id) === stateRole)
			: Object.values(RoleEnum).find((roleObject) => String(role.id) === roleObject)
	);

	async function handleFetchNotLinkedAccounts() {
		try {
			const { data } = await api.get<Account[]>("/Account/v1/GetNotLinkedAccounts");
			setNotLinkedAccounts(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function handleFetchProfiles() {
		try {
			const { data } = await api.get<Role[]>("/profile/v1/GetAll");
			setRoles(data);
		} catch (error) {
			console.log(error);
			setRoles([]);
		}
	}

	async function handleRegister() {
		setIsLoading(true);
		if (
			citiesActivated.find(
				(cityActivated) => String(cityActivated.ibgeCode) === selectedOptionCity?.value
			) ||
			(selectedOptionState?.value && !selectedOptionCity?.value)
		) {
			await requestAccessToAccount(
				selectedOptionState?.value || "",
				selectedOptionCity?.value,
				selectedOptionProfile?.value
			);
		} else {
			await requestCityLicense();
		}
		setIsLoading(false);
	}

	async function requestAccessToAccount(uf: string, ibgeCode?: string, roleId?: string) {
		try {
			const response = await api.post("/UserAccount/v1/RequestAccess", {
				uf: uf,
				ibgeCode: ibgeCode,
				roleId: roleId,
			});
			if (response.status === 200) {
				toast.info(
					`Solicitação registrada. Entre em contato com o gestor do municipio para efetuar a ativação.`,
					{ bodyClassName: "fs-5" }
				);
				setTimeout(() => {
					handleClose && handleClose();
				}, 3000);
			}
		} catch (error) {
			toast.error(`Erro ao solicitar acesso.`, { bodyClassName: "fs-5" });
			console.log(error);
		}
	}

	const selectedCityIsActive =
		citiesActivated.filter(
			(cityActivated) => cityActivated.ibgeCode === selectedOptionCity?.value
		).length > 0;

	const selectStates: SelectOptionsProps[] = states
		.filter(
			(state) =>
				selectedOptionAccessType === "city" ||
				(selectedOptionAccessType === "uf" &&
					permittedFederalUnits.filter(
						(federalUnit) => federalUnit?.abbreviation !== state.abbreviation
					))
		)
		.map((state) => ({
			label: state.abbreviation,
			value: String(state.abbreviation),
		}));

	const isDisabledRegisterButton =
		isLoading ||
		!Boolean(
			selectedOptionCity &&
				selectedOptionCity.value &&
				selectedOptionProfile &&
				selectedOptionProfile.value &&
				((confirmTerms && !selectedCityIsActive) || selectedCityIsActive)
		);

	const selectCities = cities
		.filter(
			(city) =>
				city.microrregiao.mesorregiao.UF.sigla === selectedOptionState?.value &&
				(!citiesActivated.find(
					(cityActivated) => cityActivated.ibgeCode === String(city.id)
				) ||
					notLinkedAccounts.find(
						(notLinkedAccount) => notLinkedAccount.ibgeCode === String(city.id)
					))
		)
		.map(
			(city) =>
				({
					label: city.nome,
					value: String(city.id),
				} as SelectOptionsProps)
		);

	async function handleFetchCitiesActivated() {
		try {
			const { data } = await api.get<Account[]>("/account/v1/getAccountsActivated");
			setCitiesActivated(data || []);
		} catch (err) {
			setCitiesActivated([]);
			console.log(err);
		}
	}

	async function handleFetchPermittedFederalUnits() {
		setPermittedFederalUnits(await getPermittedFederalUnits());
	}

	async function getPermittedFederalUnits() {
		try {
			const { data } = await api.get("/UserAccount/v1/GetPermittedFederalUnits");
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function requestCityLicense() {
		try {
			const response = await api.post("/cityLicense/v1/register", {
				uf: selectedOptionState?.value,
				ibgeCode: selectedOptionCity?.value,
				locale: selectedOptionCity?.label,
				identifier: user.identifier,
				licenseId: LicenseEnum.TEMPORARY,
			});
			if (response.status === 200) {
				await getCurrentAccount();
				toast.success("A confirmação da liberação da licença, chegará no seu whatssap.", {
					bodyClassName: "fs-5",
				});
				handleFetchCitiesActivated();
				setSelectedOptionCity({ value: "", label: "Selecione uma cidade" });
				setTimeout(() => {
					handleClose && handleClose();
				}, 3000);
			}
		} catch (error) {
			toast.error("Falha na solicitação.");
			console.log(error);
		}
	}

	useEffect(() => {
		if (rest.show) {
			handleFetchPermittedFederalUnits();
			handleFetchCitiesActivated();
			handleFetchNotLinkedAccounts();
			handleFetchProfiles();
		}
		return () => {
			setSelectedOptionCity({} as SelectOptionsProps);
			setSelectedOptionState({} as SelectOptionsProps);
			setSelectedOptionProfile({} as SelectOptionsProps);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rest.show]);

	return (
		<Modal size="lg" {...rest}>
			<Modal.Header closeVariant="white" closeButton style={{ backgroundColor: "#0c6fd1" }}>
				<Modal.Title className="text-white">Solicitação de Acesso</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ToastContainer autoClose={5000} />
				<section className="mb-5 ms-3 me-3 pe-10 ps-10">
					<Row className="mb-4">
						<Col>
							<label htmlFor="option" className="mb-4">
								Formato de acesso
							</label>
							<div>
								<Form.Check
									inline
									label="Municipal"
									className="text-nowrap"
									name="option"
									type="radio"
									id="city-radio"
									onChange={() => {
										setSelectedOptionAccessType("city");
										setSelectedOptionProfile({
											value: "2",
											label: "Administrador",
										} as SelectOptionsProps);
									}}
									checked={selectedOptionAccessType === "city"}
								/>
								<Form.Check
									inline
									label="Estadual"
									className="text-nowrap"
									name="option"
									type="radio"
									id="uf-radio"
									onChange={() => {
										setSelectedOptionAccessType("uf");
										setSelectedOptionCity({
											value: "",
											label: "Selecione uma cidade",
										});
										setSelectedOptionProfile({
											value: "5",
											label: "Administrador estadual",
										} as SelectOptionsProps);
									}}
									checked={selectedOptionAccessType === "uf"}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={2} md={2}>
							<label htmlFor="state">Estado</label>
							<Select
								onChange={(newValue) =>
									setSelectedOptionState(newValue as SelectOptionsProps)
								}
								defaultValue={{ value: "", label: "UF" }}
								noOptionsMessage={() => "Nenhum estado encontrado"}
								options={selectStates}
								value={selectedOptionState}
								name="state"
								id="state"
								className="fs-3 text-muted fw-normal mb-2"
							/>
						</Col>
						<Col lg={10} md={10}>
							<label htmlFor="city">Cidade de vinculo profissional</label>
							<Select
								onChange={(newValue) =>
									setSelectedOptionCity(newValue as SelectOptionsProps)
								}
								defaultValue={{ value: "", label: "Selecione uma cidade" }}
								noOptionsMessage={() => "Nenhuma cidade encontrada"}
								options={selectCities}
								value={selectedOptionCity}
								name="city"
								id="city"
								className="fs-3 text-muted fw-normal mb-2"
								isDisabled={selectedOptionAccessType === "uf"}
							/>
						</Col>
					</Row>
					<Row>
						<ProfileSelect
							id="Profile-select"
							className="fs-3"
							noDefaultOption={true}
							roles={filteredRoles}
							value={selectedOptionProfile}
							onChange={(newValue) => {
								setSelectedOptionProfile(newValue as SelectOptionsProps);
							}}
							isDisabled={selectedOptionAccessType === "uf"}
						/>
					</Row>
					{!(selectedCityIsActive || selectedOptionAccessType === "uf") && (
						<div className="mt-3">
							<input
								className="form-check-input fs-9"
								type="checkbox"
								name="confirm-terms"
								checked={confirmTerms}
								onChange={() => setConfirmTerms(!confirmTerms)}
								disabled={selectedCityIsActive || selectedOptionAccessType === "uf"}
							/>
							<label className="mx-2">
								Confirmo que estou solicitando uma licença temporária.
							</label>
						</div>
					)}
				</section>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="danger" onClick={rest.onHide}>
					Fechar
				</Button>
				<Button
					disabled={
						selectedOptionState?.value && selectedOptionAccessType === "uf"
							? false
							: isDisabledRegisterButton
					}
					variant="success"
					onClick={handleRegister}
				>
					Confimar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
