import dayjs from "dayjs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { BaseModal, BaseModalProps } from "../BaseModal";
import { ManagedCitizen } from "@/pages/RegistrationManagement";

type ManagedCitizensModalProps = BaseModalProps & {
	citizen: ManagedCitizen;
};

export function ManagedCitizensModal({ citizen, ...rest }: ManagedCitizensModalProps) {
	return (
		<BaseModal
			header={
				<>
					<Modal.Header className="modal-header border-0 pb-0">
						<div className="d-flex flex-center w-100">
							<h1 className="modal-title mt-2 mb-3 text-uppercase">
								{citizen?.name}
							</h1>
						</div>
						<button
							type="button"
							className="btn-close"
							aria-label="Close"
							onClick={rest.onHide}
						></button>
					</Modal.Header>
					<div className="text-center text-muted fw-bold mb-1">
						Aqui você pode visualizar as informações pertinentes ao cidadão
					</div>
				</>
			}
			footer={<></>}
			size="lg"
			{...rest}
		>
			<div className="border border-hover-primary p-7 rounded">
				<div className="p-0">
					<div className="d-flex flex-column">
						<p className="text-gray-700 fw-bold mb-3">
							CPF:
							<span className="fw-normal ms-1">
								{!citizen?.cpf || citizen?.cpf?.trim() === "0"
									? "NÃO INFORMADO"
									: citizen?.cpf}
							</span>
						</p>
						<p className="text-gray-700 fw-bold mb-3">
							Data de Nascimento:
							<span className="fw-normal ms-1">
								{citizen?.birthDate
									? dayjs(citizen.birthDate).format("DD/MM/YYYY")
									: "NÃO INFORMADO"}
							</span>
						</p>
						<p className="text-gray-700 fw-bold mb-3">
							Microrregião
							<span className="fw-normal ms-1">{citizen?.area || "--"}</span>
						</p>
						<p className="text-gray-700 fw-bold mb-3">
							Nome da Mãe:
							<span className="fw-normal ms-1">
								{citizen?.motherName || "NÃO INFORMADO"}
							</span>
						</p>
						<p className="text-gray-700 fw-bold mb-3">
							CNS:
							<span className="fw-normal ms-1">
								{!citizen?.cns || citizen?.cns?.trim() === "0"
									? "NÃO INFORMADO"
									: citizen?.cns}
							</span>
						</p>
						<p className="text-gray-700 fw-bold mb-3">
							NIS:
							<span className="fw-normal ms-1">
								{citizen?.nis || "NÃO INFORMADO"}
							</span>
						</p>
						<p className="text-gray-700 fw-bold mb-3">
							Data do cadastro:
							<span className="fw-normal ms-1">
								{citizen.registerDate
									? dayjs(citizen.registerDate).format("DD/MM/YYYY")
									: "NÃO INFORMADO"}
							</span>
						</p>
						<p className="text-gray-700 fw-bold mb-3">
							Unidade:
							<span className="fw-normal ms-1">
								{citizen?.healthUnit?.name || "NÃO INFORMADO"}
							</span>
						</p>
						<p className="text-gray-700 fw-bold mb-3">
							Equipe:
							<span className="fw-normal ms-1">
								{citizen?.healthTeam || "NÃO INFORMADO"}
							</span>
						</p>
					</div>
				</div>
			</div>

			<div className="d-flex flex-center pt-7">
				<Button variant="light" onClick={rest.onHide}>
					Fechar
				</Button>
			</div>
		</BaseModal>
	);
}
