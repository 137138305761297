import { BsGraphUp } from "react-icons/bs";

export function PregnancyDashboardIndicatorsHeader() {
	return (
		<section className="d-flex align-items-center">
			<label className="bg-primary text-secondary py-2 px-4 rounded fs-2 text-center">
				<BsGraphUp />
			</label>
			<h1 className="text-primary rounded ms-2 my-0">Indicadores</h1>
		</section>
	);
}
