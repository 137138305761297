import axios from "axios";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { RootState } from "@/../setup";

import * as auth from "@/modules/auth/redux/AuthRedux";
import { LOGIN_URL, refreshToken } from "@/services/auth/accountService";

export function useApi() {
	const dispatch = useDispatch();
	const token = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const storedRefreshToken = useSelector<RootState>(
		({ auth }) => auth.refreshToken,
		shallowEqual
	) as string;

	const api = axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
	});

	if (token) {
		api.defaults.headers["Authorization"] = `Bearer ${token}`;
	}

	api.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalConfig = error.config;

			if (
				originalConfig?.url !== LOGIN_URL &&
				error.response &&
				error.response.status === 401 &&
				!originalConfig._retry
			) {
				originalConfig._retry = true;

				try {
					const tokens = await refreshToken(token, storedRefreshToken);

					dispatch(auth.actions.login(tokens?.token || "", tokens?.refreshToken || ""));

					originalConfig.headers["Authorization"] = `Bearer ${tokens?.token || ""}`;

					return api(originalConfig);
				} catch (_error) {
					dispatch(auth.actions.logout());
					return Promise.reject(_error);
				}
			}
		}
	);

	return api;
}
